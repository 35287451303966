import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Iconify from 'src/components/Iconify';
import uuidv4 from 'src/utils/uuidv4';

const UserNextTask = ({ data, handleSave, viewOnly = false }) => {
  const [modal, setModal] = useState(false);
  const theme = useTheme();

  return (
    <>
      <Stack flexGrow={1} sx={{ mt: 2 }} direction={'column'}>
        <Stack direction={'row'} sx={{ mb: 2 }} justifyContent="space-between">
          <Typography variant="h7" sx={{ color: 'primary.main' }}>
            Steps
          </Typography>

          {!viewOnly && (
            <IconButton onClick={() => setModal(true)} color="primary" sx={{ mt: -1 }}>
              <Iconify icon={data?.length > 0 ? 'material-symbols:edit' : 'material-symbols:add'} />
            </IconButton>
          )}
        </Stack>

        {data?.map((a, index) => (
          <Stack direction={'row'} sx={{ p: 1, bgcolor: alpha(theme.palette.primary.main, 0.1), mb: 1, borderRadius: 1 }} spacing={3}>
            <Typography color="primary.main">{index + 1}.</Typography>
            <Typography
              sx={{
                mb: 2,
                fontSize: 15
              }}
              variant="subtitle"
              color="text.secondary"
            >
              {a.name}
            </Typography>
          </Stack>
        ))}
      </Stack>
      {modal && (
        <StepsModal
          data={data}
          handleClose={() => setModal(false)}
          handleSave={(p) => {
            setModal(false);
            handleSave(p);
          }}
        />
      )}
    </>
  );
};

export default UserNextTask;

// ---------------------------------------------------------------------------------

const StepsModal = ({ data, handleClose, handleSave }) => {
  const [addmode, setAddMode] = useState({ open: false, data: {} });
  // const [data,setData]=useState([])
  const [allresource, setAllResource] = useState([]);
  useEffect(() => {
    if (data.length > 0) {
      let p = data?.map((a) => ({ ...a, uuid: uuidv4() }));
      setAllResource(p);
    }
  }, [data]);

  const onDragEnd = (result) => {
    // Reorder card
    const { destination, source, draggableId, type } = result;
    // console.log("on drag--", destination, source, draggableId, type);
    if (!destination) return;
    if (destination.droppableId === source.droppableId && destination.index === source.index) return;
    if (type === 'column') {
      const newColumnOrder = Array.from(allresource);
      newColumnOrder.splice(source.index, 1);
      let l = [...allresource]?.find((m) => m?.uuid === draggableId);

      newColumnOrder?.splice(destination?.index, 0, l);
      // console.log(allresource, "-----------newColumnOrder", newColumnOrder);
      setAllResource(newColumnOrder);
      console.log('newcolold', newColumnOrder);
      // dispatch(persistColumn(newColumnOrder, d));
      // return;
    }
    const start = allresource[destination.index];
    const finish = allresource[source.index];
    console.log('start', start, 'finish', finish);
  };
  const handledelete = (index) => {
    let rajeevdata = [...allresource];
    rajeevdata.splice(index, 1);
    console.log('laser--', rajeevdata, index);
    setAllResource(rajeevdata);
  };
  const handleSaveResource = () => {
    let re = [...allresource].filter((a) => !!a?.name);
    let pe = [];
    for (let index = 0; index < re.length; index++) {
      const element = re[index];
      // console.log(element["index"], index, re);
      element['index'] = 1 + index;
      delete element.uuid;
      pe.push(element);
    }
    console.log('pe', pe);
    handleSave(pe);
  };

  let alldata = allresource.filter((a) => !!a?.name);
  return (
    <Dialog open={true} fullWidth maxWidth="md" onClose={() => {}}>
      {!addmode.open ? (
        <>
          <DialogTitle
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              color: 'primary.main',
              pb: 1
            }}
          >
            <Stack>
              <Typography variant="h4"> Steps</Typography>
              <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                You can include only 10 steps for a lesson.
              </Typography>
            </Stack>
            {alldata?.length <= 9 ? (
              <Button sx={{ mt: 2 }} variant="outlined" onClick={() => setAddMode({ open: data, data: {} })}>
                Add New Step
              </Button>
            ) : (
              <></>
            )}
          </DialogTitle>
          <DialogContent sx={{ pb: 3, px: 1, bgcolor: 'grey.5008' }}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="all-columns" direction="vertical" type="column">
                {(provided) => (
                  <Stack
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    spacing={2}
                    sx={{ height: 'calc(100% - 32px)', overflowY: 'hidden' }}
                    direction="column"
                    alignItems="flex"
                    // spacing={3}
                    width={'calc(100% - 32px)'}
                  >
                    {allresource?.map((a, index) => (
                      <ResourceCard
                        card={a}
                        handleupdate={(p) => {
                          setAddMode({ open: true, data: a, index: index });
                        }}
                        handledelete={() => handledelete(index)}
                        index={index}
                      />
                    ))}
                    {provided.placeholder}
                  </Stack>
                )}
              </Droppable>
            </DragDropContext>
          </DialogContent>
          <DialogActions sx={{ mt: 2 }}>
            <Button variant="outlined" onClick={() => handleClose()}>
              cancel
            </Button>
            <Button variant="contained" onClick={() => handleSaveResource(alldata)}>
              Save
            </Button>
          </DialogActions>
        </>
      ) : (
        <SetupTask
          data={addmode.data}
          handleClose={() => {
            setAddMode({ open: false, data: {} });
          }}
          handleupdate={(p) => {
            if (addmode?.index >= 0) {
              let a = [...allresource];
              a[addmode.index] = p;
              setAllResource([...a]);
            } else {
              setAllResource([...allresource, p]);
            }

            setAddMode({ open: false, data: {} });
          }}
        />
      )}
    </Dialog>
  );
};

const ResourceCard = ({ card, index, handleupdate, handledelete }) => {
  const theme = useTheme();
  const [openEdit, setOpenEdit] = useState(false);
  const handleOpenDetails = () => {};
  // const [addmode, setAddMode] = useState(false);

  return (
    <>
      <Draggable draggableId={card?.uuid} index={index} type="TASK" key={card?.uuid}>
        {(provided) => (
          <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
            <Paper
              sx={{
                mt: index == 0 ? 2 : 1,
                px: 2,
                width: 1,
                position: 'relative',
                boxShadow: 'none',
                border: 'none',
                '&:hover': {
                  boxShadow: (theme) => theme.customShadows.z16
                }
                // ...(attachments.length > 0 && {
                //   pt: 2,
                // }),
              }}
            >
              <Stack
                direction={'row'}
                justifyContent="space-between"
                spacing={1}
                sx={{
                  boxShadow: (theme) => theme.customShadows.z1,
                  '&:hover': {
                    boxShadow: (theme) => theme.customShadows.z16
                  }
                }}
                {...provided.dragHandleProps}
              >
                <Box sx={{ cursor: 'pointer' }}>
                  <Typography
                    // noWrap
                    variant="subtitle2"
                    color="textPrimary"
                    sx={{
                      pb: 2,
                      pl: 5,
                      pt: 2,
                      mr: 4,
                      transition: (theme) =>
                        theme.transitions.create('opacity', {
                          duration: theme.transitions.duration.shortest
                        })
                      // ...(completed && { opacity: 0.48 }),
                    }}
                  >
                    {index + 1}. {card?.name}
                  </Typography>
                </Box>

                <Stack direction={'row'}>
                  <IconButton onClick={() => handleupdate()}>
                    <Iconify icon={'material-symbols:edit'} />
                  </IconButton>

                  <IconButton onClick={() => handledelete()}>
                    <Iconify icon={'material-symbols:delete-outline'} />
                  </IconButton>
                </Stack>
                {provided.placeholder}
              </Stack>
            </Paper>
          </div>
        )}
      </Draggable>
    </>
  );
};
const SetupTask = ({ data, handleupdate, handleClose }) => {
  const [value, setValue] = useState({ name: '' });
  const [error, setError] = useState({ name: '' });
  useEffect(() => {
    if (data.name) {
      setValue({ ...data });
      // setAddMode(false);
    }
  }, [data]);

  const onsubmit = () => {
    if (!value.name) {
      setError({ name: 'Please enter the name of resource' });
      return;
    }

    if (data?.name) {
      handleupdate({ ...value });
    } else {
      handleupdate({ ...value, uuid: uuidv4() });

      // uuid: uuidv4();
    }
    setValue({ name: '' });
    setError({ name: '' });
    // setAddMode(true);
  };
  return (
    <>
      <DialogTitle>
        <IconButton onClick={() => handleClose()}>
          <Iconify color="primary.main" icon={'akar-icons:arrow-left'} size={30} />
        </IconButton>
        {data?.name ? 'Update Step' : 'Add Step'}
      </DialogTitle>
      <DialogContent>
        <Stack direction={'row'} justifyContent="center">
          <Paper
            sx={{
              mt: 2,
              p: 2,
              width: '100%',
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              boxShadow: (theme) => theme.customShadows.z1,
              '&:hover': {
                boxShadow: (theme) => theme.customShadows.z16
              }
            }}
          >
            <TextField
              label={'Enter step details'}
              sx={{ mb: 2 }}
              value={value.name}
              error={error.name}
              helperText={error.name}
              onChange={(p) => setValue({ ...value, name: p.target.value })}
            />

            {error.resource && (
              <Typography variant="body" sx={{ color: 'error.main' }}>
                {error.resource}
              </Typography>
            )}
            <Button onClick={() => onsubmit()} sx={{ mt: 2 }} variant="contained">
              Submit
            </Button>
          </Paper>
        </Stack>
      </DialogContent>
    </>
  );
};
