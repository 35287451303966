import { createSlice } from "@reduxjs/toolkit";
// import axios from "axios";
import axios from "../../utils/axios";
import fs from "fs";
import sum from "lodash/sum";
import uniqBy from "lodash/uniqBy";
import {
  COURSE_HIGHLIGHT,
  COURSE_LIST,
  COURSE_OVERVIEW_EXCEL,
  REPORT_COURSE,
  SUBSCRIPTIONS_OVERVIEW_EXCEL,
  SUBSCRIPTION_HIGHLIGHT,
  SUBSCRIPTION_LIST,
} from "src/apiUrl";
// utils
//
import { dispatch } from "../store";
import { eduleyApi } from "src/api/apiConfig";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,

  error: null,
  subscriptions: {
    data: {},
    isLoading: false,
    error: null,
  },
  hightlight: {
    data: {},
    isLoading: false,
    error: null,
  },
  subscriptionsbyId: {
    data: {},
    isLoading: false,
    error: null,
  },
  hightlightbyId: {
    data: {},
    isLoading: false,
    error: null,
  },
  subscriptionsbyCourse: {
    data: {},
    isLoading: false,
    error: null,
  },
  subscriptionsbyCourseId: {
    data: {},
    isLoading: false,
    error: null,
  },
};

const slice = createSlice({
  name: "subscriptions",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setsubscriptions(state, action) {
      state.subscriptions.data = action.payload;
      state.subscriptions.isLoading = false;
      state.subscriptions.error = null;
    },
    fetchSubscriptions(state) {
      state.subscriptions.isLoading = true;
    },
    hasErrorSubscription(state, action) {
      state.subscriptions.isLoading = false;
      state.subscriptions.error = action.payload;
    },

    // HIGHTLIGHT
    setsubscriptionhightlight(state, action) {
      state.hightlight.data = action.payload;
      state.hightlight.isLoading = false;
      state.hightlight.error = null;
    },
    setsubscriptionhightlightbyId(state, action) {
      state.hightlightbyId.data = action.payload;
      state.hightlightbyId.isLoading = false;
      state.hightlightbyId.error = null;
    },
    fetchSubscriptionHightlight(state) {
      state.hightlight.isLoading = true;
    },
    hasErrorSubscriptionHightlight(state, action) {
      state.hightlight.isLoading = false;
      state.hightlight.error = action.payload;
    },
    /// subscriptionby id

    //by courseId
    fetchSubscriptionbyCourse(state) {
      state.subscriptionsbyCourse.isLoading = true;
    },
    setSubscriptionbycourse(state, action) {
      state.subscriptionsbyCourse.data = action.payload;
      state.subscriptionsbyCourse.isLoading = false;
    },
    hasErrorSubscriptionbyCourse(state, action) {
      state.subscriptionsbyCourse.error = action.payload;
      state.subscriptionsbyCourse.isLoading = false;
    },

    //  subscription byID
    fetchSubscriptionbyCourseId(state) {
      state.subscriptionsbyCourse.isLoading = true;
    },
    setSubscriptionbycourseId(state, action) {
      state.subscriptionsbyCourseId.data = action.payload;
      state.subscriptionsbyCourse.isLoading = false;
    },
    hasErrorSubscriptionbyCourseId(state, action) {
      state.subscriptionsbyCourseId.error = action.payload;
      state.subscriptionsbyCourseId.isLoading = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {} = slice.actions;

// ----------------------------------------------------------------------

const accessToken = localStorage.getItem("accessToken");

const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${accessToken}`,
  },
};

// export const getSubscription = (data) => {
//   return async () => {
//     dispatch(slice.actions.fetchSubscriptionHightlight());
//     try {
//       const response = await axios.get(`${SUBSCRIPTION_LIST}${data}`, config);
//       dispatch(slice.actions.setsubscriptions(response.data));
//     } catch (error) {
//       dispatch(slice.actions.hasErrorSubscription(error));
//     }
//   };
// };

export const getSubscriptionhightlight = () => {
  return async () => {
    dispatch(slice.actions.fetchSubscriptionHightlight());
    try {
      const response = await eduleyApi.get(`${SUBSCRIPTION_HIGHLIGHT}`);
      dispatch(slice.actions.setsubscriptionhightlight(response.data));
    } catch (error) {
      dispatch(slice.actions.hasErrorSubscriptionhightlight(error));
    }
  };
};

export const getcoursehightlightbyId = (data) => {
  return async () => {
    // dispatch(slice.actions.fetchSubscriptionHightlight());
    try {
      const response = await axios.post(`${COURSE_HIGHLIGHT}`, data, config);
      dispatch(slice.actions.setsubscriptionhightlightbyId(response.data));
    } catch (error) {
      // dispatch(slice.actions.hasErrorSubscriptionhightlightById(error));
    }
  };
};
export const getSubscriptionbyCourse = (data) => {
  return async () => {
    dispatch(slice.actions.fetchSubscriptionbyCourse());
    try {
      const response = await eduleyApi.get(`${REPORT_COURSE}${data}`);
      dispatch(slice.actions.setSubscriptionbycourse(response.data));
    } catch (error) {
      dispatch(slice.actions.hasErrorSubscriptionbyCourse(error));
    }
  };
};

export const getSubscriptionbyCourseId = (data) => {
  return async () => {
    dispatch(slice.actions.fetchSubscriptionbyCourseId());
    try {
      const response = await eduleyApi.get(
        `${SUBSCRIPTION_LIST}${data}`,
        config
      );
      dispatch(slice.actions.setSubscriptionbycourseId(response.data));
    } catch (error) {
      dispatch(slice.actions.hasErrorSubscriptionbyCourseId(error));
    }
  };
};
export const courseOverviewdownload = (data) => {
  console.log(data);
  const c = { ...config };
  return async () => {
    axios
      .post(`${COURSE_OVERVIEW_EXCEL}`, data, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          // 'Content-Disposition': "attachment; filename=template.xlsx",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `course_overview-${new Date()}.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => console.log(error));
  };
};
export const subscriptionsOverviewdownload = (data) => {
  console.log(data);
  const c = { ...config, responseType: "blob" };

  return async () => {
    axios
      .post(`${SUBSCRIPTIONS_OVERVIEW_EXCEL}`, data, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          // 'Content-Disposition': "attachment; filename=template.xlsx",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `subscriptions-${new Date()}.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => console.log(error));
  };
};
// var blob = new Blob(
//   [data],
//   { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," }
// );

// // Programatically create a link and click it:
// var a = document.createElement("a");
// a.href = URL.createObjectURL(blob);
// a.download = fileName;
// a.click();
