import { alpha, Divider, Stack, styled, Typography, useTheme } from '@mui/material';
import Markdown from 'src/components/Markdown';
import LessonResource from 'src/sections/@dashboard/addcurriculum/addlesson/component/LessonResource';
import UserNextTask from 'src/sections/@dashboard/addcurriculum/addlesson/component/UserNextTask';
import MediaModal from 'src/sections/media/MediaModal';
import { fDate } from 'src/utils/formatTime';

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  width: 180,
  fontSize: 13,
  flexShrink: 0,
  color: theme.palette.text.secondary
}));
const AssignmentView = ({ data }) => {
  const theme = useTheme();
  return (
    <Stack direction="column" sx={{ width: '100%' }} spacing={2}>
      <Stack direction="row" sx={{ mt: 2 }} justifyContent={'center'}>
        <Typography variant="h5" textAlign={'center'} sx={{ width: '100%', color: alpha(theme.palette.primary.main, 0.6) }}>
          {data?.name}
        </Typography>
      </Stack>
      {data?.content && (
        <>
          <Divider />
          <Stack direction="row" alignItems={'center'}>
            <LabelStyle sx={{ mt: 2 }}>Attachment</LabelStyle>
            <MediaModal media={data?.content} viewOnly={true} addingMedia={(p) => {}} />
          </Stack>
        </>
      )}
      <Divider />
      <LabelStyle sx={{ mt: 2 }}>Description</LabelStyle>
      <div style={{ width: '100%' }}>
        <Markdown children={data?.description} />
      </div>

      {data?.resources?.length > 0 && (
        <>
          <Divider />
          <Stack direction="row">
            <LabelStyle sx={{ mt: 2 }}>Resource</LabelStyle>
            <LessonResource data={data?.resources || []} handleSave={(p) => {}} viewOnly={true} />
          </Stack>
        </>
      )}
      <Divider />
      <Stack direction="row">
        <LabelStyle sx={{ mt: 2 }}>Next Steps</LabelStyle>
        <UserNextTask data={data?.additional_steps} handleSave={(p) => {}} viewOnly={true} />
      </Stack>
      <Divider />
      <Stack direction="row" alignItems={'center'}>
        <LabelStyle>Number of Hours </LabelStyle>
        <Typography variant="h5" sx={{ color: theme.palette.primary.main, fontWeight: 400 }}>
          {data?.time_allotment}
        </Typography>
      </Stack>
      <Divider />

      <Stack direction="row" alignItems={'center'}>
        <LabelStyle>Start Date</LabelStyle>
        <Typography variant="h5" sx={{ color: theme.palette.primary.main, fontWeight: 400 }}>
          {fDate(data?.start_datetime)}
        </Typography>
      </Stack>
      <Divider />

      <Stack direction="row" alignItems={'center'}>
        <LabelStyle>Can Skip</LabelStyle>
        <Typography variant="h5" sx={{ color: theme.palette.primary.main, fontWeight: 400 }}>
          {data?.can_skip ? 'Yes' : 'No'}
        </Typography>
      </Stack>
      <Divider />
      {/* Weightage Group in Grade
       */}
      <Stack direction="row" alignItems={'center'}>
        <LabelStyle>Weightage Group in Grade</LabelStyle>
        <Typography variant="h5" sx={{ color: theme.palette.primary.main, fontWeight: 400 }}>
          {data?.transcript_weightage?.name}
        </Typography>
      </Stack>
      <Divider />
      {/* Weightage Group in Grade
       */}
      <Stack direction="row" alignItems={'center'}>
        <LabelStyle>Is Gated</LabelStyle>
        <Stack direction={'column'}>
          <Typography variant="h5" sx={{ color: theme.palette.primary.main, fontWeight: 400 }}>
            {!!data?.can_proceed_without_marking ? 'Yes' : 'No'}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              mt: 2,
              fontSize: 12,
              textDecoration: data?.can_skip ? 'line-through' : ''
            }}
            color="text.secondary"
          >
            Can student proceed to next content without instructor marking the assignment .
          </Typography>
        </Stack>
      </Stack>
      <Divider />
      <Stack direction="row" alignItems={'center'}>
        <LabelStyle sx={{ mt: 1 }}>Submission type </LabelStyle>
        <Typography variant="h5" sx={{ color: theme.palette.primary.main, fontWeight: 400 }}>
          {data?.submission_type == 'uploaded' ? 'File Upload' : 'Subjective Subjective'}
        </Typography>
      </Stack>
      <Divider />
      <Stack direction="row" alignItems={'center'}>
        <LabelStyle sx={{ mt: 1 }}>Total Marks </LabelStyle>
        <Typography variant="h5" sx={{ color: theme.palette.primary.main, fontWeight: 400 }}>
          {data?.total_marks}
        </Typography>
      </Stack>
      <Divider />

      {data?.submission_type == 'subjective' && (
        <Stack direction="column" alignItems={'center'} spacing={3}>
          <Typography variant="h5" textAlign={'center'} sx={{ width: '100%', color: alpha(theme.palette.primary.main, 0.6) }}>
            Question List
          </Typography>

          <Divider sx={{ mb: 1 }} />
          {data?.questions?.map((a, index) => (
            <Stack direction="row" sx={{ width: '100%' }} spacing={2}>
              <Typography variant="h7" sx={{ color: theme.palette.primary.main, fontWeight: 400 }}>
                {index + 1}.
              </Typography>
              <Typography variant="h7" sx={{ color: theme.palette.primary.main, fontWeight: 400 }}>
                {a.name}
              </Typography>
            </Stack>
          ))}
        </Stack>
      )}
    </Stack>
  );
};

export default AssignmentView;
