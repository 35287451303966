import moment from 'moment';
import PropTypes from 'prop-types';
import { createContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { eduleyApi } from 'src/api/apiConfig';
import { COHORT_ATTENDANCE } from 'src/apiUrl';
import useAuth from 'src/hooks/useAuth';

// hooks
// utils
// config

// ----------------------------------------------------------------------

const LandingContext = createContext({});

// ----------------------------------------------------------------------

LandingProvider.propTypes = {
  children: PropTypes.node
};

function LandingProvider({ children }) {
  const [hasCohortAttendance, setCohortAttendance] = useState(false);
  const { user } = useAuth();
  const dispatch = useDispatch();
  const { hasAddDocumentOpened } = useSelector((state) => state.configuration);
  const isAdmin = user?.groups?.includes(2);
  useEffect(() => {
    if (isAdmin) {
      setCohortAttendance(isAdmin);
    } else if (isAdmin === false) {
      isCohortAttendanceAvailable();
    }
  }, [isAdmin]);

  async function isCohortAttendanceAvailable() {
    try {
      const response = await eduleyApi.get(COHORT_ATTENDANCE, {
        params: {
          date__gte: moment().add(0, 'day').format('YYYY-MM-DD'),
          date__lte: moment().add(0, 'day').format('YYYY-MM-DD'),
          status: 'pending'
        }
      });
      setCohortAttendance(response.data.count > 0);
    } catch (e) {}
  }

  console.log('Admin---:', isAdmin);

  return <LandingContext.Provider value={{ hasCohortAttendance, isCohortAttendanceAvailable }}>{children}</LandingContext.Provider>;
}

export { LandingProvider, LandingContext };
