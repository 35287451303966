export function getPageLimit(pageName) {
  let p = +localStorage.getItem(pageName);
  console.log(pageName, p, "raje", p ? p : 5);
  return p ? p : 5;
}
export function getPageLimitWithDefault(pageName, d) {
  let p = +localStorage.getItem(pageName);
  console.log(pageName, p);
  return p ? p : d || 5;
}

export function setPageLimit(pageName, limit) {
  localStorage.setItem(pageName, limit);
  return limit;
}
