import React, { useEffect, useState } from 'react';
import { eduleyApi } from 'src/api/apiConfig';
import { UPLOAD_FILE } from 'src/apiUrl';

export default function useUpload(file, config) {
  const [loading, setLoading] = useState(false);
  const [id, setID] = useState(null);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (typeof file !== 'undefined' && typeof file !== 'string' && !id && file) {
      getID();
    }
  }, [file]);

  const progressConfig = {
    /**
     * Handle the upload progress event.
     *
     * @param {Object} progressEvent - The progress event object.
     */
    onUploadProgress: (progressEvent) => {
      // Calculate the percentage of completion
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

      // Update the progress percentage
      setProgressPercentage(percentCompleted);
    }
  };

  /**
   * Uploads a file and retrieves the ID from the response.
   */
  async function getID() {
    setLoading(true); // Set loading to true

    // Create the payload with file data
    let payload = new FormData();
    payload.append('name', file.name);
    payload.append('file', file);
    payload.append('size_in_mb', file.size / 1024 / 1024);

    try {
      // Send the file to the server and retrieve the response
      const response = await eduleyApi.post(`${UPLOAD_FILE}`, payload, progressConfig);
      setLoading(false); // Set loading to false
      setID(response.data.id); // Set the ID from the response
      setData(response.data);
      config.onSuccess(response.data.id, response.data);
    } catch (e) {
      setError(e); // Set the error
      setLoading(false); // Set loading to false
    }
  }

  function resetMedia() {
    setData(null);
    setID(null);
    setProgressPercentage(0);
    setError(false);
    setLoading(false);
  }

  return {
    isMediaUploading: loading,
    mediaId: id,
    progressPercentage,
    file: data,
    mediaError: error,
    resetMedia
  };
}
