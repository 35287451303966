import PropTypes from "prop-types";
import { useState } from "react";
// @mui
import { useTheme } from "@mui/material/styles";
import { TableRow, TableCell } from "@mui/material";
// utils
import { fDate } from "../../../../utils/formatTime";
import { fAmount, fCurrency } from "../../../../utils/formatNumber";
// components
import Label from "../../../../components/Label";

// ----------------------------------------------------------------------

InvoiceTableRow.propTypes = {
  row: PropTypes.object.isRequired,
};

export default function InvoiceTableRow({ row }) {
  const theme = useTheme();

  const { created_date, status, amount, currency, remark } = row;

  const [openMenu, setOpenMenuActions] = useState(null);

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  return (
    <TableRow hover selected={false}>
      <TableCell align="left">{fDate(created_date)}</TableCell>

      <TableCell align="center">{fAmount(amount)}</TableCell>

      <TableCell align="left">
        <Label
          variant={theme.palette.mode === "light" ? "ghost" : "filled"}
          color={
            (status === "success" && "success") ||
            (status === "failed" && "error") ||
            (status === "requested" && "warning") ||
            (status === "in process" && "info") ||
            "success"
          }
          sx={{ textTransform: "capitalize" }}
        >
          {status}
        </Label>
      </TableCell>
      <TableCell align="left">{remark}</TableCell>
    </TableRow>
  );
}
