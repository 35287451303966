import { Box, CircularProgress, LinearProgress } from "@mui/material";
import React from "react";
import Iconify from "../Iconify";
import Logo from "../Logo";
import useAuth from "src/hooks/useAuth";

const TableLoader = ({ loading }) => {
  const { instituteinfo } = useAuth();
  // console.log(instituteinfo);
  return (
    <>
      {loading && (
        <Box
          sx={{
            //   disablePointerEvents

            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "rgba(255, 255, 255, 0.8)",
            zIndex: 9999,
          }}
          disablePointerEvents
        >
          {/* <Iconify
            icon={"eos-icons:three-dots-loading"}
            // color={"primary.main"}
            sx={{ color: "primary.main" }}
            size={50}
            width={150}
            height={150}
          /> */}
          {/* design animated logo */}

          {/* loader background like skelton body */}
          <Box sx={{ width: "145px" }}>
            {/* logo is center align */}
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              {instituteinfo?.favicon ? (
                <img
                  src={instituteinfo?.favicon}
                  alt="logo"
                  style={{
                    width: "50px",
                    height: "50px",
                    // backgroundColor: "red",
                    // marginTop: "-30px",
                  }}
                />
              ) : (
                <Logo sx={{ mb: -4 }} />
              )}
            </Box>
            <LinearProgress sx={{ mt: 2 }} />
          </Box>
          <LinearProgress />
        </Box>
      )}
    </>
  );
};

export default TableLoader;
