// @mui
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";
import { alpha } from "@material-ui/core";
import React from "react";

// ----------------------------------------------------------------------

export default function UploadIllustration({ ...other }) {
  const theme = useTheme();
  const PRIMARY_MAIN = alpha(theme.palette.primary.main, 0.4);
  const PRIMARY_DARK = theme.palette.primary.dark;
  const PRIMARY_DARKER = theme.palette.primary.darker;

  return (
    <Box {...other}>
      <svg xmlns='http://www.w3.org/2000/svg' x='0' y='0' enableBackground='new 0 0 390 400' version='1.1' viewBox='0 0 390 400' xmlSpace='preserve'>
        <g fill='none' stroke={PRIMARY_MAIN} strokeMiterlimit='10' strokeWidth='0.37'>
          <path d='M469.09 91.38s-79.62 95.61-120.4 10.83-86.82 17.3-155.8-45.68c-74.8-68.29-24.73-143.82-24.73-143.82'></path>
          <path d='M468.29 90.46s-78.79 94.99-119.5 10.5c-40.39-83.88-86.17 16.13-154.24-45.89-73.68-67.17-24.81-141.58-24.69-142.52'></path>
          <path d='M467.5 89.54S389.54 183.9 348.89 99.7c-40-82.98-85.52 14.96-152.67-46.1-72.56-66.05-24.9-139.33-24.65-141.22'></path>
          <path d='M466.7 88.62s-77.13 93.73-117.71 9.83c-39.61-82.08-84.87 13.79-151.11-46.32-71.45-64.93-24.99-137.09-24.62-139.92'></path>
          <path d='M465.9 87.7s-76.3 93.1-116.82 9.49c-39.22-81.18-84.22 12.62-149.55-46.53-70.33-63.82-25.08-134.84-24.58-138.62'></path>
          <path d='M465.1 86.78s-75.47 92.48-115.92 9.16c-38.83-80.29-83.57 11.45-147.98-46.74-69.21-62.7-25.16-132.6-24.55-137.32'></path>
          <path d='M464.31 85.86s-74.64 91.85-115.03 8.82c-38.44-79.39-82.92 10.28-146.42-46.96-68.1-61.58-25.25-130.36-24.51-136.02'></path>
          <path d='M463.51 84.94s-73.81 91.22-114.13 8.49c-38.05-78.49-82.27 9.11-144.86-47.17-66.98-60.46-25.34-128.11-24.47-134.72'></path>
          <path d='M462.71 84.02s-72.98 90.59-113.24 8.15c-37.66-77.59-81.62 7.94-143.29-47.38-65.86-59.35-25.43-125.87-24.44-133.42'></path>
          <path d='M461.91 83.1s-72.15 89.96-112.34 7.82c-37.27-76.69-80.96 6.77-141.73-47.6-64.75-58.23-25.51-123.62-24.4-132.12'></path>
          <path d='M461.11 82.18s-71.32 89.34-111.45 7.48c-36.88-75.79-80.31 5.6-140.17-47.81-63.63-57.11-25.6-121.38-24.37-130.82'></path>
          <path d='M460.32 81.26s-70.49 88.71-110.55 7.15c-36.49-74.89-79.66 4.43-138.6-48.02-62.51-55.99-25.69-119.14-24.33-129.52'></path>
          <path d='M459.52 80.34s-69.65 88.08-109.66 6.81c-36.1-73.99-79.01 3.26-137.04-48.24-61.4-54.88-25.78-116.89-24.29-128.23'></path>
          <path d='M458.72 79.42s-68.82 87.45-108.76 6.48c-35.71-73.09-78.36 2.09-135.48-48.45C154.2-16.31 188.62-77.2 190.23-89.48'></path>
          <path d='M457.92 78.5s-68.16 86.9-107.87 6.14c-35.46-72.12-77.81 1.04-133.92-48.66-59.27-52.52-25.95-112.41-24.22-125.63'></path>
          <path d='M457.13 77.59s-67.33 86.28-106.97 5.81c-35.08-71.22-77.17-.12-132.35-48.88-58.16-51.39-26.04-110.16-24.18-124.33'></path>
          <path d='M456.33 76.67s-66.51 85.66-106.08 5.47c-34.69-70.31-76.53-1.28-130.79-49.09-57.05-50.27-26.13-107.92-24.15-123.03'></path>
          <path d='M455.53 75.75s-65.69 85.03-105.18 5.14c-34.31-69.41-75.88-2.44-129.23-49.3-55.94-49.15-26.21-105.69-24.11-121.74'></path>
          <path d='M454.73 74.83s-64.87 84.41-104.29 4.8c-33.92-68.51-75.24-3.6-127.66-49.52-54.83-48.02-26.3-103.43-24.08-120.43'></path>
          <path d='M453.94 73.91s-64.04 83.78-103.39 4.46c-33.54-67.61-74.59-4.77-126.1-49.73-53.72-46.89-26.39-101.19-24.04-119.13'></path>
          <path d='M453.14 72.99s-63.22 83.16-102.5 4.13c-33.15-66.71-73.94-5.93-124.54-49.94-52.61-45.77-26.48-98.95-24-117.83'></path>
          <path d='M452.34 72.07s-62.4 82.54-101.6 3.79c-32.76-65.81-73.3-7.09-122.97-50.15C176.27-18.94 201.2-71 203.8-90.82'></path>
          <path d='M451.54 71.15s-61.57 81.91-100.7 3.46c-32.38-64.91-72.65-8.26-121.41-50.37-50.39-43.52-26.65-94.46-23.93-115.23'></path>
          <path d='M450.74 70.23s-60.75 81.29-99.81 3.12c-31.99-64-72.01-9.42-119.85-50.58-49.28-42.4-26.74-92.22-23.9-113.93'></path>
          <path d='M449.95 69.31s-59.92 80.66-98.91 2.79C319.44 9 279.68 61.52 232.76 21.31 184.6-19.96 205.93-68.66 208.9-91.32'></path>
          <path d='M449.15 68.39s-59.09 80.03-98.02 2.45c-31.21-62.2-70.71-11.75-116.72-51.01-47.05-40.15-26.91-87.72-23.82-111.33'></path>
          <path d='M448.35 67.47s-58.27 79.41-97.12 2.12c-30.82-61.3-70.07-12.91-115.16-51.22-45.94-39.03-27-85.49-23.79-110.03'></path>
          <path d='M447.55 66.55s-57.44 78.78-96.23 1.78C320.89 7.93 281.9 54.26 237.73 16.9c-44.82-37.91-27.09-83.24-23.75-108.73'></path>
          <path d='M446.76 65.63s-56.61 78.16-95.33 1.45C321.39 7.57 282.66 51.84 239.4 15.43c-43.71-36.79-27.18-81-23.71-107.44'></path>
          <path d='M445.96 64.71s-55.78 77.53-94.44 1.11c-29.65-58.61-68.12-16.4-110.47-51.86-42.59-35.67-27.26-78.75-23.68-106.14'></path>
          <path d='M445.16 63.79s-54.95 76.9-93.54.78C322.36 6.86 284.14 47 242.71 12.5c-41.48-34.55-27.35-76.51-23.64-104.84'></path>
          <path d='M444.36 62.87s-54.12 76.27-92.65.44C322.85 6.5 284.89 44.58 244.37 11.03c-40.36-33.43-27.44-74.27-23.61-103.54'></path>
          <path d='M443.57 61.95s-53.29 75.65-91.75.11c-28.47-55.91-66.18-19.9-105.78-52.5-39.25-32.31-27.53-72.02-23.57-102.24'></path>
          <path d='M442.77 61.03s-52.46 75.02-90.86-.23C323.83 5.79 286.38 39.74 247.7 8.09c-38.13-31.19-27.61-69.78-23.53-100.94'></path>
          <path d='M441.97 60.11s-51.62 74.39-89.96-.56C324.33 5.43 287.13 37.31 249.36 6.62c-37.01-30.07-27.7-67.54-23.5-99.64'></path>
          <path d='M441.17 59.19s-50.79 73.76-89.07-.9c-27.28-53.21-64.22-23.4-101.08-53.13-35.89-28.95-27.79-65.29-23.46-98.34'></path>
          <path d='M440.37 58.27s-49.95 73.13-88.17-1.23c-26.89-52.32-63.57-24.57-99.53-53.35-34.78-27.84-27.88-63.05-23.43-97.04'></path>
          <path d='M439.58 57.35s-49.12 72.5-87.28-1.57c-26.5-51.43-62.92-25.74-97.96-53.57-33.66-26.72-27.96-60.81-23.39-95.74'></path>
          <path d='M438.78 56.43s-48.28 71.87-86.38-1.9C326.3 4 290.13 27.62 256 .75c-32.54-25.61-28.05-58.56-23.35-94.44'></path>
          <path d='M437.98 55.51s-47.44 71.23-85.49-2.24c-25.7-49.63-61.61-28.09-94.84-53.99-31.41-24.49-28.14-56.32-23.32-93.14'></path>
          <path d='M437.18 54.59s-46.6 70.6-84.59-2.57c-25.3-48.74-60.95-29.26-93.27-54.21-30.29-23.38-28.23-54.08-23.28-91.84'></path>
          <path d='M436.39 53.67s-45.76 69.97-83.7-2.91c-24.9-47.84-60.3-30.44-91.71-54.42-29.17-22.27-28.31-51.83-23.25-90.54'></path>
          <path d='M435.59 52.75s-44.92 69.34-82.8-3.24c-24.5-46.95-59.64-31.61-90.15-54.63-28.04-21.16-28.4-49.59-23.21-89.24'></path>
          <path d='M434.79 51.83s-44.08 68.7-81.91-3.58C328.78 2.2 293.9 15.47 264.3-6.59c-26.92-20.05-28.49-47.34-23.17-87.95'></path>
          <path d='M433.99 50.92s-43.23 68.07-81.01-3.91c-23.7-45.16-58.32-33.97-87.02-55.06C240.17-27 237.39-53.16 242.83-94.7'></path>
          <path d='M433.2 50s-42.25 67.36-80.12-4.25C329.86 1.44 295.42 10.6 267.62-9.52c-24.66-17.84-28.66-42.86-23.1-85.35'></path>
          <path d='M432.4 49.08s-41.42 66.73-79.22-4.58c-22.83-43.41-56.99-36.34-83.89-55.48-23.53-16.74-28.75-40.61-23.06-84.05'></path>
          <path d='M431.6 48.16s-40.59 66.1-78.33-4.92C330.83.73 296.95 5.71 270.94-12.46c-22.4-15.65-28.84-38.37-23.03-82.75'></path>
          <path d='M430.8 47.24s-39.76 65.48-77.43-5.25C331.32.38 297.72 3.26 272.6-13.92c-21.26-14.55-28.93-36.13-22.99-81.45'></path>
          <path d='M430 46.32s-38.93 64.85-76.54-5.59C331.81.02 298.49.8 274.27-15.4c-20.13-13.46-29.01-33.88-22.96-80.15'></path>
          <path d='M429.21 45.4s-38.1 64.22-75.64-5.92C332.3-.34 299.29-1.7 275.93-16.86c-18.97-12.41-29.1-31.64-22.92-78.85'></path>
          <path d='M428.41 44.48s-37.27 63.59-74.74-6.26c-37.47-69.85-113.2-25.37-98.96-134.1'></path>
        </g>
      </svg>
    </Box>
  );
}
