import PropTypes from 'prop-types';
// @mui
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, Button, Typography } from '@mui/material';
// hooks
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { HEADER, NAVBAR } from '../../../config';
// components
import Logo from '../../../components/Logo';
import Iconify from '../../../components/Iconify';
import { IconButtonAnimate } from '../../../components/animate';
//
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import ContactsPopover from './ContactsPopover';
import NotificationsPopover from './NotificationsPopover';
import { PATH_DASHBOARD } from 'src/routes/paths';
import useLanding from 'src/hooks/useLanding';
import { m } from 'framer-motion';
import useAuth from 'src/hooks/useAuth';

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout'
})(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: 'none',
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT
    }),
    ...(verticalLayout && {
      width: '100%',
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      backgroundColor: theme.palette.background.default
    })
  }
}));

// ----------------------------------------------------------------------

DashboardHeader.propTypes = {
  onOpenSidebar: PropTypes.func,
  isCollapse: PropTypes.bool,
  verticalLayout: PropTypes.bool
};

export default function DashboardHeader({ onOpenSidebar, isCollapse = false, verticalLayout = false }) {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, instituteinfo } = useAuth();
  const isShowAttendance = location.pathname !== PATH_DASHBOARD.cohortAttendance.list;
  const isAdmin = user?.groups?.includes(2);
  const isInstructor = user?.groups?.includes(3);
  const { hasCohortAttendance } = useLanding();
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;

  const isDesktop = useResponsive('up', 'lg');

  console.log('Location:', location.pathname);

  return (
    <RootStyle isCollapse={isCollapse} isOffset={isOffset} verticalLayout={verticalLayout}>
      <Toolbar
        sx={{
          minHeight: '100% !important',
          px: { lg: 5 }
        }}
      >
        {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}

        {!isDesktop && (
          <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        )}

        {/* <Searchbar /> */}
        <Stack direction="row" alignItems="center" justifyContent="center" sx={{ position: 'relative' }}>
          {hasCohortAttendance && !isAdmin && isInstructor && (
            <m.div
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              transition={{
                type: 'spring',
                stiffness: 300,
                damping: 20,
                duration: 0.8,
                repeat: Infinity,
                repeatType: 'reverse'
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                spacing={2}
                onClick={() => navigate(PATH_DASHBOARD.cohortAttendance.list)}
                sx={{
                  px: 3.5,
                  py: 1,
                  backgroundColor: (theme) => alpha(theme.palette.info.main, 0.1),
                  borderRadius: 1,
                  cursor: 'pointer'
                }}
              >
                <Iconify icon="solar:bell-off-bold-duotone" height={26} width={26} color="info.dark" />
                <Stack>
                  <Typography variant="subtitle2" color="info.dark">
                    Attendance pending to marked!
                  </Typography>
                  <Typography variant="body2" color="info.dark">
                    You have still not marked all the attendance for your cohort(s) for today
                  </Typography>
                </Stack>
              </Stack>
            </m.div>
          )}
        </Stack>

        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={{ xs: 0.5, sm: 2 }}>


          {/* <LanguagePopover /> */}
          {/* <NotificationsPopover /> */}
          {/* <ContactsPopover /> */}
          <AccountPopover />
        </Stack>
      </Toolbar>
    </RootStyle>
  );
}
