import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
// hooks
import useAuth from "../hooks/useAuth";
// routes
import { PATH_DASHBOARD } from "../routes/paths";

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default function GuestGuard({ children }) {
  const { isAuthenticated, user } = useAuth();
  console.log("isAuthenticated", isAuthenticated, user);
  //  2, name: 'Institute Admin',
  //  4, name: 'Accounts',
  //  3, name: 'Mentor',
  //  5, name: 'co-mentor',
  //  6, name: 'Counsellor',

  if (isAuthenticated) {
    if (user.groups?.includes(2)) {
      return <Navigate to="/program" />;
    }
    if (user?.groups?.includes(3)) {
      return <Navigate to='/program' />
    }
    if (user?.groups.includes(4)) {
      return <Navigate to='/analytics/program-performance' />
    }
    if (user?.groups.includes(5)) {
      return <Navigate to='/grades/quiz' />
    }
    if (user?.groups.includes(6)) {
      return <Navigate to='/student-profile' />
    }
    return <Navigate to="/program" />;
  }

  return <>{children}</>;
}
