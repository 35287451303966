import { Avatar, Stack, Typography } from '@mui/material';

export default function DisabledUserDocument() {
  return (
    <Stack
      sx={{
        p: 2.5,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: 1,
        bgcolor: 'background.neutral'
      }}
      spacing={2.5}
    >
      <Avatar sx={{ width: 150, height: 150 }} src={'https://img.icons8.com/bubbles/500/documents.png'} />
      <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'center' }}>
        Enable student documents to let the students upload their documents
      </Typography>
    </Stack>
  );
}
