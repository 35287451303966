import React from "react";
import { IconButton, useTheme, Tooltip } from "@mui/material";
import Iconify from "./Iconify";
function IconifyButton({ sx = {}, icon = "", iconSize = 20, title = '', ...other }) {
  const theme = useTheme();
  return (<Tooltip title={title}>
    <IconButton
      variant="contained"
      color="primary"
      size="small"
      aria-label="edit"
      // size="medium"
      {...other}
      sx={{
        cursor: "pointer",
        "&:hover": {
          backgroundColor:
            theme.palette.mode === "light"
              ? theme.palette.primary.lighter
              : theme.palette.primary.light,
        },
        ...sx,
      }}
    >
      <Iconify
        icon={icon}
        // color={iconColor}
        height={iconSize}
        width={iconSize}
      />
    </IconButton>
  </Tooltip>
  );
}

export default IconifyButton;
