import React from 'react'
import { useLocation } from 'react-router'
import { AddCourseDocument, AddInstituteDocument } from './add-document'

const AddDocument = ({ ...other }) => {
  const param = useLocation()

  return (<>
    {!param.pathname.includes('theme') ?
      <AddCourseDocument {...other} />
      :
      <AddInstituteDocument {...other} />
    }
  </>

  )
}

export default AddDocument
