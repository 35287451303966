// @mui
import { LoadingButton } from "@mui/lab";
import {
  Stack,
  Typography,
  CircularProgress,
  Card,
  Box,
  Dialog,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Avatar,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
// utils
// components
import Iconify from "src/components/Iconify";
import Image from "src/components/Image";
import Label from "src/components/Label";
import {
  addPayoutAccount,
  editPayoutAccount,
} from "src/redux/slices/fund_management";
import { useDispatch } from "src/redux/store";
import { BankCardfill, InteracCard as EditInterac, PaypalCard as EditPaypal } from "./AccountCards";

// ----------------------------------------------------------------------

const BankCard = ({ data, updateCard }) => {
  console.log(data);
  const [edit, setEdit] = useState(false);
  const {
    beneficiary_account_number = "",
    beneficiary_address = "",
    beneficiary_bank,
    beneficiary_name = "",

    is_active = false,
    logo
  } = data;
  let l = beneficiary_account_number.length
  let pre = ('xxxxxxxxxxx') + beneficiary_account_number.slice(l - 4, l)
  return (
    <>
      <Card
        sx={{
          // border: `2px solid`,
          // color: (theme) => theme.palette[color].darker,
          height: 110,
          "& .MuiCardHeader-action": {
            alignSelf: "center",
          },
          "&:hover": {
            opacity: 1.2,
            boxShadow: "0px 0px 10px rgba(1, 0, 0, 0.1)",
          },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          // justifyContent="center"
          sx={{ minHeight: 102, position: "relative", p: 3 }}
        >
          <Label
            variant="filled"
            color={is_active ? "info" : "error"}
            sx={{
              top: 16,
              right: 16,
              zIndex: 9,
              position: "absolute",
              // textTransform: "uppercase",
            }}
          >
            {is_active ? "Active" : "In Review"}
          </Label>

          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{ position: "relative" }}
          >
            <Image
              key={'attachments'}
              src={logo}
              // onClick={() => handleOpenLightbox(attachments)}
              sx={{
                m: 0.5,
                width: 64,
                height: 64,
                borderRadius: 1,
                cursor: "pointer",
              }}
            />


          </Stack>

          <Iconify
            icon={"clarity:edit-solid"}
            sx={{
              color: "grey.500",
              position: "absolute",
              bottom: 10,
              right: 8,
              cursor: "pointer",
            }}
            onClick={() => setEdit(true)}
            width={20}
            height={20}
          />
          <Stack spacing={0.5} sx={{ ml: 2 }}>
            <Typography variant="body">{beneficiary_name}</Typography>

            <Typography variant="subtitle2" sx={{ color: "primary.main" }}>
              {pre}
            </Typography>
            <Typography variant="caption">{beneficiary_bank}</Typography>
          </Stack>
        </Stack>
      </Card>
      {edit && (
        <BankCardfill
          data1={data}
          cancelling={() => {
            updateCard(data);
            setEdit(false);
          }}
          id={data.account_type}
        />
      )}
    </>
  );
}

const InteracCard = ({ data, updateCard }) => {
  // console.log(data);

  const [edit, setEdit] = useState(false);
  const { beneficiary_account_number = "", logo, beneficiary_address = "", beneficiary_bank = "", beneficiary_name = "", institute = "", is_active = false, interac = '' } = data;
  let suf = interac.slice(interac.lastIndexOf("@"), interac.length)

  let pre = interac.slice(0, interac.lastIndexOf("@")).split('').reverse().join('').substr(4).split('').reverse().join('') + 'xxxx' + suf

  return (
    <>
      <Card
        sx={{
          // border: `2px solid`,
          // color: (theme) => theme.palette[color].darker,
          height: 110,
          "& .MuiCardHeader-action": {
            alignSelf: "center",
          },
          "&:hover": {
            opacity: 1.2,
            boxShadow: "0px 0px 10px rgba(0, 0, 1, 0.1)",
          },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          // justifyContent="center"
          sx={{ minHeight: 102, position: "relative", p: 3 }}
        >
          <Label
            variant="filled"
            color={is_active ? "info" : "error"}
            sx={{
              top: 16,
              right: 16,
              zIndex: 9,
              position: "absolute",
              // textTransform: "uppercase",
            }}
          >
            {is_active ? "Active" : "In Review"}
          </Label>

          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{ position: "relative" }}
          >
            <Image
              key={'attachments'}
              src={logo}
              // onClick={() => handleOpenLightbox(attachments)}
              sx={{
                m: 0.5,
                width: 64,
                height: 64,
                borderRadius: 1,
                cursor: "pointer",
              }}
            />



          </Stack>

          <Iconify
            icon={"clarity:edit-solid"}
            sx={{
              color: "grey.500",
              position: "absolute",
              bottom: 10,
              right: 8,
              cursor: "pointer",
            }}
            onClick={() => setEdit(true)}
            width={20}
            height={20}
          />
          <Stack spacing={0.5} sx={{ ml: 2 }}>
            <Typography variant="body">{'Interac Account'}</Typography>

            <Typography variant="subtitle2" sx={{ color: "primary.main" }}>
              {pre}
            </Typography>
            <Typography variant="caption">{beneficiary_address}</Typography>
          </Stack>
        </Stack>
      </Card>
      {edit && (
        <EditInterac
          data1={data}
          cancelling={() => {
            updateCard(data);
            setEdit(false);
          }}
          id={data.account_type}
        />
      )}
    </>
  );
}
const PaypalCard = ({ data, updateCard }) => {
  console.log(data);
  const [edit, setEdit] = useState(false);
  const {

    beneficiary_address = "",

    is_active = false,
    paypal = '', logo
  } = data;
  let suf = paypal.slice(paypal.lastIndexOf("@"), paypal.length)

  let pre = paypal.slice(0, paypal.lastIndexOf("@")).split('').reverse().join('').substr(4).split('').reverse().join('') + 'xxxx' + suf
  return (
    <>
      <Card
        sx={{
          // border: `2px solid`,
          // color: (theme) => theme.palette[color].darker,
          height: 110,
          "& .MuiCardHeader-action": {
            alignSelf: "center",
          },
          "&:hover": {
            opacity: 1.2,
            boxShadow: "0px 0px 10px rgba(0, 0, 1, 0.1)",
          },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          // justifyContent="center"
          sx={{ minHeight: 100, position: "relative", p: 3 }}
        >
          <Label
            variant="filled"
            color={is_active ? "info" : "error"}
            sx={{
              top: 16,
              right: 16,
              zIndex: 9,
              position: "absolute",
              // textTransform: "uppercase",
            }}
          >
            {is_active ? "Active" : "In Review"}
          </Label>

          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{ position: "relative" }}
          >
            <Image
              key={'attachments'}
              src={logo}
              // onClick={() => handleOpenLightbox(attachments)}
              sx={{
                m: 0.5,
                width: 64,
                height: 64,
                borderRadius: 1,
                cursor: "pointer",
              }}
            />



          </Stack>

          <Iconify
            icon={"clarity:edit-solid"}
            sx={{
              color: "grey.500",
              position: "absolute",
              bottom: 10,
              right: 8,
              cursor: "pointer",
            }}
            onClick={() => setEdit(true)}
            width={20}
            height={20}
          />
          <Stack spacing={0.5} sx={{ ml: 2 }}>
            <Typography variant="body">{'PayPal Account'}</Typography>

            <Typography variant="subtitle2" sx={{ color: "primary.main" }}>
              {pre}
            </Typography>
            <Typography variant="caption">{beneficiary_address}</Typography>
          </Stack>
        </Stack>
      </Card>
      {edit && (
        <EditPaypal
          data1={data}
          cancelling={() => {
            updateCard(data);
            setEdit(false);
          }}
          id={data.account_type}
        />
      )}
    </>
  );
}


export { BankCard, InteracCard, PaypalCard }
// const AddrequestModal = ({ cancelling, data1 = {} }) => {
//   const dispatch = useDispatch();
//   const [loading, setLoading] = useState(false);
//   const { enqueueSnackbar } = useSnackbar();
//   const [data, setData] = useState({
//     beneficiary_name: "",
//     beneficiary_address: "",
//     beneficiary_bank: "",
//     beneficiary_account_number: "",

//     ...data1,
//   });
//   const [errors, setErrors] = useState({});
//   const requestingpayout = async () => {
//     if (data.beneficiary_account_number !== data.beneficiary_account_number2) {
//       setErrors({
//         beneficiary_account_number: "Account number is not matching",
//       });
//       return;
//     }
//     setLoading(true);
//     let res = "";

//     res = await dispatch(editPayoutAccount(data));
//     console.log(res);
//     if (res == "success") {
//       enqueueSnackbar("Payout account updated successfully", {
//         variant: "success",
//       });
//       cancelling();
//       setLoading(false);
//     } else {
//       setLoading(false);
//       console.log(res);
//       setErrors(res);
//     }
//     console.log(res);
//     setLoading(false);
//   };
//   return (
//     <>
//       <Dialog
//         fullWidth
//         maxWidth="sm"
//         open={true}
//         onClose={() => cancelling()}
//         aria-labelledby="max-width-dialog-title"
//       >
//         <DialogTitle id="max-width-dialog-title">{"Edit Account"}</DialogTitle>
//         <DialogContent>
//           {/* <DialogContentText>{"Add Request"}</DialogContentText> */}
//           <TextField
//             autoFocus
//             margin="dense"
//             id="name"
//             label="Beneficiary Name"
//             value={data.beneficiary_name}
//             onChange={(e) =>
//               setData({ ...data, beneficiary_name: e.target.value })
//             }
//             type="text"
//             helperText={errors.beneficiary_name}
//             error={errors.beneficiary_name ? true : false}
//             fullWidth
//           />
//           <TextField
//             autoFocus
//             margin="dense"
//             multiline
//             rows={2}
//             error={errors.beneficiary_address ? true : false}
//             helperText={errors.beneficiary_address}
//             value={data.beneficiary_address}
//             onChange={(e) =>
//               setData({ ...data, beneficiary_address: e.target.value })
//             }
//             label="Beneficiary Address"
//             type="text"
//             fullWidth
//           />
//           <TextField
//             value={data.beneficiary_bank}
//             margin="dense"
//             error={errors.beneficiary_bank ? true : false}
//             helperText={errors.beneficiary_bank}
//             onChange={(e) =>
//               setData({ ...data, beneficiary_bank: e.target.value })
//             }
//             label="Bank Name"
//             type="text"
//             fullWidth
//           />
//           <TextField
//             value={data.beneficiary_account_number}
//             margin="dense"
//             onChange={(e) =>
//               setData({ ...data, beneficiary_account_number: e.target.value })
//             }
//             error={errors.beneficiary_account_number ? true : false}
//             helperText={errors.beneficiary_account_number}
//             id="name"
//             label="Account Number"
//             type="text"
//             fullWidth
//           />
//           <TextField
//             value={data.beneficiary_account_number2}
//             margin="dense"
//             onChange={(e) =>
//               setData({ ...data, beneficiary_account_number2: e.target.value })
//             }
//             id="name"
//             error={errors.beneficiary_account_number2 ? true : false}
//             helperText={errors.beneficiary_account_number2}
//             label="Re-Enter Account Number"
//             type="password"
//             fullWidth
//           />
//           {/* <Stack direction={"row"}>
//             <Checkbox
//               checked={data.is_active}
//               onChange={(e) =>
//                 setData({ ...data, is_active: e.target.checked })
//               }
//             />
//             <Typography variant="body2" sx={{ mt: 1 }} color="textSecondary">
//               {"Active"}
//             </Typography>
//           </Stack> */}
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => cancelling()}>Cancel</Button>
//           <LoadingButton
//             onClick={() => requestingpayout()}
//             loading={loading}
//             disabled={loading}
//           >
//             Submit
//           </LoadingButton>
//         </DialogActions>
//       </Dialog>
//     </>
//   );
// };
