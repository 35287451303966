import {
  format,
  getTime,
  formatDistanceToNow,
  formatDistanceStrict,
} from "date-fns";

// ----------------------------------------------------------------------

export function fDateDash(date) {
  return format(new Date(date), "yyyy-MM-dd");
}
export function fDate(date) {
  if (date) {
    return format(new Date(date), "dd MMMM yyyy");
  }
  return "";
}
export function fDatetoUTCString(date) {
  return new Date(date).toUTCString();
}

export function fDateTime(date) {
  return format(new Date(date), "dd MMM yyyy HH:mm");
}

// 2022-09-19T01:04:49.077873-06:00
export function fDateTimenoZone(date) {
  return format(new Date(date), "yyyy-MM-dd HH:mm:ss");
}

export function fTimestamp(date) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), "dd/MM/yyyy p");
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}
export function fDistanceBetween(start, end) {
  // console.log(start, end);
  return formatDistanceStrict(new Date(start), new Date(end), {
    addSuffix: false,
    unit: "day",
  });
}
