import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
// utils
// import axios from '../utils/axios';
import axios from 'axios';
import { eduleyApi } from 'src/api/apiConfig';
import { ADMIN, INSTITUTE_CONFIG, LOGIN } from 'src/apiUrl';
import { isValidToken, setSession } from '../utils/jwt';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  instituteinfo: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  SETINSTITUTE: (state, action) => {
    const { data } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      instituteinfo: data
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  }
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve()
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  // (new URL(document.location)).searchParams;
  let params = new URL(document.location).searchParams;
  let name = params.get('accessToken');
  // console.log(params, name);
  if (name) {
    localStorage.setItem('accessToken', name);
    window.location.href = '/';
  }
  const accessToken = window.localStorage.getItem('accessToken');
  // function useQuery() {
  // const { search } = useLocation();
  // return useMemo(() => new URLSearchParams(search), [search]);
  // }
  // const query = useQuery()
  // console.log('query', query);

  const getInstitute = async () => {
    try {
      let res = await eduleyApi.get(`${INSTITUTE_CONFIG}`);
      // console.log('institute', res.data)

      dispatch({
        type: 'SETINSTITUTE',
        payload: {
          data: res.data[0]
        }
      });
      console.log(res, 'akr');
      localStorage.setItem('currency', JSON.stringify(res.data[0]?.default_currency));
    } catch (error) {
      console.log('res', error);
    }
  };

  useEffect(() => {
    const initialize = async () => {
      try {
        if ((accessToken && isValidToken(accessToken)) || name) {
          setSession(accessToken || name);

          console.log('check headers---', accessToken, name);
          const config = {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken || name}`
            }
          };
          const response = await eduleyApi.get(ADMIN, config);

          // const response = await axios.get(ADMIN,config);
          console.log('response', response);
          const user = response.data[0];

          // ins
          await getInstitute();

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password) => {
    const response = await axios.post(LOGIN, {
      email,
      password
    });
    const { refresh, access } = response.data;

    localStorage.setItem('accessToken', access);
    // window.location.reload();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access}`
      }
    };
    const response2 = await axios.get(ADMIN, config);
    console.log('Login', response2);

    const user = response2.data[0];

    setSession(access);
    dispatch({
      type: 'LOGIN',
      payload: {
        user: user
      }
    });
    await getInstitute();
  };

  const register = async (email, password, firstName, lastName) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  const logout = async () => {
    setSession(null);
    localStorage.clear();
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
