import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { createContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { eduleyApi } from 'src/api/apiConfig';
import { COHORT, COURSE_FEE_BREAKUP, CREATE_COURSE, STUDENT_DISCOUNT } from 'src/apiUrl';
import { setCohortDetails } from 'src/redux/slices/course';
import { updateCourseData } from 'src/redux/slices/courseupdate';
// ----------------------------------------------------------------------

export const Loading = {
  discount: 'discount',
  createDiscount: 'create-discount',
  configure: 'configure',
  priceBreakup: 'price-breakup'
};

const FeeContext = createContext();

// ----------------------------------------------------------------------

FeeProvider.propTypes = {
  children: PropTypes.node
};

function FeeProvider({ children }) {
  const dispatch = useDispatch();
  const { addcourse } = useSelector((state) => state.courseupdate);
  const { cohortdetails } = useSelector((state) => state.course);
  const [feeData, setFeeData] = useState(null);
  const courseInfo = addcourse?.firstform;
  const [discountData, setDiscountData] = useState(null);
  const [discountValue, setDiscountValue] = useState('');
  const [discountUnit, setDiscountUnit] = useState('percentage');
  const [priceBreakup, setPriceBreakup] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [discountLoading, setDiscountLoading] = useState(false);
  const [hasDiscount, setHasDiscount] = useState(false);
  const [price, setPrice] = useState(courseInfo?.price);
  const [isCohort, setCohort] = useState(false);
  const { id = '' } = useParams();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname == '/cohort-management') {
      setFeeData(cohortdetails);
      setCohort(true);
    } else {
      setCohort(false);
      setFeeData(courseInfo);
    }
  }, [location, cohortdetails, courseInfo]);

  useEffect(() => {
    if (feeData) {
      setPrice(feeData?.price);
    }
  }, [feeData]);

  const onChangeDiscountValue = (discount) => {
    setDiscountValue(discount);
  };

  const onChangeDiscountUnit = (discountUnit) => {
    setDiscountUnit(discountUnit);
  };

  const onChangePrice = (event) => {
    setPrice(event?.target?.value?.replace(/[^0-9.]/g, '') || null);
  };

  //Get Discount
  async function getFeeDiscount(id) {
    return new Promise((resolve, reject) => {
      eduleyApi
        .get(`${STUDENT_DISCOUNT}`, {
          params: {
            course: !isCohort ? id : feeData?.course,
            cohort: isCohort ? id : undefined
          }
        })
        .then((response) => {
          if (!isEmpty(response.data) && Number(response?.data?.value) > 0) {
            setHasDiscount(true);
            setDiscountValue(response.data.value);
            setDiscountUnit(response.data?.discount_type || 'percentage');
            setDiscountData(response.data);
          } else {
            setHasDiscount(false);
            setDiscountValue('');
            setDiscountUnit('percentage');
            setDiscountData(response.data);
          }

          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  //Manage Discount
  async function onSubmitDiscount(removeDiscount = false, msg) {
    return new Promise(async (resolve, reject) => {
      const payload = {
        discount_type: discountUnit,
        value: removeDiscount ? 0 : discountValue,
        course: isCohort ? feeData?.course : feeData?.id,
        cohort: isCohort ? feeData?.id : undefined
        //  cohort: 0
      };
      console.log('Discount Type:', payload, discountData, hasDiscount);

      setDiscountLoading(Loading.createDiscount);
      if (!isEmpty(discountData)) {
        try {
          await onUpdateDiscount(payload, msg);
          await getCoursePriceBreakup(feeData?.id);
          resolve('msg');
        } catch (e) {
          reject('err');
        }
      } else {
        try {
          await onCreateDiscount(payload);
          await getCoursePriceBreakup(feeData?.id);

          resolve('msg');
        } catch (e) {
          reject('err');
        }
      }
    });
  }

  //Create Discount
  async function onCreateDiscount(payload) {
    return new Promise((resolve, reject) => {
      setDiscountLoading(Loading.createDiscount);
      eduleyApi
        .post(`${STUDENT_DISCOUNT}`, payload)
        .then(async (response) => {
          if (!isEmpty(response.data) && Number(response?.data?.value) > 0) {
            setHasDiscount(true);
            setDiscountValue(response.data.value);
            setDiscountUnit(response.data?.discount_type || 'percentage');
            setDiscountData(response.data);
          } else {
            setHasDiscount(null);
            setDiscountValue('');
            setDiscountUnit('percentage');
            setDiscountData(response.data);
          }

          await onFetchCourseData();

          enqueueSnackbar('Discount applied successfully!');

          resolve(response);
        })
        .catch((err) => {
          setDiscountLoading(false);
          reject(err);
        });
    });
  }
  //Update Discount
  async function onUpdateDiscount(payload, msg) {
    return new Promise((resolve, reject) => {
      setDiscountLoading(Loading.createDiscount);
      eduleyApi
        .patch(`${STUDENT_DISCOUNT}${discountData?.id}/`, payload)
        .then(async (response) => {
          if (!isEmpty(response.data) && Number(response?.data?.value) > 0) {
            setHasDiscount(true);
            setDiscountValue(response.data.value);
            setDiscountUnit(response.data?.discount_type || 'percentage');
            setDiscountData(response.data);
          } else {
            setHasDiscount(null);
            setDiscountValue('');
            setDiscountUnit('percentage');
            setDiscountData(response.data);
          }

          enqueueSnackbar(msg || 'Discount updated successfully!');
          await onFetchCourseData();
          resolve(response.data);
        })
        .catch((err) => {
          setDiscountValue(discountData?.value);
          setDiscountUnit(discountData?.discount_type);
          setDiscountLoading(false);
          reject(err);
        });
    });
  }

  //Get Course Breakup
  async function getCoursePriceBreakup(id) {
    return new Promise((resolve, reject) => {
      setDiscountLoading(Loading.discount);
      eduleyApi
        .get(`${COURSE_FEE_BREAKUP}`, {
          params: {
            course: !isCohort ? id : feeData?.course,
            cohort: isCohort ? id : undefined
          }
        })
        .then((response) => {
          setPriceBreakup(response.data);
          setDiscountLoading(false);
          resolve(response.data);
        })
        .catch((err) => {
          setDiscountLoading(false);
          reject(err);
        });
    });
  }

  async function onFetchCourseData() {
    return new Promise((resolve, reject) => {
      eduleyApi
        .get(`${isCohort ? COHORT : CREATE_COURSE}${feeData?.id}/`)
        .then((response) => {
          if (isCohort) {
            dispatch(setCohortDetails(response.data));
          } else {
            dispatch(updateCourseData(response.data));
          }
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  async function onUpdateData(payload) {
    return new Promise((resolve, reject) => {
      const newPayload = {
        ...feeData,
        ...payload
      };
      eduleyApi
        .patch(`${isCohort ? COHORT : CREATE_COURSE}${feeData?.id}/`, newPayload)
        .then((response) => {
          if (isCohort) {
            dispatch(setCohortDetails(response.data));
          } else {
            dispatch(updateCourseData(response.data));
          }
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);

          const hasNonFieldErrors = error?.data?.data?.non_field_errors;
          const hasDataErrors = error?.data?.data;

          if (hasNonFieldErrors && !isEmpty(hasNonFieldErrors)) {
            if (Array.isArray(hasNonFieldErrors)) {
              enqueueSnackbar(hasNonFieldErrors[0], { variant: 'error' });
            } else {
              enqueueSnackbar(hasNonFieldErrors, { variant: 'error' });
            }
          } else if (hasDataErrors && !isEmpty(hasDataErrors)) {
            if (Array.isArray(hasDataErrors)) {
              enqueueSnackbar(hasDataErrors[0], { variant: 'error' });
            }
          }
        });
    });
  }

  async function updateCoursePriceBreakup(payload) {
    return new Promise((resolve, reject) => {
      const newPayload = {
        course: isCohort ? feeData?.course : feeData?.id,
        price_breakup: payload,
        cohort: isCohort ? feeData?.id : undefined
      };
      eduleyApi
        .patch(`${COURSE_FEE_BREAKUP}`, newPayload, {
          params: {
            course: isCohort ? feeData?.course : feeData?.id,
            cohort: isCohort ? feeData?.id : undefined
          }
        })
        .then(async (response) => {
          await onFetchCourseData();
          setPriceBreakup(response.data);
          enqueueSnackbar('Fee Component updated successfully!');
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  console.log('Course Info:', feeData);

  return (
    <FeeContext.Provider
      value={{
        onChangeDiscountValue,
        onChangeDiscountUnit,
        getFeeDiscount,
        onCreateDiscount,
        onChangePrice,
        getCoursePriceBreakup,
        onUpdateData,
        setHasDiscount,
        onSubmitDiscount,
        updateCoursePriceBreakup,
        onFetchCourseData,
        price,
        priceBreakup,
        discountUnit,
        discountValue,
        discountLoading,
        hasDiscount,
        feeData,
        discountData,
        isCohort
      }}
    >
      {children}
    </FeeContext.Provider>
  );
}

export { FeeContext, FeeProvider };
