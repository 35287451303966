// @mui
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";
import { alpha } from "@material-ui/core";
import React from "react";

// ----------------------------------------------------------------------

export default function UploadIllustration({ ...other }) {
  const theme = useTheme();
  const PRIMARY_MAIN = alpha(theme.palette.primary.main, 0.4);
  const PRIMARY_DARK = theme.palette.primary.dark;
  const PRIMARY_DARKER = theme.palette.primary.darker;

  return (
    <Box {...other}>
      <svg xmlns='http://www.w3.org/2000/svg' x='0' y='0' enableBackground='new 0 0 390 400' version='1.1' viewBox='0 0 390 400' xmlSpace='preserve'>
        <g fill='none' stroke={PRIMARY_MAIN} strokeMiterlimit='10' strokeWidth='0.37'>
          <path d='M206.94 459.41s37.93-118.5-55.49-107.4c-93.42 11.11-32.01-82.54-122.17-106.93-97.77-26.45-134.61 56.34-134.61 56.34'></path>
          <path d='M205.74 459.23s37.77-117.32-55.05-106.47c-8.28.97-15.35 1.13-21.42.58-61.62-5.53-19.33-84.01-100.24-106.02-96.25-26.16-132.8 54.95-133.6 55.62'></path>
          <path d='M204.53 459.05s37.62-116.14-54.61-105.54c-8.19.95-15.2 1.1-21.24.58-61.2-5.36-20.17-82.71-99.9-104.52-94.74-25.86-130.99 53.57-132.59 54.9'></path>
          <path d='M203.33 458.87s37.46-114.96-54.16-104.61c-8.1.93-15.05 1.08-21.06.57-60.79-5.19-21.01-81.4-99.56-103.02-93.22-25.57-129.18 52.18-131.57 54.18'></path>
          <path d='M202.13 458.69s37.3-113.78-53.72-103.68c-8.01.91-14.9 1.06-20.88.57-60.37-5.02-21.85-80.1-99.22-101.53-91.71-25.27-127.37 50.79-130.56 53.46'></path>
          <path d='M200.92 458.51s37.14-112.6-53.28-102.75c-7.92.89-14.76 1.04-20.71.56-59.96-4.85-22.7-78.8-98.88-100.03-90.2-24.98-125.56 49.4-129.55 52.74'></path>
          <path d='M199.72 458.33s36.99-111.42-52.84-101.82c-7.83.87-14.61 1.01-20.53.56-59.54-4.68-23.54-77.5-98.54-98.53-88.68-24.69-123.75 48.02-128.53 52.02'></path>
          <path d='M198.51 458.15s36.81-110.43-52.39-100.9c-7.75.83-14.46.99-20.35.55-59.12-4.5-24.38-76.19-98.2-97.03-87.17-24.39-121.94 46.63-127.52 51.3'></path>
          <path d='M197.31 457.97S233.96 348.7 145.36 358c-7.66.8-14.31.97-20.17.55-58.71-4.33-25.22-74.89-97.87-95.53-85.65-24.1-120.13 45.24-126.51 50.58'></path>
          <path d='M196.11 457.8s36.49-108.11-51.51-99.04c-7.57.78-14.17.95-19.99.54-58.29-4.16-26.06-73.59-97.53-94.04-84.14-23.8-118.32 43.85-125.5 49.86'></path>
          <path d='M194.9 457.62s36.33-106.96-51.07-98.11c-7.48.76-14.02.92-19.81.54-57.87-3.99-26.9-72.29-97.19-92.54-82.62-23.51-116.5 42.46-124.48 49.14'></path>
          <path d='M193.7 457.44s36.17-105.8-50.62-97.18c-7.39.73-13.87.9-19.63.53-57.46-3.82-27.74-70.98-96.85-91.04-81.11-23.22-114.7 41.08-123.47 48.42'></path>
          <path d='M192.49 457.26s36.01-104.64-50.18-96.25c-7.3.71-13.72.88-19.45.53-57.04-3.65-28.54-69.82-96.51-89.54-79.55-23.08-112.89 39.69-122.46 47.7'></path>
          <path d='M191.29 457.08s35.85-103.48-49.74-95.32c-7.21.69-13.58.86-19.28.52-56.63-3.48-29.38-68.53-96.17-88.04-78.03-22.8-111.08 38.3-121.44 46.98'></path>
          <path d='M190.08 456.9s35.7-102.32-49.3-94.39c-7.12.66-13.43.83-19.1.52-56.21-3.3-30.22-67.24-95.84-86.55-76.51-22.52-109.27 36.92-120.43 46.26'></path>
          <path d='M188.88 456.72s35.54-101.16-48.85-93.47c-7.03.64-13.28.81-18.92.51-55.79-3.13-31.06-65.94-95.5-85.05-75-22.24-107.45 35.53-119.42 45.54'></path>
          <path d='M187.68 456.54s35.38-100-48.41-92.54c-6.94.62-13.13.79-18.74.51-55.38-2.96-31.9-64.65-95.16-83.55-73.48-21.95-105.64 34.14-118.4 44.82'></path>
          <path d='M186.47 456.36s35.22-98.83-47.97-91.61c-6.85.6-12.98.77-18.56.5-54.96-2.79-32.73-63.36-94.82-82.05-71.96-21.67-103.83 32.75-117.39 44.1'></path>
          <path d='M185.27 456.18s35.06-97.67-47.53-90.68c-6.76.57-12.84.74-18.38.5-54.55-2.62-33.57-62.06-94.48-80.56-70.44-21.39-102.02 31.37-116.38 43.38'></path>
          <path d='M184.06 456s34.91-96.51-47.08-89.75c-6.67.55-12.69.73-18.2.49-54.13-2.34-34.41-60.77-94.14-79.06-68.93-21.1-100.21 29.98-115.37 42.66'></path>
          <path d='M182.86 455.82s34.75-95.34-46.64-88.82c-6.58.53-12.54.71-18.02.49-53.72-2.16-35.25-59.48-93.81-77.56-67.41-20.82-98.4 28.59-114.35 41.94'></path>
          <path d='M181.66 455.64s34.59-94.17-46.2-87.89c-6.49.5-12.39.69-17.85.48-53.3-1.99-36.08-58.18-93.47-76.06-65.89-20.53-96.59 27.2-113.34 41.22'></path>
          <path d='M180.45 455.46s34.44-93-45.76-86.97c-6.41.48-12.25.66-17.67.48-52.89-1.82-36.92-56.89-93.13-74.56-64.37-20.25-94.78 25.82-112.33 40.5'></path>
          <path d='M179.25 455.28s34.28-91.84-45.31-86.04c-6.32.46-12.1.64-17.49.47-52.47-1.64-37.76-55.59-92.79-73.07-62.86-19.96-92.97 24.43-111.31 39.78'></path>
          <path d='M178.04 455.11s34.12-90.67-44.87-85.11c-6.23.44-11.95.62-17.31.47-52.05-1.47-38.6-54.3-92.45-71.57-61.34-19.67-91.16 23.04-110.3 39.06'></path>
          <path d='M176.84 454.93s33.96-89.49-44.43-84.18c-6.14.42-11.8.6-17.13.46-51.64-1.3-39.44-53-92.11-70.07-59.82-19.39-89.35 21.65-109.29 38.34'></path>
          <path d='M175.64 454.75s33.81-88.32-43.99-83.25c-6.05.39-11.65.57-16.95.46-51.22-1.13-40.28-51.7-91.77-68.57-58.3-19.1-87.54 20.27-108.27 37.62'></path>
          <path d='M174.43 454.57s33.65-87.15-43.54-82.32c-5.96.37-11.51.55-16.77.45-50.8-.95-41.11-50.41-91.44-67.07-56.79-18.81-85.73 18.88-107.26 36.9'></path>
          <path d='M173.23 454.39s33.5-85.97-43.1-81.39c-5.87.35-11.36.53-16.59.45-50.39-.78-41.95-49.11-91.1-65.58-55.27-18.52-83.92 17.49-106.25 36.18'></path>
          <path d='M172.02 454.21s33.34-84.79-42.66-80.47c-5.78.33-11.21.51-16.41.44-49.97-.61-42.79-47.81-90.76-64.08-53.75-18.23-82.11 16.1-105.24 35.46'></path>
          <path d='M170.82 454.03s33.18-83.62-42.22-79.54c-5.69.31-11.06.48-16.24.44-49.55-.44-43.63-46.51-90.42-62.58-52.24-17.94-80.3 14.72-104.22 34.74'></path>
          <path d='M169.61 453.85s33.03-82.44-41.77-78.61c-5.6.29-10.92.46-16.06.43-49.14-.27-44.47-45.21-90.08-61.08-50.72-17.65-78.49 13.33-103.21 34.02'></path>
          <path d='M168.41 453.67s32.87-81.25-41.33-77.68c-5.51.27-10.77.44-15.88.43-48.72-.1-45.31-43.91-89.74-59.59-49.21-17.35-76.68 11.94-102.2 33.3'></path>
          <path d='M167.21 453.49s32.72-80.07-40.89-76.75c-5.42.24-10.62.42-15.7.42-48.3.07-46.15-42.61-89.41-58.09-47.69-17.06-74.86 10.55-101.18 32.58'></path>
          <path d='M166 453.31s32.56-78.88-40.45-75.82c-5.33.22-10.47.39-15.52.42-47.89.24-46.99-41.31-89.07-56.59-46.17-16.77-73.05 9.17-100.17 31.86'></path>
          <path d='M164.8 453.13s32.41-77.7-40-74.89c-5.24.2-10.33.37-15.34.41-47.47.41-47.83-40.01-88.73-55.09-44.66-16.47-71.24 7.78-99.16 31.14'></path>
          <path d='M163.59 452.95s32.25-76.51-39.56-73.97c-5.15.18-10.18.35-15.16.41-47.05.57-48.67-38.7-88.39-53.59-43.14-16.17-69.43 6.39-98.15 30.42'></path>
          <path d='M162.39 452.77s32.1-75.31-39.12-73.04c-5.06.16-10.03.32-14.98.4-46.64.74-49.51-37.4-88.05-52.1-41.63-15.87-67.62 5-97.13 29.7'></path>
          <path d='M161.19 452.59s31.94-74.12-38.68-72.11c-4.97.14-9.88.3-14.81.4-46.22.91-50.35-36.09-87.71-50.6-40.12-15.57-65.81 3.61-96.12 28.98'></path>
          <path d='M159.98 452.42s31.79-72.92-38.23-71.18c-4.88.12-9.73.28-14.63.39-45.8 1.07-51.19-34.78-87.38-49.1-38.6-15.27-64 2.23-95.11 28.26'></path>
          <path d='M158.78 452.24s31.63-71.72-37.79-70.25c-4.79.1-9.59.25-14.45.39-45.39 1.15-52.03-33.47-87.04-47.6-37.09-14.97-62.19.84-94.09 27.54'></path>
          <path d='M157.57 452.06s31.47-70.52-37.35-69.32c-4.7.08-9.44.22-14.27.38-44.97 1.32-52.87-32.16-86.7-46.1-35.58-14.66-60.38-.55-93.08 26.82'></path>
          <path d='M156.37 451.88s31.32-69.31-36.91-68.39c-4.62.06-9.29.2-14.09.38-44.56 1.49-53.72-30.85-86.36-44.61-34.07-14.36-58.57-1.94-92.07 26.1'></path>
          <path d='M155.17 451.7s31.16-68.1-36.46-67.46c-4.53.04-9.14.18-13.91.37-44.14 1.66-54.56-29.53-86.02-43.11-32.56-14.05-56.76-3.32-91.05 25.38'></path>
          <path d='M153.96 451.52s31.01-66.89-36.02-66.54c-4.44.02-9 .16-13.73.37-43.73 1.84-55.41-28.22-85.68-41.61-31.05-13.74-54.95-4.71-90.04 24.66'></path>
          <path d='M152.76 451.34s30.85-65.68-35.58-65.61c-4.35 0-8.85.13-13.55.36-43.31 2.01-56.25-26.9-85.35-40.11-29.54-13.42-53.14-6.1-89.03 23.94'></path>
          <path d='M151.55 451.16s30.7-64.46-35.14-64.68c-4.26-.01-8.7.11-13.38.36-42.89 2.18-57.1-25.57-85.01-38.62-28.04-13.1-51.33-7.49-88.02 23.22'></path>
          <path d='M150.35 450.98s30.54-63.23-34.69-63.75c-4.17-.03-8.55.09-13.2.35-42.48 2.35-57.95-24.24-84.67-37.12-26.53-12.78-49.52-8.87-87 22.5'></path>
          <path d='M149.14 450.8s30.38-62.01-34.25-62.82c-4.08-.05-8.4.06-13.02.35-42.06 2.52-58.81-22.91-84.33-35.62-25.03-12.46-47.71-10.26-85.99 21.78'></path>
          <path d='M147.94 450.62s30.22-60.66-33.81-61.89c-3.99-.06-8.26.04-12.84.34-41.64 2.69-59.66-21.58-83.99-34.12-23.53-12.13-45.9-11.65-84.98 21.06'></path>
          <path d='M146.74 450.44s30.06-59.48-33.37-60.96c-3.9-.08-8.11.02-12.66.34-41.23 2.86-60.55-20.19-83.65-32.62-22.06-11.77-44.09-13.04-83.97 20.33'></path>
          <path d='M145.53 450.26s33.64-65.58-45.4-59.7c-79.05 5.88-82.17-81.88-166.26-11.51'></path>
        </g>
      </svg>
    </Box>
  );
}
