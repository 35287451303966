import { Typography, styled } from '@mui/material';

export const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.caption,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1)
}));

export const FILE_EXTENSIONS = ['pdf', 'docx', 'zip', 'xls', 'ppt', 'text'];
export const REQUIREMENT_LEVEL = [{ name: 'course', icon: 'https://img.icons8.com/nolan/64/knowledge-sharing.png' }, { name: 'institute', icon: 'https://img.icons8.com/external-wanicon-flat-wanicon/64/external-school-online-learning-wanicon-flat-wanicon.png' }];
export const REQUIREMENT_TYPE = [{ name: 'mandatory', icon: 'https://img.icons8.com/nolan/64/submit-resume.png' }, { name: 'optional', icon: 'https://img.icons8.com/pulsar-line/48/submit-resume.png' }];


/* Permission Person */

export const PERMISSIONS_LEVEL = [{ name: 'Admin', value: 'admin', icon: 'https://img.icons8.com/fluency/48/manager.png' },
{ name: 'Counselor', value: 'counselor', icon: 'https://img.icons8.com/external-flaticons-lineal-color-flat-icons/64/external-counselor-psychology-flaticons-lineal-color-flat-icons-5.png' },
{ name: 'Accountant', value: 'accountant', icon: 'https://img.icons8.com/external-itim2101-lineal-color-itim2101/64/external-accountant-life-style-avatar-itim2101-lineal-color-itim2101-1.png' },
{ name: 'Instructor', value: 'instructor', icon: 'https://img.icons8.com/bubbles/50/training.png', }, {
  name: 'Reviewer', value: 'reviewer', icon: 'https://img.icons8.com/external-wanicon-flat-wanicon/64/external-teacher-online-learning-wanicon-flat-wanicon.png'
}];

export const MAX_FILE_SIZE = [2, 5, 10, 50];

export const DOCUMENT_ICON_URL = 'https://img.icons8.com/color/96/google-docs--v1.png';

export const acceptedFileTypes = [
  '.pdf', // PDF files
  '.doc,.docx',
  '.xls,.xlsx,.csv',
  'image/*',
  '.ppt,.pptx'
  // Word files (docx)
  // PowerPoint files (pptx)
];

export function getAlternativeFileTypes(fileType) {
  const updatedFileTypes = fileType?.map((type) => {
    if (type == 'ppt') return '.ppt,.pptx';
    else if (type == 'doc') return '.doc,.docx';
    else if (type == 'xls') return '.xls,.xlsx,.csv';
    else return `.${type}`;
  });
  // console.log('updatedTYpes:', updatedFileTypes?.join(','));
  return updatedFileTypes?.join(',');
}
