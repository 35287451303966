import { useState, useCallback, useEffect } from "react";
// @mui
import { alpha, styled } from "@mui/material/styles";

import Iconify from "src/components/Iconify";
import {
  Card,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  Button,
  Stack,
  Checkbox,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import { blue, pink } from "@mui/material/colors";
import { BankCardfill, InteracCard, PaypalCard } from "./AccountCards";
import { useDispatch, useSelector } from "src/redux/store";
import { getpayoutaccountype } from "src/redux/slices/fund_management";
import Image from "src/components/Image";

// ----------------------------------------------------------------------

const DropZoneStyle = styled("div")(({ theme }) => ({
  width: 104,
  height: 104,
  fontSize: 24,
  display: "flex",
  cursor: "pointer",
  alignItems: "center",
  justifyContent: "center",
  margin: theme.spacing(0.5),
  borderRadius: "50%",
  border: `dashed 4px ${theme.palette.primary.main}`,
  "&:hover": { opacity: 1.2 },
}));

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function UploadFile({ data, updateCard, done }) {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch()
  const {
    payout: { accountType },
  } = useSelector((state) => state.fund_management);
  useEffect(() => {
    dispatch(getpayoutaccountype())
  }, [])

  return (
    <>
      <Card
        sx={{
          // borderColor: (theme) => theme.palette.primary.main,
          borderColor: (theme) => theme.palette["primary"].lighter,
          color: (theme) => theme.palette["primary"].darker,
          display: "flex",
          cursor: "pointer",
          alignItems: "center",
          justifyContent: "center",
          width: 110,
          height: 110,
          "& .MuiCardHeader-action": {
            alignSelf: "center",
          },
        }}
        onClick={() => setOpen(true)}
      >
        <DropZoneStyle
          sx={{
            opacity: 0.72,
          }}
        >
          <Iconify
            icon={"eva:plus-fill"}
            sx={{ color: "text.secondary" }}
            width={32}
            height={32}
          />
        </DropZoneStyle>
      </Card>
      {open && (
        <ChooseNewCard
          cancelling={() => {
            updateCard();
            setOpen(false);
            done()
          }}
          type={accountType}
        />

      )}
    </>
  );
}


const ChooseNewCard = ({ cancelling, type }) => {
  console.log('dd', type)
  const [selectedAccount, setSelectedAccount] = useState(0)

  return (<>
    {selectedAccount.key == 'bank' && <BankCardfill
      id={selectedAccount.id}
      cancelling={() => {
        cancelling()
        // updateCard();
        // setOpen(false);
      }}
    />}
    {selectedAccount.key == 'paypal' && <PaypalCard
      id={selectedAccount.id}

      cancelling={() => {
        cancelling()
        // updateCard();
        // setOpen(false);
      }}
    />}
    {selectedAccount.key == 'interac' && <InteracCard
      id={selectedAccount.id}

      cancelling={() => {
        cancelling()
        // updateCard();
        // setOpen(false);
      }}
    />}
    <Dialog
      fullWidth
      maxWidth="xs"
      open={selectedAccount == 0}
      onClose={() => cancelling()}
      aria-labelledby="max-width-dialog-title"
    >

      <DialogTitle id="max-width-dialog-title">
        {"Please select the type of account you wish to add"}
      </DialogTitle>
      <DialogContent>

        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
          {type?.map((option, index) => <ListItem key={option.key} onClick={() => setSelectedAccount(option)} alignItems="flex-start">
            <ListItemButton>
              <ListItemAvatar>
                <Image
                  key={'attachments'}
                  src={option.logo}
                  // onClick={() => handleOpenLightbox(attachments)}
                  sx={{
                    m: 0.5,
                    width: 64,
                    height: 64,
                    borderRadius: 1,
                    cursor: "pointer",
                  }}
                />
                {/* <Avatar variant="square" sx={{  width: 75, height: 55, borderRadius: '10%' }} alt="Interac" src={option.logo} /> */}
              </ListItemAvatar>
              <ListItemText
                primary={option.name}
              />
            </ListItemButton>
          </ListItem>)}

        </List>
      </DialogContent>

    </Dialog>
  </>
  )
}

