import {
  Box,
  CircularProgress,
  IconButton,
  circularProgressClasses,
  useTheme,
} from "@mui/material";
import React from "react";
import Iconify from "./Iconify";

const LoadingIcon = ({ loading, ...props }) => {
  // const theme=useTheme()
  return (
    <>
      {loading ? (
        <>
          <Box sx={{ position: "relative" }}>
            {" "}
            <CircularProgress
              variant="determinate"
              sx={{
                color: (theme) =>
                  theme.palette.grey[
                    theme.palette.mode === "light" ? 200 : 800
                  ],
              }}
              size={35}
              thickness={5}
              {...props}
              value={100}
            />
            <CircularProgress
              variant="indeterminate"
              disableShrink
              sx={{
                color: (theme) =>
                  theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
                animationDuration: "550ms",
                position: "absolute",
                left: 0,
                [`& .${circularProgressClasses.circle}`]: {
                  strokeLinecap: "round",
                },
              }}
              size={35}
              thickness={5}
              {...props}
            />
          </Box>
          {/* <IconButton aria-label="settings" color="primary">
            {loading && <Iconify icon="eos-icons:bubble-loading" />}
          </IconButton> */}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default LoadingIcon;
