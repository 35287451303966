// import PropTypes from "prop-types";
// import Slider from "react-slick";
import { useEffect, useState } from 'react';
// @mui
import {
  Button,
  Card,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

// utils
import { fDateTime } from '../../../../utils/formatTime';
// _mock_
// import { _bookingReview } from "../../../../_mock";

// components
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import Iconify from 'src/components/Iconify';
import { getPayoutAccountforrequest, setFundPayout } from 'src/redux/slices/fund_management';
import { useDispatch, useSelector } from 'src/redux/store';
import { fAmount } from 'src/utils/formatNumber';
// import { CarouselArrows } from "../../../../components/carousel";

// ----------------------------------------------------------------------

export default function BookingCustomerReviews({ donepayment, amount = {} }) {
  const [addrequest, setAddrequest] = useState(false);

  return (
    <>
      <Card>
        {amount?.data?.data <= 0 ? (
          <>
            <RootStylee>
              <Typography flexWrap={'inherit'} variant="subtitle2" sx={{ opacity: 0.72 }}>
                Payouts is available only for institute who have opted for payment via Budgetnista
              </Typography>
            </RootStylee>
          </>
        ) : (
          <>
            <CardHeader
              title="Withdrawable Balance"
              subheader={`${fDateTime(new Date())}`}
              sx={{
                '& .MuiCardHeader-action': {
                  alignSelf: 'center'
                }
              }}
            />
            <ReviewItem requesting={() => setAddrequest(true)} data={amount} />
          </>
        )}{' '}
      </Card>
      {addrequest && (
        <AddrequestModal
          cancelling={() => setAddrequest(false)}
          donepayment={() => {
            setAddrequest(false);
            donepayment();
          }}
        />
      )}
    </>
  );
}
//

const AddrequestModal = ({ cancelling, donepayment }) => {
  const {
    payout: { transactions, accountreq: account }
  } = useSelector((state) => state.fund_management);

  const { enqueueSnackbar } = useSnackbar();

  // console.log("transactions", account);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ amount: '', account: '' });
  const [error, setError] = useState({ amount: '', account: '' });

  useEffect(() => {
    // dispatch(getFundPayoutlist(query));
    dispatch(getPayoutAccountforrequest('?offset=0&is_active=true'));
    // dispatch(getFundPayoutAmount());
  }, [dispatch]);

  const requestingpayout = async () => {
    setLoading(true);
    let res = await dispatch(setFundPayout(data));
    if (res === 'success') {
      setLoading(false);
      enqueueSnackbar('Payout requested successfully', {
        variant: 'success'
      });
      donepayment();
    } else {
      setLoading(false);
      if (res[0]) {
        enqueueSnackbar(res[0], { variant: 'error' });
      } else {
        setError(res);
      }
      console.log('res', res);
    }
    console.log(res);
    setLoading(false);
  };
  return (
    <>
      <Dialog fullWidth maxWidth="xs" open={true} onClose={() => cancelling()} aria-labelledby="max-width-dialog-title">
        <DialogTitle id="max-width-dialog-title">{'Request For Payout'}</DialogTitle>
        <DialogContent>
          <Typography variant="caption">Enter Amount</Typography>
          <TextField
            autoFocus
            margin="dense"
            type="number"
            value={data.amount}
            onChange={(e) => setData({ ...data, amount: e.target.value })}
            fullWidth
            error={error.amount}
            helperText={error.amount}
          />
          <Typography variant="caption">Select Account</Typography>
          <Select error={error.account} fullWidth value={data.account} onChange={(e) => setData({ ...data, account: e.target.value })}>
            <MenuItem value="" disabled>
              Select Account
            </MenuItem>
            {account?.results?.map((item, ifg) => (
              <MenuItem value={item.id} key={ifg + 'jk'}>
                {item.beneficiary_account_number ? (
                  <>
                    {item.beneficiary_account_number + ' '} / {' ' + item.beneficiary_name}
                  </>
                ) : item.paypal ? (
                  <>
                    {item.paypal + ' '}
                    {' ' + '(PayPal Account)'}
                  </>
                ) : (
                  <>
                    {item.interac + ' '}
                    {' ' + '(Interac Account)'}
                  </>
                )}
              </MenuItem>
            ))}
          </Select>
          {error.account && <FormHelperText>{error.account}</FormHelperText>}
        </DialogContent>
        <DialogActions>
          <LoadingButton onClick={() => requestingpayout()} loading={loading} disabled={loading} variant="contained">
            Submit
          </LoadingButton>
          <Button variant="outlined" onClick={() => cancelling()}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

// ----------------------------------------------------------------------

function ReviewItem({ requesting, data = {} }) {
  return (
    <>
      <Stack spacing={2} sx={{ minHeight: 202, position: 'relative', p: 3 }}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <AnalyticsCurrentVisits data2={data} />
        </Stack>
        <Stack direction="row" spacing={2} alignItems="flex-end" sx={{ flexGrow: 1 }}>
          <Button
            fullWidth
            onClick={() => requesting()}
            variant="contained"
            // disabled={ }
            //   endIcon={<Iconify icon={"eva:checkmark-circle-2-fill"} />}
          >
            Request Payout
          </Button>
        </Stack>
      </Stack>
    </>
  );
}
const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(4.5, 5)
}));
const RootStylee = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  padding: 25,
  width: 250,
  height: 480,
  padding: theme.spacing(0, 0)
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(16),
  height: theme.spacing(16),
  justifyContent: 'center',
  marginBottom: theme.spacing(3)
}));

// ----------------------------------------------------------------------

function AnalyticsCurrentVisits({ icon = 'emojione-monotone:money-bag', color = 'primary', data2 = {} }) {
  const { type = '', data = {} } = data2;
  const { data: as = '', heading = '' } = data;
  let amount = parseFloat(as).toFixed(2);
  console.log('data', data);
  return (
    <RootStyle
      sx={{
        color: (theme) => theme.palette[color].darker,
        bgcolor: (theme) => theme.palette[color].lighter
      }}
    >
      <IconWrapperStyle
        sx={{
          color: (theme) => theme.palette[color].dark,
          backgroundImage: (theme) =>
            `linear-gradient(135deg, ${alpha(theme.palette[color].dark, 0)} 0%, ${alpha(theme.palette[color].dark, 0.24)} 100%)`
        }}
      >
        <Iconify icon={icon} width={54} height={54} />
      </IconWrapperStyle>
      <Typography variant="h3">{fAmount(amount)}</Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        {heading}
      </Typography>
    </RootStyle>
  );
}
