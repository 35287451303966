import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TableCell, TableRow, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// utils
import { fAmount } from '../../../../utils/formatNumber';
import { fDate } from '../../../../utils/formatTime';
// components
import { LoadingButton } from '@mui/lab';
import { eduleyApi } from 'src/api/apiConfig';
import { BUDGETNISTA_PAYOUT } from 'src/apiUrl';
import Label from '../../../../components/Label';

// ----------------------------------------------------------------------

CommissionTableRow.propTypes = {
  row: PropTypes.object.isRequired
};

export default function CommissionTableRow({ row, recalllist }) {
  const theme = useTheme();

  const { created_date, status, amount, currency, remarks } = row;
  const [conform, setConform] = useState(false);
  const [openMenu, setOpenMenuActions] = useState(null);

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  return (
    <>
      <TableRow hover selected={false}>
        <TableCell align="left">{fDate(created_date)}</TableCell>

        <TableCell align="center">{fAmount(amount)}</TableCell>

        <TableCell align="left" sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Label
            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
            color={
              (status === 'success' && 'success') ||
              (status === 'failed' && 'error') ||
              (status === 'requested' && 'warning') ||
              (status === 'in process' && 'info') ||
              'success'
            }
            sx={{
              textTransform: 'capitalize',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            {status}
          </Label>
          {/* {status == "requested" && (
            <Iconify
              sx={{ color: "primary.main", cursor: "pointer" }}
              icon="eva:edit-2-outline"
              onClick={() => setConform(true)}
            />
          )} */}
        </TableCell>
        <TableCell align="left">{remarks}</TableCell>
        <TableCell align="left">
          {status == 'requested' && (
            <Button
              sx={{
                color: 'primary.main',
                cursor: 'pointer',
                textTransform: 'none'
              }}
              icon="eva:edit-2-outline"
              onClick={() => setConform(true)}
            >
              Mark as Paid
            </Button>
          )}
        </TableCell>
      </TableRow>
      {conform && (
        <RequestPayment
          onsuccess={() => {
            setConform(false);
            recalllist();
          }}
          data={row}
          handleclose={() => setConform(false)}
        />
      )}
    </>
  );
}

const RequestPayment = ({ onsuccess, handleclose, data }) => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState({
    ...data,
    remarks: '',
    status: 'in process'
  });
  const handleSubmit = async () => {
    setLoading(true);
    try {
      let res = await eduleyApi.patch(`${BUDGETNISTA_PAYOUT}${data.id}/`, value);
      console.log(res);
      onsuccess();
    } catch (error) {
      console.log(error.response);
    }
    setLoading(false);
  };
  return (
    <>
      <Dialog open={true} onClose={() => handleclose()} maxWidth="xs" height="auto" fullWidth>
        <DialogTitle>Make Requested Payment</DialogTitle>
        <DialogContent>
          <Stack direction={'row'} sx={{ mt: 3, mb: 3 }}>
            <Typography variant="body" sx={{ mr: 5 }}>
              Amount Requested
            </Typography>
            <Typography variant="body">{fAmount(data.amount)}</Typography>
          </Stack>

          <Typography variant="caption">Enter Remarks</Typography>

          <TextField fullWidth value={value.remarks} onChange={(e) => setValue({ ...value, remarks: e.target.value })} />
        </DialogContent>
        <DialogActions>
          <LoadingButton onClick={handleSubmit} loading={loading} disabled={loading} variant="contained" sx={{ textTransform: 'none' }}>
            Mark as Paid
          </LoadingButton>
          <Button variant="outlined" onClick={() => handleclose()}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
