import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  alpha,
  useTheme
} from '@mui/material';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { eduleyApi } from 'src/api/apiConfig';
import { PAYMENT_PLAN } from 'src/apiUrl';
import Avatar from 'src/components/Avatar';
import Iconify from 'src/components/Iconify';
import Label from 'src/components/Label';
import { IconButtonAnimate } from 'src/components/animate';
import { TableLoader } from 'src/components/table';
import RoleBasedGuard from 'src/guards/RoleBasedGuard';
import { courseUpdating } from 'src/redux/slices/courseupdate';
import { useDispatch, useSelector } from 'src/redux/store';
import { fAmount } from 'src/utils/formatNumber';
import ChoosePaymentType from './ChoosePaymentType';
import PaymentPlanView from './ConfigurationView/PaymentPlanView';

const PaymentPlan = ({ accessibleRoles }) => {
  const theme = useTheme();
  const [openDetails, setopenDetails] = useState(false);
  const { addcourse, loadingtoform } = useSelector((state) => state.courseupdate);
  const courseInfo = addcourse.firstform;
  const handleChange = () => {
    setopenDetails(!openDetails);
  };
  return (
    <>
      <Stack
        direction={'row'}
        spacing={2}
        sx={{
          width: 1,
          position: 'relative',
          border: (theme) => `1px solid ${theme.palette.divider}`,
          my: 2,
          borderRadius: 1,
          backgroundColor: '#fff',
          cursor: 'pointer'
        }}
        onClick={handleChange}
      >
        <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 2 }}>
          <Avatar
            sx={{
              backgroundColor: alpha(theme.palette.primary.light, 0.1),
              color: 'primary.main'
            }}
          >
            <Iconify icon={'streamline:money-cash-bill-2-currency-billing-payment-finance-cash-bill-money-accounting'} />
          </Avatar>

          <Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2} sx={{ pb: 1, flexGrow: 1 }}>
              <Typography variant="h6">Payment Options</Typography>
              <Stack direction="row" alignItems="center" spacing={2}>
                {courseInfo?.payment_plans && (
                  <Label
                    variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                    color={(courseInfo?.payment_plans?.includes('full_payment') && 'success') || 'default'}
                    sx={{
                      letterSpacing: 1,
                      fontSize: 12
                    }}
                  >
                    {(courseInfo?.payment_plans?.includes('full_payment') && 'Full Payment Enabled') || 'Full Payment Disabled'}
                  </Label>
                )}

                <Label
                  variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                  color={courseInfo?.payment_plans?.includes('payment_plan') ? 'success' : 'default'}
                  sx={{
                    letterSpacing: 1,
                    fontSize: 12
                  }}
                >
                  {courseInfo?.payment_plans?.includes('payment_plan') ? ' Payment Plan Enabled' : 'Payment Plan Disabled'}
                </Label>
              </Stack>
            </Stack>
            <Typography variant="caption" color="text.secondary">
              Configure the options the student will see when making the program fee payment. By default the “Full Payment” option is selected for
              program.
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      {openDetails && <PaymentPlanDetails handleCloseDrawer={handleChange} accessibleRoles={accessibleRoles} />}
    </>
  );
};

const PaymentPlanDetails = ({ handleCloseDrawer, accessibleRoles }) => {
  const theme = useTheme();
  const [paymentInfo, setPaymentInfo] = useState(null);
  const [payInstallment, setPayInstallment] = useState(null);
  const [loading1, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { addcourse, loadingtoform } = useSelector((state) => state.courseupdate);
  const courseInfo = addcourse.firstform;
  const loading = useMemo(() => loadingtoform || loading1, [loadingtoform, loading1]);
  // const [value, setValue] = useState({ fullpayment: true, installment: false });
  const dispatch = useDispatch();
  const value = useMemo(
    () => ({
      fullpayment: addcourse?.firstform?.payment_plans?.includes('full_payment'),
      installment: addcourse?.firstform?.payment_plans?.includes('payment_plan')
    }),
    [addcourse.firstform.payment_plans]
  );
  useEffect(() => {
    if (addcourse.firstform.id) {
      getPaymentPlanInfo();
      // handlePaymentPlanInfo();
    }
  }, [addcourse.firstform.id]);

  const getPaymentPlanInfo = async () => {
    try {
      let res = await eduleyApi.get(`${PAYMENT_PLAN}?course=${addcourse.firstform.id}`);
      console.log('res', res);
      setPaymentInfo(res.data);
      setPayInstallment(res.data[0].installments);
    } catch (error) {}
  };

  const handleChange = async (e) => {
    // setValue({ ...value, [e.target.name]: e.target.checked });
    let payment_plans = [];
    if (e.target.checked) {
      payment_plans = [...addcourse.firstform.payment_plans, e.target.name];
    } else {
      payment_plans = addcourse.firstform.payment_plans.filter((item) => item !== e.target.name);
    }

    if (_.isEmpty(payment_plans)) {
      enqueueSnackbar('Please select atleast one payment option', {
        variant: 'error'
      });
      return;
    }
    try {
      let res = await dispatch(
        courseUpdating({
          ...addcourse.firstform,
          payment_plans: payment_plans
        })
      );
      if (e.target.name === 'full_payment') {
        if (e.target.checked) {
          enqueueSnackbar('The full payment option is selected and saved for this program.');
        } else {
          enqueueSnackbar('The full payment option is unselected and saved for this program.');
        }
      }
      if (e.target.name === 'payment_plan') {
        if (e.target.checked) {
          enqueueSnackbar('The payment plan option is selected and saved for this program.');
          getPaymentPlanInfo();
        } else {
          enqueueSnackbar('The payment plan option is unselected and saved for this program.');
        }
      }
    } catch (error) {
      // const message = error?.data?.non_field_errors ?? 'An error occurred';
      // enqueueSnackbar(message, {
      //   variant: 'error'
      // });
    }
  };
  // console.log("loadingtoform", loadingtoform, loading, value);
  const handleAddInstallment = (index) => {
    let info = {
      installment_index: payInstallment[payInstallment.length - 1].installment_index,
      amount: '',
      due_in_days: '',
      //  payInstallment[payInstallment.length - 1].due_in_days,
      plan: 1
    };
    let installment = [...payInstallment, info];
    setPayInstallment(installment);
  };

  const sumoOfAllAmount = useMemo(
    () => payInstallment?.reduce((acc, item) => +acc + +item.amount, +paymentInfo[0]?.registration_fee),
    [payInstallment, paymentInfo]
  );
  const handleChangeAmount = (e, index) => {
    let pay = [...payInstallment];
    pay[index].amount = e.target.value;
    setPayInstallment(pay);
  };
  const handleChangeDueDate = (e, index) => {
    let pay = [...payInstallment];
    pay[index].due_in_days = e.target.value;

    setPayInstallment(pay);
  };
  const handleDelete = (index) => {
    let pay = [...payInstallment];
    pay.splice(index, 1);
    setPayInstallment(pay);
  };
  const handleRegAmount = (e) => {
    let pay = [...paymentInfo];
    pay[0].registration_fee = e.target.value;
    setPaymentInfo(pay);
  };

  const saveChanges = async () => {
    // dispatch(editcourse())
    setLoading(true);
    let data = {
      ...paymentInfo[0],
      registration_fee: paymentInfo[0].registration_fee,
      installments: payInstallment.map((a, index) => ({
        ...a,
        amount: +a.amount,
        installment_index: index + 1
      }))
    };

    try {
      let res = await eduleyApi.patch(`${PAYMENT_PLAN}${paymentInfo[0].id}/?course=${addcourse.firstform.id}`, data);
      enqueueSnackbar('Payment Plan Updated Successfully');
      getPaymentPlanInfo();
      handleCloseDrawer();
    } catch (error) {
      console.log('error', error);
      if (!_.isEmpty(error.data.data.non_field_errors)) {
        enqueueSnackbar(error.data.data.non_field_errors, {
          variant: 'error'
        });
      }
    }
    setLoading(false);
  };

  const checkHoleValidation = useMemo(() => {
    return (
      payInstallment?.some((a, index) => {
        let normalCheck =
          +a.due_in_days >= +addcourse.firstform.duration_in_weeks * 7 ||
          !a.due_in_days ||
          +a.due_in_days == 0 ||
          +a.amount <= 0 ||
          paymentInfo[0]?.registration_fee <= 0;
        if (index > 0) {
          return normalCheck || +payInstallment[index - 1].due_in_days >= +payInstallment[index].due_in_days;
        }
        return normalCheck;
      }) || +courseInfo.price !== +sumoOfAllAmount
    );
  }, [payInstallment, courseInfo.price, sumoOfAllAmount]);
  const dueDateValidation = (a, b) => {
    return +a >= +b;
  };
  return (
    <>
      {/* {loading && <LinearProgress />} */}
      <Drawer
        open={true}
        anchor={'right'}
        //  onOpen={toggleDrawer(true)}
        onClose={handleCloseDrawer}
        sx={{
          '& .MuiDrawer-paper': {
            width: {
              xs: '100%',
              sm: '100%',
              md: '70%',
              lg: '60%'
            },
            // maxWidth: 500,
            // padding: 2,
            py: 2
          },
          height: '100%'
        }}
        //  onClose={toggleDrawer(false)}
      >
        <Stack direction="row" spacing={3} sx={{ mb: 1 }}>
          <Tooltip title="Go back">
            <IconButtonAnimate onClick={handleCloseDrawer} size="small">
              <Iconify icon={'material-symbols:arrow-back-rounded'} width={20} height={20} />
            </IconButtonAnimate>
          </Tooltip>
          <Typography
            variant="subtitle"
            sx={{
              fontWeight: 'bold',
              fontSize: 16
            }}
          >
            Configure Payment Options
          </Typography>
        </Stack>
        <RoleBasedGuard
          accessibleRoles={accessibleRoles}
          noPermissionChildren={<PaymentPlanView data={value} loading={loadingtoform} paymentInfo={paymentInfo} />}
        >
          <Box
            sx={{
              // borderRadius: 2,
              bgcolor: theme.palette.grey[200]
              // height: 1,
            }}
          >
            <TableLoader loading={loading} />
            <CardHeader title="Choose Payment Options for User" />
            <CardContent>
              <ChoosePaymentType handleChange={handleChange} value={value} disabled={loadingtoform} />
            </CardContent>
          </Box>
          {/* {JSON.stringify(paymentInfo)} */}

          {value.installment && !_.isEmpty(paymentInfo) && (
            <>
              <Box
                sx={{
                  width: '100%'
                }}
              >
                <TableLoader loading={loading} />
                <CardHeader
                  title="Configure Payment Plan"
                  sx={{
                    fontWeight: 'bold',
                    fontSize: 16
                  }}
                />
                <CardContent>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableCell>Installment#</TableCell>
                        <TableCell>Installment Amount ({courseInfo?.currency?.prefix || courseInfo?.currency?.suffix})</TableCell>
                        <TableCell>
                          Due Date (in days)
                          <Tooltip
                            describeChild
                            arrow
                            title="The due date is calculated from the course start date and should be within the program duration."
                          >
                            <IconButton sx={{ mt: -1 }}>
                              <Iconify
                                icon="ant-design:exclamation-circle-twotone"
                                width={20}
                                height={20}
                                sx={{
                                  mb: -0.5,
                                  ml: 1
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        <TableCell>Actions</TableCell>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>Registration Fee</TableCell>
                          <TableCell>
                            <TextField
                              variant="standard"
                              value={paymentInfo[0]?.registration_fee}
                              type="number"
                              placeholder="Enter amount"
                              onChange={handleRegAmount}
                            />
                          </TableCell>
                          <TableCell>0</TableCell>
                          <TableCell>
                            <IconButton color="error" variant="contained" disabled>
                              <Iconify icon="line-md:remove" />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                        {payInstallment?.map((a, index) => (
                          <TableRow key={index}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>
                              <TextField
                                variant="standard"
                                value={
                                  a.amount
                                  // (+a.amount && +fNumber2decimal(a.amount)) || ""
                                }
                                type="number"
                                placeholder="Enter amount"
                                onChange={(e) => handleChangeAmount(e, index)}
                                error={a.amount <= 0 && a.amount != ''}
                                helperText={a.amount <= 0 && a.amount != '' && 'Amount should be greater than 0'}
                              />{' '}
                            </TableCell>
                            <TableCell>
                              <TextField
                                variant="standard"
                                placeholder="Enter days"
                                type="number"
                                onChange={(e) => handleChangeDueDate(e, index)}
                                value={a.due_in_days}
                                error={index != 0 && a.due_in_days != '' && dueDateValidation(payInstallment[index - 1].due_in_days, a.due_in_days)}
                                helperText={
                                  index != 0 &&
                                  a.due_in_days != '' &&
                                  dueDateValidation(payInstallment[index - 1].due_in_days, a.due_in_days) &&
                                  'Days should more than the previous installments days'
                                }
                              />
                            </TableCell>
                            <TableCell>
                              <IconButton color="error" onClick={() => handleDelete(index)} variant="contained" disabled={index == 0}>
                                <Iconify icon="line-md:remove" />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell colSpan={4}>
                            <Stack>
                              <Button variant="outlined" onClick={() => handleAddInstallment()} size="small">
                                Add Installment
                              </Button>
                            </Stack>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={4}>
                            <Divider />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              display: 'flex'
                            }}
                            colSpan={1}
                          >
                            <Typography variant="body1" sx={{ ml: 1 }} noWrap>
                              No of Installment: {payInstallment?.length || 0}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Stack direction={'row'}>
                              <Typography
                                variant="body1"
                                sx={{
                                  ml: 1,
                                  display: 'inline'
                                }}
                              >
                                Total Amount:{' '}
                              </Typography>
                              <Typography
                                variant="body1"
                                sx={{
                                  ml: 1,
                                  display: 'inline',
                                  color: (sumoOfAllAmount != addcourse.firstform.price && 'error.main') || 'primary.main'
                                }}
                              >
                                {/* {courseInfo?.currency?.prefix ||
                              courseInfo?.currency?.suffix}{" "} */}
                                {sumoOfAllAmount}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body1" sx={{ ml: 1, display: 'inline' }} noWrap>
                              Program duration: {addcourse.firstform.duration_in_weeks * 7} Days
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                    <Divider />
                    <Stack
                      direction="row"
                      //   alignContent={"flex-end"}
                      justifyContent="space-between"
                      // alignItems={"flex-end"}
                      width="100%"
                      sx={{ mt: 1, width: '100%' }}
                    >
                      <Stack>
                        <Typography variant="error" color="error">
                          {+courseInfo.price !== +sumoOfAllAmount && (
                            <>
                              Total amount should be equal to program price <b>{fAmount(courseInfo.total_price)}</b>
                            </>
                          )}
                        </Typography>
                        {/* check due date is greater than course duration */}
                        {
                          <Typography variant="error" color="error">
                            {payInstallment?.some((a) => +a.due_in_days >= addcourse.firstform.duration_in_weeks * 7) &&
                              `Due in days should be less than ${addcourse.firstform.duration_in_weeks * 7} days`}
                            {/* {JSON.stringify(paymentInfo)} */}
                            {(!paymentInfo[0].installments[0].due_in_days || paymentInfo[0].installments[0].due_in_days == 0) &&
                              `Due in days should be greater than 0 days`}
                          </Typography>
                        }
                      </Stack>
                    </Stack>
                  </TableContainer>
                </CardContent>
                <CardActions
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <LoadingButton
                    variant="contained"
                    disabled={checkHoleValidation}
                    loading={loadingtoform}
                    onClick={saveChanges}
                    size="large"
                    sx={{ pl: 4, pr: 4 }}
                  >
                    Save
                  </LoadingButton>
                </CardActions>
              </Box>
            </>
          )}
        </RoleBasedGuard>
      </Drawer>
    </>
  );
};

export default PaymentPlan;
