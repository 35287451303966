import { yupResolver } from '@hookform/resolvers/yup';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  IconButton,
  Button,
  Typography,
  styled,
  TextField,
  Grid,
  DialogActions,
  Box,
  useTheme,
  Avatar,
  alpha
} from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Iconify from 'src/components/Iconify';
import Label from 'src/components/Label';
import { UploadSingleFile } from 'src/components/upload';
import { RHFTextField, FormProvider, RHFDatePicker, RHFUploadSingleFile } from 'src/components/hook-form';
import * as Yup from 'yup';
import { getFileFormatIcon } from 'src/utils/getFileFormat';
import { FILE_EXTENSIONS, LabelStyle, MAX_FILE_SIZE, acceptedFileTypes } from '../constants';
import { LoadingButton } from '@mui/lab';
import { eduleyApi } from 'src/api/apiConfig';
import { ENROLLMENT_DOCUMENT_FOR_COURSE, ENROLLMENT_DOCUMENT_FOR_INSTITUTE } from 'src/apiUrl';
import { useSnackbar } from 'notistack';
import useUpload from 'src/hooks/useUpload';
import { getEnrollmentDocuments, getEnrollmentDocumentsForInstitute, setSelectedDocument } from 'src/redux/slices/configuration';
import { useLocation, useParams } from 'react-router';

const AddCourseDocument = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const param = useLocation()
  const { enqueueSnackbar } = useSnackbar();
  const { selectedDocument } = useSelector((state) => state.configuration);

  const NewDocumentSchema = Yup.object().shape({
    name: Yup.string().required('Name is required')
  });

  const defaultValues = useMemo(
    () => ({
      name: selectedDocument?.name || '',
      file_extension_type: selectedDocument?.file_extension_type || ['pdf'],
      max_file_size: selectedDocument?.max_file_size || 2,
      instruction: selectedDocument?.instruction || '',
      template_file: selectedDocument?.template_file || undefined
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedDocument]
  );
  console.log('params', param)

  const methods = useForm({
    resolver: yupResolver(NewDocumentSchema),
    defaultValues
  });

  const {
    reset,
    watch,
    control,
    setValue,

    handleSubmit,
    formState: { isSubmitting }
  } = methods;

  const template_file = watch('template_file');
  const { mediaId, isMediaUploading, progressPercentage, file, resetMedia, mediaError } = useUpload(template_file, {
    onSuccess: (id) => {
      setValue('template_file', id);
    }
  });

  useEffect(() => {
    reset(defaultValues);
  }, [selectedDocument]);
  const ENROLLMENT_DOCUMENT = ENROLLMENT_DOCUMENT_FOR_COURSE
  useEffect(() => {
    // handleAddDocument()
    // if (mediaId > 0) {
    //   setValue('template_file', mediaId);
    // }
  }, [mediaId]);

  async function handleAddDocument(data) {
    try {
      console.log('Uploadig Data:', data);
      const response = await eduleyApi.post(ENROLLMENT_DOCUMENT, data);
      enqueueSnackbar(`${data?.name} added successfully`, { variant: 'success' });
      handleCallNewData()
      onClose();
      return response;
    } catch (error) {
      console.log('Error:', error);
      const errorField = error.data.data;
      for (const fieldName in errorField) {
        if (fieldName == 'non_field_errors') {
          enqueueSnackbar(errorField[fieldName][0], { variant: 'error' });
          return;
        }
        if (errorField.hasOwnProperty(fieldName) && Array.isArray(errorField[fieldName])) {
          // Handle errors for the specific field
          const fieldErrors = errorField[fieldName];
          console.error(`${fieldName} Error:`, fieldErrors);
          // Display a toast notification for each error message
          fieldErrors.forEach((errorMessage) => {
            enqueueSnackbar(`${fieldName} Error: ${errorMessage}`, { variant: 'error' });
          });
        }
      }
      return error;
    }
  }
  const handleCallNewData = () => {
    if (param.pathname.includes('theme')) {
      dispatch(getEnrollmentDocumentsForInstitute())
    } else {
      dispatch(getEnrollmentDocuments())
    }
  };

  async function handleEditDocument(data) {
    try {
      console.log('Uploadig Data:', data);
      const response = await eduleyApi.patch(`${ENROLLMENT_DOCUMENT}${selectedDocument?.id}/`, data);
      enqueueSnackbar(`${data?.name} updated successfully`, { variant: 'success' });
      handleCallNewData();
      onClose();
      return response;
    } catch (error) {
      console.log('Error:', error);
      const errorField = error.data.data;
      for (const fieldName in errorField) {
        if (errorField.hasOwnProperty(fieldName) && Array.isArray(errorField[fieldName])) {
          // Handle errors for the specific field
          const fieldErrors = errorField[fieldName];
          console.error(`${fieldName} Error:`, fieldErrors);
          // Display a toast notification for each error message
          fieldErrors.forEach((errorMessage) => {
            enqueueSnackbar(`${fieldName} Error: ${errorMessage}`, { variant: 'error' });
          });
        }
      }
      return error;
    }
  }

  const handleDrop = useCallback(

    (acceptedFiles) => {
      resetMedia();
      // console.log('acceptedFiles', acceptedFiles);
      const file = acceptedFiles[acceptedFiles.length - 1];
      if (file) {
        setValue(
          'template_file',
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        );
      }
    },
    [setValue]
  );

  function onClose() {
    dispatch(setSelectedDocument(null));
    reset(defaultValues);
    resetMedia();
    handleClose();
  }

  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose}>
      <DialogTitle>{selectedDocument ? 'Edit Document' : 'Add Document'}</DialogTitle>
      <DialogContent>
        <FormProvider methods={methods}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <LabelStyle sx={{ mt: 2 }}>Document Name*</LabelStyle>
              <RHFTextField name="name" size="small" placeholder="Enter document name" autoFocus={!selectedDocument} />
              {/* <TextField fullWidth name="name" size="small" /> */}

              <Stack direction={'column'} sx={{ mt: 2 }}>
                <LabelStyle>File Extention Allowed* </LabelStyle>
                <Controller
                  name="file_extension_type"
                  control={control}
                  render={({ field }) => (
                    <Box
                      sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(3,1fr)',
                        gap: 2
                      }}
                    >
                      {FILE_EXTENSIONS.map((item) => (
                        <FileExtensionOption
                          {...field}
                          url={getFileFormatIcon(item)}
                          name={item}
                          isSelected={field.value?.includes(item)}
                          onSelect={() => {
                            if (field.value?.includes(item)) {
                              let update = field.value.length > 1 ? field.value?.filter((a) => a !== item) : field.value;
                              field.onChange(update);
                            } else {
                              let update = [...field.value, item];
                              field.onChange(update);
                            }
                          }}
                        />
                      ))}
                    </Box>
                  )}
                />
              </Stack>
              <Stack sx={{ mt: 4 }}>
                <LabelStyle sx={{ width: '50%' }}>Max File Size*</LabelStyle>
                <Controller
                  name="max_file_size"
                  control={control}
                  render={({ field }) => (
                    <Box
                      sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(3,1fr)',
                        gap: 2
                      }}
                    >
                      {MAX_FILE_SIZE.map((item) => (
                        <MaxFileSize
                          {...field}
                          maxSize={item}
                          isSelected={field.value === item}
                          onSelect={() => {
                            field.onChange(item);
                          }}
                        />
                      ))}
                      {/* {field.value && !MAX_FILE_SIZE.includes(field.value) && <MaxFileSize {...field} isSelected maxSize={field.value} />} */}
                    </Box>
                  )}
                />
                {/* <TextField fullWidth type="number" name="size" size="small" /> */}
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Stack sx={{ mt: 2 }}>
                <LabelStyle>Instruction for student (Optional)</LabelStyle>
                <RHFTextField rows={2} multiline fullWidth name="instruction" />
                {/* <TextField fullWidth multiline rows={3} name="instruction" size="small" /> */}
              </Stack>
              <Stack direction={'column'} sx={{ mt: 2 }}>
                <LabelStyle>Upload a template file (Optional)</LabelStyle>
                <RHFUploadSingleFile name="template_file" accept={acceptedFileTypes.join(',')} onDrop={handleDrop} />
                <Stack direction="row" alignItems="center" spacing={1} sx={{ py: 1.5 }}>
                  {isMediaUploading && <Iconify icon="line-md:uploading-loop" height={30} width={30} color="primary.main" />}
                  {progressPercentage > 0 && progressPercentage < 100 && (
                    <Typography color="primary.main" variant="body1">
                      {progressPercentage}%
                    </Typography>
                  )}
                  {file && <Iconify icon="icon-park-solid:file-success" height={30} width={30} color="primary.main" />}
                  {mediaError && <Iconify icon="material-symbols-light:error" height={30} width={30} color="primary.main" />}
                  {(template_file || file) && (
                    <Typography variant="body1" color={mediaId ? 'primary.main' : 'text.secondary'}>
                      {template_file?.name || file?.name}
                    </Typography>
                  )}
                  {selectedDocument && selectedDocument.template_file && (
                    <Button variant="text" color="primary" size="small" onClick={() => window.open(selectedDocument.template_file, '_blank')}>
                      Download Template
                    </Button>
                  )}
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <LoadingButton
          loading={isSubmitting}
          onClick={methods.handleSubmit(selectedDocument ? handleEditDocument : handleAddDocument)}
          variant="contained"
        >
          {selectedDocument ? 'Update Document' : 'Create Document'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddCourseDocument;

const FileExtensionOption = ({ url, name, isSelected, onSelect }) => {
  const theme = useTheme();
  const [isHover, setHover] = useState(false);

  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  return (
    <Box
      sx={{
        border: `${isSelected ? '2px' : '1px'} solid ${isHover || isSelected ? theme.palette.primary.main : theme.palette.divider}`,
        transition: `0.2s all`,
        borderRadius: 1.5,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        px: 2.5,
        justifyContent: 'center',
        minHeight: 80,
        cursor: 'pointer',
        bgcolor: isSelected ? alpha(theme.palette.primary.main, 0.07) : 'transparent'
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={onSelect}
    >
      <Avatar src={url} sx={{ width: 45, height: 45 }} />
      <Typography variant="body2">{name}</Typography>
    </Box>
  );
};

const MaxFileSize = ({ maxSize, onSelect, isSelected }) => {
  const theme = useTheme();
  const [isHover, setHover] = useState(false);

  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };
  return (
    <Stack
      sx={{
        border: `${isSelected ? '2px' : '1px'} solid ${isHover || isSelected ? theme.palette.primary.main : theme.palette.divider}`,
        transition: `0.2s all`,
        borderRadius: 1.5,
        display: 'flex',
        alignItems: 'center',
        //   flexDirection: 'column',
        px: 2.5,
        justifyContent: 'center',
        minHeight: 80,
        cursor: 'pointer',
        bgcolor: isSelected ? alpha(theme.palette.primary.main, 0.07) : 'transparent'
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={onSelect}
      direction="row"
      spacing={0.5}
    >
      <Typography variant="h4" color={isSelected || isHover ? 'primary.main' : 'text.secondary'}>
        {maxSize}
      </Typography>
      <Typography variant="body2" color={isSelected || isHover ? 'primary.main' : 'text.secondary'}>
        MB
      </Typography>
    </Stack>
  );
};
