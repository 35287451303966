import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { Divider, IconButton, MenuItem, TableCell, TableRow, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// utils
// components
import moment from 'moment';
import Iconify from 'src/components/Iconify';
import { MenuPopoverV2 } from 'src/components/menu-popover-v2';

// ----------------------------------------------------------------------

AccessPermissionRow.propTypes = {
  row: PropTypes.object.isRequired
};

export default function AccessPermissionRow({ row, type, onAccessChange }) {
  const theme = useTheme();

  const { created_date, institute_name, email, requestFor, remarks } = row;

  const [openPopover, setOpenPopover] = useState(null);

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  return (
    <>
      <TableRow hover selected={false}>
        <TableCell align="left">{email}</TableCell>

        <TableCell align="left">{institute_name}</TableCell>

        <TableCell align="left">{remarks}</TableCell>

        <TableCell align="left">{moment(created_date).format('DD MMMM yyyy')}</TableCell>
        <TableCell align="left">
          {type === 'pending' ? (
            <IconButton color={openPopover ? 'inherit' : 'default'} onClick={handleOpenPopover}>
              <Iconify icon="eva:more-vertical-fill" />
            </IconButton>
          ) : (
            <Typography sx={{ alignSelf: 'center', color: type === 'granted' ? 'green' : 'red', fontSize: 14 }}>
              {type === 'granted' ? 'Granted' : 'Rejected'}
            </Typography>
          )}
        </TableCell>
      </TableRow>
      <MenuPopoverV2 open={openPopover} onClose={handleClosePopover} arrow="right-top" sx={{ width: 160 }}>
        <MenuItem
          onClick={() => {
            handleClosePopover();
            onAccessChange('granted');
          }}
          sx={{ color: 'success.main' }}
        >
          <Iconify icon="ep:success-filled" />
          Grant
        </MenuItem>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          onClick={() => {
            onAccessChange('revoked');
            handleClosePopover();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="material-symbols:cancel-outline" />
          Reject
        </MenuItem>
      </MenuPopoverV2>
    </>
  );
}
