import axios from "axios";
import { MAINTAINANCE } from "src/apiUrl";
import { HOST_API } from "../config";

//Auth api
export const authApi = axios.create({
  baseURL: HOST_API,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",

    // "Access-Control-Allow-Origin": "*",
  },
});

authApi.interceptors.request.use(
  async function (options) {

    return options;
  },
  function (error) {
    return Promise.reject(error);
  }
);

authApi.interceptors.response.use(
  async function (options) {
    return options;
  },
  function (error) {
    if (error?.response?.status == 503) {
      // localStorage.setItem('mode', 'maintain');
      checkonmaintain();
      // localStorage.clear();
    }
    if (error?.response?.status == 500) {
      window.location.href = "/500";
    }
    return Promise.reject({ error, data: error.response });
  }
);

//Authorized api
export const eduleyApi = axios.create({
  baseURL: HOST_API,
  // timeout: 30000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

eduleyApi.interceptors.request.use(
  async function (options) {
    options.headers["Authorization"] = `Bearer ${localStorage.getItem("accessToken")}`;
    return options;
  },
  function (error) {
    return Promise.reject(error);
  }
);

eduleyApi.interceptors.response.use(
  async function (response) {
    if (response?.status == 401 || response?.status == 403) {
      localStorage.clear();
      window.location.href = "/auth/login";
    }
    if (response?.status == 500) {
      // window.location.href = "/500";
    }

    // console.log('Api Response :',response)
    return response;
  },
  function (error) {
    if (error?.response?.status == 503) {
      // localStorage.setItem('mode', 'maintain');
      checkonmaintain();
      // localStorage.clear();
    }
    if (error?.response?.status == 500) {
      // window.location.href = "/500";
    }
    if (error.response?.status == 401) {
      localStorage.clear();
      window.location.href = "/auth/login";
    }
    if (error.response?.status == 403) {
      localStorage.clear();
      window.location.href = "/auth/login";
    }
    // console.log('API Error :', error.response)
    return Promise.reject({ error, data: error.response });
  }
);
// i love it

const checkonmaintain = async () => {
  try {
    let res = await eduleyApi.get(MAINTAINANCE);
    if (res?.data.in_maintenance) {
      if (res?.data.allow_access_in_maintenance) {
      } else {
        console.log("resfdf--------", res.data);
        localStorage.clear();

        localStorage.setItem("mode", "maintain");

        window.location.href = "/maintenance";
      }
    }
    // setMaintain(res.data);
    // console.log("res---", res);
  } catch (error) { }
};
