import axios from 'axios';
// config
import { HOST_API } from '../config';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status == '401') {
      localStorage.clear()
      window.location.href = "/auth/login";
    }
    console.log('error', error.response)
    return Promise.reject((error) || 'Something went wrong')
  }
);

export default axiosInstance;
