import { createSlice } from '@reduxjs/toolkit';

import {
  STUDENT_COURSE_ASSIGNMENT_REPORT_HIGHLIGHT,
  STUDENT_COURSE_QUIZ_REPORT_HIGHLIGHT,
  STUDENT_COURSE_REPORT,
  STUDENT_COURSE_REPORT_HIGHLIGHT,
  STUDENT_LIST
} from 'src/apiUrl';
// utils
//
import { eduleyApi } from 'src/api/apiConfig';
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  student: {},
  error: null,
  openUpdateCard: false,
  studentcourse: {
    data: {},
    stu_details: {},
    loading: false,
    coursehighlight: {},
    quizhighlight: {},
    assignmenthighlight: {}
  },
  currentUser: {
    data: {},
    loading: false
  }
};

const slice = createSlice({
  name: 'student',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setUserviewdatanull(state, action) {
      state.studentcourse = {
        data: {},
        stu_details: {},
        loading: false,
        coursehighlight: {},
        quizhighlight: {},
        assignmenthighlight: {}
      };
    },
    // GET PRODUCTS
    getUserSuccess(state, action) {
      state.isLoading = false;
      const stu = action.payload;
      state.student = stu;
    },
    startCourseLoading(state) {
      state.studentcourse.loading = true;
    },
    getUsercourseSuccess(state, action) {
      state.studentcourse.loading = false;
      const stu = action.payload;

      state.studentcourse.data = stu;
    },
    getUserDetailsSuccess(state, action) {
      state.studentcourse.loading = false;
      const stu = action.payload;

      state.studentcourse.stu_details = stu;
    },
    getUsercoursehighlightSuccess(state, action) {
      state.studentcourse.loading = false;
      const stu = action.payload;

      state.studentcourse.coursehighlight = stu;
    },
    handleUpdateUser(state, action) {
      state.openUpdateCard = action.payload;
    },
    getUserquizhighlightSuccess(state, action) {
      state.studentcourse.loading = false;
      const stu = action.payload;

      state.studentcourse.quizhighlight = stu;
    },
    getUserassignmenthighlightSuccess(state, action) {
      state.studentcourse.loading = false;
      const stu = action.payload;
      state.studentcourse.assignmenthighlight = stu;
    },
    getCurrentUserSuccess(state, action) {
      state.currentUser.loading = false;
      const stu = action.payload;
      state.currentUser.data = stu;
      state.openUpdateCard = false;
    },
    startCurrentUserLoading(state) {
      state.currentUser.loading = true;
    },
    setCurrentUserDataNull(state, action) {
      state.currentUser = {
        data: {},
        loading: false
      };
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setCurrentUserDataNull, handleUpdateUser } = slice.actions;

// ----------------------------------------------------------------------

export function getUserList(q) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await eduleyApi.get(`${STUDENT_LIST}${q}`);
      dispatch(slice.actions.getUserSuccess(response.data));
    } catch (error) {
      // getStatus(error.response);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setClearallstudentcourseviewdata(q) {
  return () => {
    dispatch(slice.actions.setUserviewdatanull());
  };
}
export function getUserdetails(id) {
  return async () => {
    dispatch(slice.actions.startCourseLoading());
    try {
      const response = await eduleyApi.get(`${STUDENT_LIST}${id}/`);
      // console.log("student details---", response.data, params);
      dispatch(slice.actions.getUserDetailsSuccess(response.data));
      dispatch(handleUpdateUser(false));
      // setUserData(response.data);
      // let p = response?.data?.subscriptions?.find((a) => a.id == params.course);
      // console.log(p);
      // setProgressData(p);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUserCourse(p, q) {
  return async () => {
    dispatch(slice.actions.startCourseLoading());
    try {
      const response = await eduleyApi.get(`${STUDENT_COURSE_REPORT}${q}`);
      console.log(response, q);
      dispatch(slice.actions.getUsercourseSuccess(response.data));
    } catch (error) {
      // getStatus(error.response);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUserCourseHighlight(q) {
  return async () => {
    dispatch(slice.actions.startCourseLoading());
    try {
      const response = await eduleyApi.get(`${STUDENT_COURSE_REPORT_HIGHLIGHT}?subscription=${q}`);
      dispatch(slice.actions.getUsercoursehighlightSuccess(response.data));
    } catch (error) {
      // getStatus(error.response);
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getUserCourseQuizHighlight(q) {
  return async () => {
    dispatch(slice.actions.startCourseLoading());
    try {
      const response = await eduleyApi.get(`${STUDENT_COURSE_QUIZ_REPORT_HIGHLIGHT}?subscription=${q}`);
      dispatch(slice.actions.getUserquizhighlightSuccess(response.data));
    } catch (error) {
      // getStatus(error.response);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUserCourseAssignmentHighlight(q) {
  return async () => {
    dispatch(slice.actions.startCourseLoading());
    try {
      const response = await eduleyApi.get(`${STUDENT_COURSE_ASSIGNMENT_REPORT_HIGHLIGHT}?subscription=${q}`);
      dispatch(slice.actions.getUserassignmenthighlightSuccess(response.data));
    } catch (error) {
      // getStatus(error.response);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCurrentUser(id) {
  return async () => {
    dispatch(slice.actions.startCurrentUserLoading());
    try {
      const response = await eduleyApi.get(`${STUDENT_LIST}${id}/`);
      dispatch(slice.actions.getCurrentUserSuccess(response.data));
      // dispatch(handleUpdateUser(false));
    } catch (error) {
      // getStatus(error.response);
      dispatch(slice.actions.hasError(error));
    }
  };
}
