import { Button, Drawer, Stack, Switch, Typography, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ConfirmDialog from 'src/components/confirm-dialog/ConfirmDialog';
import RoleBasedGuard from 'src/guards/RoleBasedGuard';
import { updateUserDocument } from 'src/redux/slices/courseupdate';
import AddRemoveDocument from './AddRemoveDocument';
import DisabledUserDocument from './DisabledUserDocument';

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16
    },
    '&:before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12
    },
    '&:after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12
    }
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2
  }
}));

export default function UserDocumentDrawer({
  open,
  onClose,
  onAddDocument,
  onSubmit,
  list,
  getList,
  studentDocuments,
  courseInfo,
  getDocumentList,
  accessibleRoles,
  ...other
}) {
  const dispatch = useDispatch();

  const [openCaution, setOpenCaution] = useState(false);
  const [enableUserDocument, setEnableUserDocument] = useState(false);
  useEffect(() => {
    setEnableUserDocument(!!courseInfo?.enable_student_document);
  }, [courseInfo?.enable_student_document, open]);

  function onToggleUserDocuments(e) {
    setOpenCaution(false);
    if (courseInfo?.id) {
      dispatch(
        updateUserDocument({
          ...courseInfo,
          enable_student_document: e
        })
      );
    }
  }

  function onOpenCaution(e) {
    if (!e.target.checked) {
      setOpenCaution(true);
      setEnableUserDocument(false);
    } else {
      setEnableUserDocument(true);
    }
  }

  function onCloseCaution() {
    setOpenCaution(false);
  }

  function onOkay() {
    onToggleUserDocuments(false);
    onCloseCaution();
  }

  return (
    <>
      <Drawer
        open={open}
        onClose={onClose}
        anchor="right"
        BackdropProps={{
          invisible: true
        }}
        PaperProps={{
          sx: { width: 380 }
        }}
        {...other}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ p: 2.5 }}>
          <Typography variant="h6"> User Documents </Typography>
          <RoleBasedGuard accessibleRoles={accessibleRoles} noPermissionChildren={<></>}>
            <Android12Switch defaultChecked onChange={onOpenCaution} checked={enableUserDocument} />
          </RoleBasedGuard>
        </Stack>
        {enableUserDocument ? (
          <AddRemoveDocument
            getDocumentList={getDocumentList}
            onClose={onClose}
            studentDocuments={studentDocuments}
            courseInfo={courseInfo}
            accessibleRoles={accessibleRoles}
          />
        ) : (
          <DisabledUserDocument />
        )}

        <ConfirmDialog
          title="Are you sure you want to disable student document?"
          content="User documents is required for subscriptions and any new subscriptions will not be able to upload document"
          action={
            <Button onClick={onOkay} color="error" variant="contained">
              Yes, Disable
            </Button>
          }
          onClose={onCloseCaution}
          open={openCaution}
        />
      </Drawer>
    </>
  );
}
