import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';
import { eduleyApi } from 'src/api/apiConfig';
import { COURSE_LIVE_SESSION } from 'src/apiUrl';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  liveConnect: {
    data: null,
    error: null,
    isLoading: false
  }
};

const slice = createSlice({
  name: 'connect',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    startLiveConnectLoading(state) {
      state.liveConnect.isLoading = true;
    },
    setLiveConnectList(state, action) {
      state.liveConnect.data = action.payload;
      state.liveConnect.isLoading = false;
    },
    setLiveConnectListError(state, action) {
      state.liveConnect.error = action.payload;
      state.liveConnect.isLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { addRecipients, onSendMessage, resetActiveConversation } = slice.actions;

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export function getLiveSessionList(params) {
  return async () => {
    dispatch(slice.actions.startLiveConnectLoading());
    try {
      const response = await eduleyApi.get(COURSE_LIVE_SESSION, { params });
      dispatch(slice.actions.setLiveConnectList(response.data));
    } catch (error) {
      dispatch(slice.actions.setLiveConnectListError(error));
    }
  };
}

// ----------------------------------------------------------------------
