import { createSlice } from "@reduxjs/toolkit";
import axios from "../../../src/utils/axios";

import { useSnackbar } from "notistack";
import {
  FORUM_THREAD,
  FORUM_THREAD_COMMENT,
  FORUM_THREAD_CREATE,
} from "src/apiUrl";
// utils
//
import { dispatch } from "../store";
import { eduleyApi } from "src/api/apiConfig";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  thread: {},
  error: null,
};

const slice = createSlice({
  name: "forum",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getThreadSuccess(state, action) {
      state.isLoading = false;
      const products2 = action.payload;
      state.thread = products2;
    },
    getThreadSuccessbyid(state, action) {
      state.isLoading = false;
      const products2 = action.payload;
      state.thread = [products2, ...state.thread];
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {} = slice.actions;

// ----------------------------------------------------------------------

const accessToken = localStorage.getItem("accessToken");

const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${accessToken}`,
  },
};

export function getForumThread(q) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await eduleyApi.get(`${FORUM_THREAD}${q}`);

      dispatch(slice.actions.getThreadSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getForumThreadById(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await eduleyApi.get(`${FORUM_THREAD}${id}/`);
      return response.data;
      // dispatch(slice.actions.getThreadSuccessbyid(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

export function createForumThread(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${FORUM_THREAD_CREATE}`, data, config);
      // dispatch(slice.actions.getThreadSuccessbyid(response.data));
      console.log("response--success", response.data);
      return "success";
    } catch (error) {
      console.log("response--eerrrrrr", error.response);

      dispatch(slice.actions.hasError(error));
      return error.response;
    }
  };
}
export function createthreadcomment(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${FORUM_THREAD_COMMENT}`,
        data,
        config
      );
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return "error";
    }
  };
}
