import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// slices

import courseReducer from "./slices/course";
import courseupReducer from "./slices/courseupdate";
import lessonReducer from "./slices/lesson";
import mediaReducer from "./slices/media";
import userReducer from "./slices/user";
import subscriptionReducer from "./slices/subscriptions";
import revenueReducer from "./slices/revenue";
import supportReducer from "./slices/support";
import trackReducer from "./slices/track";
import fundManagementReducer from "./slices/fund_management";
// general
import studentReducer from "./slices/student";
import forumReducer from "./slices/forum";
import configurationReducer from "./slices/configuration";
import analyticsReducer from "./slices/analytics";
import attendanceReducer from "./slices/attendance";
import connect from "./slices/connect";


// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const productPersistConfig = {
  key: "courseupdate",
  storage,
  keyPrefix: "redux-",
  whitelist: ["sortBy", "checkout"],
};

const rootReducer = combineReducers({
  course: persistReducer(productPersistConfig, courseReducer),
  courseupdate: courseupReducer,
  lesson: lessonReducer,
  media: mediaReducer,
  user: userReducer,
  subscription: subscriptionReducer,
  revenue: revenueReducer,
  support: supportReducer,
  track: trackReducer,
  fund_management: persistReducer(rootPersistConfig, fundManagementReducer),
  student: studentReducer,
  forum: forumReducer,
  configuration: configurationReducer,
  analytics: analyticsReducer,
  attendance: attendanceReducer,
  connect: connect,
});

export { rootPersistConfig, rootReducer };
