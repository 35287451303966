import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Video from "src/components/Video";
import Image from "src/components/Image";
import DocumentViewer from "src/components/DocumentViewer";
import Iconify from "src/components/Iconify";

const MediaViewer = ({ action, file }) => {
  const [viewmedia, setViewMedia] = useState(false);
  // console.log("rajeev", file, viewmedia);
  return (
    <>
      <div style={{ cursor: "pointer" }} onClick={() => setViewMedia(true)}>
        {action}
      </div>
      {viewmedia && (
        <PlayerModal file={file} handleClose={() => setViewMedia(false)} />
      )}
    </>
  );
};

export default MediaViewer;

const PlayerModal = ({ handleClose, file }) => {
  const [copied, setCopied] = useState(false);
  console.log("file", file);
  const handleCopy = () => {
    navigator.clipboard.writeText(file.url || file.media_file);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);

  }
  return (
    <Dialog fullWidth maxWidth={"md"} open={true} onClose={handleClose}>
      <DialogTitle sx={{ textAlign: "center", mb: 2 }}>{file.name}</DialogTitle>
      <DialogContent>
        {file.file_type == "video" && (
          <Video
            url={file.url || file.media_file}
            controls={true}
            height="45vh"
            width="100%"
          />
        )}
        {file.file_type == "image" && (
          <Image
            key={file.id}
            src={file.media_file || file.url}
            // onClick={() => setOpenModal(true)}
            sx={{
              m: 0.5,
              // width: 1104,
              height: "55vh",
              borderRadius: 1,
              // objectFit:'contain',
              cursor: "pointer",
            }}
          />
        )}
        {file.file_type == "document" && (
          <DocumentViewer
            src={file.media_file || file.url}
            name={file.name}
            sx={{ height: "200px", width: "100%" }}
            alt="media"
          // width="360px"
          />
        )}
        {/* {JSON.stringify(file)} */}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        {/* for copy link  */}
        {!file.is_private && <Button onClick={() => handleCopy()} variant="outlined" startIcon={<Iconify icon={'eva:copy-fill'} width={20} height={20} />}>
          {copied ? 'Copied!' : 'Copy Link'}
        </Button>}
        <Button onClick={() => handleClose()} variant="contained" sx={{ right: 0 }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
