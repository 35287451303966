import { HOST_API } from './config';

//auth
export const LOGIN = HOST_API + '/admin/login/';
export const ADMIN = HOST_API + '/iap/admin/';
export const IAP_USER = HOST_API + '/iap/user/';

export const COURSE_CATEGORY = HOST_API + '/course/admin/category/';
export const COURSE_LANGUAGE = HOST_API + '/course/admin/language/';
// general

// student list
export const STUDENT_LIST = HOST_API + '/iap/report/student/';
export const STUDENT_COURSE_REPORT = HOST_API + '/iap/report/student/progress/course_report/';
export const STUDENT_COURSE_REPORT_HIGHLIGHT = HOST_API + '/iap/report/student/progress/course_overview/highlight';
export const STUDENT_COURSE_ASSIGNMENT_REPORT_HIGHLIGHT = HOST_API + '/iap/report/student/progress/assignment/highlight';
export const STUDENT_COURSE_QUIZ_REPORT_HIGHLIGHT = HOST_API + '/iap/report/student/progress/quiz/highlight';
export const STUDENT_COURSE_ASSIGNMENT_REPORT_LIST = HOST_API + '/iap/report/student/progress/assignment_report/';
export const STUDENT_COURSE_QUIZ_REPORT_LIST = HOST_API + '/iap/report/student/progress/quiz_report/';

// forum
export const FORUM_THREAD = HOST_API + '/forum/iap/thread/';
export const FORUM_THREAD_CREATE = HOST_API + '/forum/iap/thread/add';
// export const FORUM_THREAD_UPDATE = HOST_API + '/forum/iap/thread/update/'
export const FORUM_THREAD_COMMENT = HOST_API + '/forum/iap/thread/comment/';
export const INSTITUTE_LIST = HOST_API + '/institute/list/institute/';

//course

export const COURSE_LIST = HOST_API + '/course/admin/course/';
export const CURRENCY_LIST = HOST_API + '/course/admin/currency/';

export const CREATE_COURSE = HOST_API + '/course/admin/course/';
export const COURSE_MODULE = HOST_API + '/course/admin/section/';
export const COURSE_LESSON = HOST_API + '/course/admin/lesson/';
export const COURSE_SECTION_ORDER = HOST_API + '/course/admin/section/ordering/';
export const COURSE_CONTENT_ORDER = HOST_API + '/course/admin/content/ordering/';
export const COURSE_QUIZ = HOST_API + '/course/admin/quiz/';
export const COURSE_QUIZ_QUESTION = HOST_API + '/course/admin/quiz-question/';

export const COURSE_DOC_ASSIGNMENT = HOST_API + '/course/admin/assignment/';
export const COURSE_PRACTICAL_ASSIGNMENT = HOST_API + '/course/admin/practical-assignment/';
export const COURSE_ZOOM_ASSIGNMENT = HOST_API + '/course/admin/zoom-assignment/';
export const COURSE_CUSTOM_TRANSCRIPT = HOST_API + '/course/admin/course_custom_transcript/';
export const SUBSCRIPTION_CUSTOM_TRANSCRIPT = HOST_API + '/iap/transcript/subscription_custom_transcript/';

export const FINAL_GPA_SCORES = HOST_API + '/iap/transcript/final_gpa_scores/';
export const MEDIA_LIST = HOST_API + '/eduley_media/media/';

export const MEDIA_IMAGE = HOST_API + '/eduley_media/image/';
export const MEDIA_VIDEO = HOST_API + '/eduley_media/video/';
export const MEDIA_VIDEO_TOURL = HOST_API + '/eduley_media/video/get_url/';
export const MEDIA_DOCUMENT = HOST_API + '/eduley_media/document/';

// user list
export const USER_LIST = HOST_API + '/iap/user/';

// Instructor
export const INSTRUCTOR = HOST_API + '/instructor/';
export const USER_ROLE = HOST_API + '/iap/group/';
// analytics

// Analytics > courseOverView
export const ANALYTICS_ASSIGNMENT_HIGHLIGHT = HOST_API + '/iap/report/course/assignment/highlight/';
export const ANALYTICS_ASSIGNMENT_OVERVIEW = HOST_API + '/iap/report/course/assignment_overview/';
export const ANALYTICS_COURSE_HIGHLIGHT = HOST_API + '/iap/report/course/highlight/';
export const ANALYTICS_COURSE_OVERVIEW = HOST_API + '/iap/report/course/overview/';
export const ANALYTICS_QUIZ_HIGHLIGHT = HOST_API + '/iap/report/course/quiz/highlight/';
export const ANALYTICS_QUIZ_OVERVIEW = HOST_API + '/iap/report/course/quiz_overview/';

//subscription
export const SUBSCRIPTION_LIST = HOST_API + '/iap/report/subscription/';
export const SUBSCRIPTION_HIGHLIGHT = HOST_API + '/iap/report/subscription/highlight/';

export const REVENUE_LIST = HOST_API + '/iap/report/revenue/';
export const REVENUE_HIGHLIGHT = HOST_API + '/iap/report/revenue/highlight/';

export const REPORT_COURSE = HOST_API + '/iap/report/course/';

export const COURSE_OVERVIEW_EXCEL = HOST_API + '/iap/report/course/overview/';

export const SUBSCRIPTIONS_OVERVIEW_EXCEL = HOST_API + '/iap/report/subscription/overview/';

export const PAYMENT_PLAN = HOST_API + '/course/admin/payment_plan/';

// export const SUBSCRIPTION_COURSE = HOST_API + "/subscription/course/";

export const COURSE_HIGHLIGHT = HOST_API + '/iap/report/course/highlight/';
// payment subscription

export const PAYMENTPLAN_SUBSCRIPTION_LIST = HOST_API + '/iap/payment/subscription/';
export const PAYMENTPLAN_TRANSACTION = HOST_API + '/iap/payment/transaction/';
export const PAYMENTPLAN_TRANSACTIONS = HOST_API + '/iap/payment/installment/';
export const CHANGE_SUBSCRIPTION_ACCESS = HOST_API + '/iap/subcription_course_access/';
export const SUBSCRIPTION_CUSTOM_PAYMENT = HOST_API + '/iap/payment/subscription_payment/';
export const MANUAL_ADJUSTMENT = HOST_API + '/iap/payment/subscription-refund/';

export const PENDING_INSTALLMENT = HOST_API + '/iap/payment/pending_installment/';

// support Menu

export const BUDGETNISTA_SUPPORT = HOST_API + '/support/iapsupport/ticket/iapsupport/';
export const BUDGETNISTA_SUPPORT_REPLY = HOST_API + '/support/iapsupport/ticket/iapsupport/reply/';
export const SUPPORT_CHAT = HOST_API + '/eduley_support/chat/';

export const STUDENT_SUPPORT = HOST_API + '/support/ticket/student_ticket/';
export const STUDENT_SUPPORT_REPLY = HOST_API + '/support/ticket/iap_reply/';

// tracking of Quiz and assignment

export const QUIZ_TRACK = HOST_API + '/iap/report/quiz/';
export const QUIZ_ATTEMPT_STUDENT = HOST_API + '/iap/report/student/progress/manual-quizattempt-bulk-create';
export const QUIZ_TRACK_MARK_SET = HOST_API + '/iap/report/quiz_marks/';
export const ASSIGNMENT_TRACK = HOST_API + '/iap/report/assignment/';
export const ASSIGNMENT_TRACK_REVIEW = HOST_API + '/iap/report/assignment-subjective/';
export const STUDENT_TRACK = HOST_API + '/iap/report/student/';

// Password reset

export const PASSWORD_RESET = HOST_API + '/iap/password_reset/';
export const PASSWORD_CONFIRM = HOST_API + '/iap/password_reset/confirm/';
export const CHANGE_PASSWORD = HOST_API + '/iap/change_password/';

// fund management
export const FUND_STATEMENT = HOST_API + '/iap/report/transaction/';
export const FUND_STATEMENT_DOWNLOAD = HOST_API + '/iap/report/transaction/export-excel/';
export const FUND_STATEMENT_HIGHLIGHT = HOST_API + '/iap/report/transaction/highlight/';
export const FUND_PAYOUT = HOST_API + '/iap/institute/wallet/institute_payout/';
export const FUND_PAYOUT_ACCOUNT = HOST_API + '/iap/institute/wallet/account/';
export const FUND_PAYOUT_AMOUNT = HOST_API + '/iap/institute/wallet/institute_payout/highlight/';

export const ACCOUNT_TYPE = HOST_API + '/iap/institute/wallet/account_type/';
export const BUDGETNISTA_PAYOUT_HIGHLIGHT = HOST_API + '/iap/institute/wallet/eduley_payout/highlight/';
export const BUDGETNISTA_PAYOUT = HOST_API + '/iap/institute/wallet/eduley_payout/';

export const FUND_TOTAL_PROCCESSED = '/iap/report/total_processed_breakup/highlight/';
export const FUND_TOTAL_PAYABLE = '/iap/report/breakup_payable_to_eduley/highlight/';
export const FUND_TOTAL_RECEIVABLE = '/iap/report/breakup_of_total_receivable/highlight/';

// cohort

export const COHORT = HOST_API + '/course/admin/course/cohort/';
export const COHORT_CREATE = HOST_API + '/course/admin/course/cohort/';

/**************  Configration API List  ***************************/

export const INSTITUTE_CONFIG = HOST_API + '/institute/admin/institute/';
export const LANDING_PAGE_CONFIG = HOST_API + '/landing_page/admin/landing_page/';
export const WALLET_STRIPE = HOST_API + '/iap/institute/wallet/stripe/';
export const USER_FOR_SUPPORT = HOST_API + '/iap/iap-user-for-support/';
export const SUPPORT_USER_EMAIL = HOST_API + '/institute/support-user/';
export const SCOREWEIGHTAGE = HOST_API + '/iap/transcript/institute_transcript_weightage/';
/*********** STUDENT FINANCE  ******/

export const FINANCE_PROVINCE_INFO = HOST_API + '/institute/financial_assistance';
export const PROVINCE_INFO = HOST_API + '/region';
export const COUNTRY_INFO = HOST_API + '/country';

// GAMIFICATION
export const GAMIFICATION_ACTIONS = HOST_API + '/gamification/action/';
export const GAMIFICATION_REWARDS = HOST_API + '/gamification/admin/rewards/';
export const GAMIFICATION_LEVELS = HOST_API + '/gamification/admin/levels/';

export const REWARD_BY_COURSE = HOST_API + '/gamification/admin/rewards_by_course/';
export const REWARD_BY_ACTION = HOST_API + '/gamification/admin/rewards_by_action/';
export const GAMIFICATION_LEBEL_OVERVIEW = HOST_API + '/gamification/admin/levels_overview/';
export const MAINTAINANCE = HOST_API + '/maintenance';

// Cohort Attendance
export const COHORT_ATTENDANCE = HOST_API + '/course/admin/course/cohort/cohort-attendance/';
export const COHORT_ATTENDANCE_ANALYTICS = HOST_API + '/course/admin/course/cohort/cohort-attendance/analytics/';
export const COHORT_ATTENDANCE_ANALYTICS_HIGHLIGHTS = HOST_API + '/course/admin/course/cohort/cohort-attendance/attendance-highlight/';
export const COHORT_ATTENDANCE_REPORT_ATTENDANCE_BY_RANGE = HOST_API + '/course/admin/course/cohort/cohort-attendance/attendance-by-range-highlight/';
export const COHORT_ATTENDANCE_REPORT_ATTENDANCE_BY_DAYS_OF_WEEKS =
  HOST_API + '/course/admin/course/cohort/cohort-attendance/attendance-by-days-of-weeks-highlight/';

export const INSTRUCTOR_ANALYTICS = HOST_API + '/course/admin/course/cohort/cohort-attendance/instructor-analytics/';

export const STUDENT_ATTENDANCE = '/iap/report/student/attendance/';
export const ENROLLMENT_DOCUMENT_FOR_COURSE = '/institute/global_document/course-enrollment-document-configuration/';
export const ENROLLMENT_DOCUMENT_FOR_INSTITUTE = '/institute/global_document/institute-enrollment-document-configuration/';
// upload file
export const UPLOAD_FILE = HOST_API + '/eduley_media/upload-file/';
export const STUDENT_DOCUMENT = '/course/admin/student-document/';

export const STUDENT_DISCOUNT = '/course/admin/discount/';
export const COURSE_FEE_BREAKUP = '/course/admin/course-breakup/';

export const SUBSCRIPTION_FEE_BREAKUP = '/iap/payment/price-breakup/';

export const DOCUMENTS = '/iap/document/';
export const COURSE_LIVE_SESSION = HOST_API + '/course/admin/live-session/';

export const PROFILE_COMPLETION_CONFIGURATION = '/iap/report/student/student-profile-percentage-configuration/';
export const PROFILE_COMPLETION_CONFIGURATION_FIELD = '/iap/report/student/student-profile-fields';
export const PROFILE_VERIFICATION = '/iap/report/student/verification/';
export const INSTITUTE_DOCUMENT = '/institute/global_document/document/';

export const GENERATE_DESCRIPTION = HOST_API + '/course/admin/generate-description/';
export const COUNTER_TRANSACTION = '/iap/payment/counter-transaction/';

export const SCHOLARSHIP = '/iap/payment/scholarship/';
export const SCHOLARSHIP_DETAILS = '/iap/payment/subscription/';

// CLASSROOM
export const CLASSROOM_VIEW = (COURSE_ID) => `iap/report/student/progress/classroom/${COURSE_ID}/`;
export const CLASSROOM_CONTENT = (CONTENT_ID) => `iap/report/student/progress/classroom-content/?content=${CONTENT_ID}`;
export const CLASSROOM_STUDENT = (COURSE_ID) => `iap/report/student/progress/classroom-student/?course=${COURSE_ID}`;

//Platform settings
export const PLATFORM_SETTINGS = HOST_API + '/institute/admin/platform-settings/';
export const PLATFORM_SETTINGS_ACCESS_REQUEST = HOST_API + '/institute/admin/institute-access-request/';
