import { LoadingButton } from '@mui/lab';
import { Button, Grid, Stack, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import Page from 'src/components/Page';
import RoleBasedGuard from 'src/guards/RoleBasedGuard';
import { changesection } from 'src/redux/slices/courseupdate';
import { useDispatch, useSelector } from 'src/redux/store';
import { FeeStructure } from '../../configuration/fee_structure';
import useFeeStructure from '../../configuration/fee_structure/useFeeStructure';
import CustomScore from './component/CustomScore';
import PaymentPlan from './component/PaymentPlan';
import { UserDocument } from './component/UserDocument/index';

const ConfigurationPage = ({ accessibleRoles }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { configuration, addcourse } = useSelector((state) => state.courseupdate);
  const courseInfo = addcourse?.firstform;
  const { price, onUpdateData, feeData, onFetchCourseData } = useFeeStructure();
  const [loading, setLoading] = useState(false);

  const isFree = feeData?.is_course_free || feeData?.is_free || feeData?.is_cohort;

  console.log('Course Detail:', feeData);

  useEffect(() => {
    onFetchCourseData();
  }, []);

  const handleNext = async () => {
    dispatch(changesection(3));
    // try {
    //   if (courseInfo?.price !== price && !courseInfo.is_course_free && !(price == '' || price == 0)) {
    //     setLoading(true);
    //     await onUpdateData({ price });
    //     dispatch(changesection(3));
    //     setLoading(false);
    //   } else if (!courseInfo?.is_course_free && (price == '' || price == 0)) {
    //     return enqueueSnackbar('This course is not free. Please enter valid price to continue', { variant: 'error' });
    //   } else {
    //     dispatch(changesection(3));
    //   }
    // } catch (e) {
    //   setLoading(false);
    // }
    // handleSubmit();
  };
  const handleBack = () => {
    dispatch(changesection(1));
  };

  function isDisabledNextBecauseOfPrice() {
    if (courseInfo?.is_course_free) {
      return false;
    } else if (Number(price) !== Number(courseInfo?.price) && courseInfo?.has_price_breakup) {
      return true;
    }
  }

  return (
    <>
      <Page title="Program: Media & Price">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={isFree ? 12 : 8} lg={isFree ? 12 : 8} spacing={1}>
            <CustomScore accessibleRoles={accessibleRoles} />
            {!addcourse?.firstform?.is_course_free && !addcourse?.firstform?.is_cohort && <PaymentPlan accessibleRoles={accessibleRoles} />}
            <UserDocument accessibleRoles={accessibleRoles} />
          </Grid>
          {!isFree && (
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <FeeStructure accessibleRoles={accessibleRoles} />
            </Grid>
          )}
        </Grid>

        <RoleBasedGuard accessibleRoles={accessibleRoles} noPermissionChildren={<></>}>
          <Stack sx={{ mt: 3 }} direction="row" justifyContent="space-between">
            <Stack item xs={12} md={3}>
              <Button variant="contained" onClick={() => handleBack()} size="large">
                Back
              </Button>
            </Stack>
            <Stack item xs={12} md={3}>
              <LoadingButton
                loading={loading}
                variant="contained"
                onClick={() => handleNext()}
                disabled={configuration?.customweightage?.status === 'pending'}
                size="large"
              >
                Next
              </LoadingButton>
            </Stack>
          </Stack>
        </RoleBasedGuard>
      </Page>
    </>
  );
};

export default ConfigurationPage;
