import { createSlice } from "@reduxjs/toolkit";
import axios from "../../../src/utils/axios";
import {
  ACCOUNT_TYPE,
  FUND_PAYOUT,
  FUND_PAYOUT_ACCOUNT,
  FUND_PAYOUT_AMOUNT,
  FUND_STATEMENT,
  FUND_STATEMENT_HIGHLIGHT,
  QUIZ_TRACK,
} from "src/apiUrl";
// utils
//
import { dispatch } from "../store";
import { eduleyApi } from "src/api/apiConfig";

const initialState = {
  isLoading: false,
  statement: {
    transactions: {},
    heading: {},
    error: null,
  },
  payout: {
    amount: {},
    transactions: {},
    account: {},
    accountreq: {},
    error: null,
    accountType: {},
  },

  error: null,
};

const slice = createSlice({
  name: "fund_management",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getStatementSuccess(state, action) {
      state.isLoading = false;
      const statement = action.payload;
      state.statement.transactions = statement;
    },
    getStatementHeadSuccess(state, action) {
      state.isLoading = false;
      const statement = action.payload;
      state.statement.heading = statement;
    },
    setAmountSuccess(state, action) {
      state.isLoading = false;
      const statement = action.payload;
      state.payout.amount = statement;
    },
    getPayoutSuccess(state, action) {
      state.isLoading = false;
      const payout = action.payload;
      state.payout.transactions = payout;
    },
    getPayoutAccountSuccess(state, action) {
      state.isLoading = false;
      const payout = action.payload;
      state.payout.account = payout;
    },
    getPayoutAccountSuccessforreq(state, action) {
      state.isLoading = false;
      const payout = action.payload;
      state.payout.accountreq = payout;
    },
    setAccountType(state, action) {
      state.payout.accountType = action.payload;
    },
  },
});
export default slice.reducer;

// Actions
export const { actions } = slice;

// ----------------------------------------------------------------------

const accessToken = localStorage.getItem("accessToken");

const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${accessToken}`,
  },
};
const getStatus = (status) => {
  console.log("status", status);

  switch (status?.status) {
    case 401:
      window.location.href = "/auth/login";
      return "Unauthorized";
    default:
      return "";
  }
};
export const getFundStatemantlist = (query) => async (dispatch) => {
  dispatch(actions.startLoading());
  try {
    const res = await eduleyApi.get(`${FUND_STATEMENT}${query}`);
    console.log("res", res);
    dispatch(actions.getStatementSuccess(res.data));
  } catch (error) {
    getStatus(error.response);
    dispatch(actions.hasError(error.response.data));
  }
};

export const getFundStatemantheading = () => async (dispatch) => {
  dispatch(actions.startLoading());
  try {
    const res = await eduleyApi.get(`${FUND_STATEMENT_HIGHLIGHT}`);
    console.log("res", res);
    dispatch(actions.getStatementHeadSuccess(res.data));
  } catch (error) {
    getStatus(error.response);
    dispatch(actions.hasError(error.response.data));
  }
};
export const getFundPayoutlist = (query) => async (dispatch) => {
  dispatch(actions.startLoading());
  try {
    const res = await eduleyApi.get(`${FUND_PAYOUT}${query}`);
    console.log("res", res);
    dispatch(actions.getPayoutSuccess(res.data));
  } catch (error) {
    getStatus(error.response);
    dispatch(actions.hasError(error?.response?.data));
  }
};
// Reducer

export const getFundPayoutAccount = (query) => async (dispatch) => {
  dispatch(actions.startLoading());
  try {
    const res = await eduleyApi.get(`${FUND_PAYOUT_ACCOUNT}${query}`);
    console.log("res", res);
    dispatch(actions.getPayoutAccountSuccess(res.data));
  } catch (error) {
    getStatus(error.response);
    dispatch(actions.hasError(error.response.data));
  }
};

export const getPayoutAccountforrequest = (query) => async (dispatch) => {
  dispatch(actions.startLoading());
  try {
    const res = await eduleyApi.get(`${FUND_PAYOUT_ACCOUNT}${query}`);
    console.log("res", res);
    dispatch(actions.getPayoutAccountSuccessforreq(res.data));
  } catch (error) {
    getStatus(error.response);
    dispatch(actions.hasError(error.response.data));
  }
};

export const setFundPayout = (data) => async (dispatch) => {
  try {
    const res = await axios.post(`${FUND_PAYOUT}`, data, config);
    console.log("res", res);
    return "success";
  } catch (error) {
    getStatus(error.response);
    // dispatch(actions.hasError(error.response.data));
    return error.response.data;
  }
};

export const addPayoutAccount = (data) => async (dispatch) => {
  try {
    const res = await axios.post(`${FUND_PAYOUT_ACCOUNT}`, data, config);
    console.log("res", res);
    return "success";
  } catch (error) {
    getStatus(error.response);
    // dispatch(actions.hasError(error.response.data));
    return error.response.data;
  }
};

export const editPayoutAccount = (data) => async (dispatch) => {
  let d = { ...data };
  delete d.is_active;
  try {
    const res = await axios.patch(
      `${FUND_PAYOUT_ACCOUNT}${data.id}/`,
      d,
      config
    );
    console.log("res", res);
    return "success";
  } catch (error) {
    getStatus(error.response);
    // dispatch(actions.hasError(error.response.data));
    return error.response.data;
  }
};
export const getFundPayoutAmount = (data) => async (dispatch) => {
  try {
    const res = await eduleyApi.get(`${FUND_PAYOUT_AMOUNT}`);
    console.log("res", res);
    dispatch(actions.setAmountSuccess(res.data));
    return "success";
  } catch (error) {
    console.log("error", error.response);
    // getStatus(error.response);
    // dispatch(actions.hasError(error.response.data));
    return error.response;
  }
};

export const getpayoutaccountype = () => async (dispatch) => {
  try {
    const res = await eduleyApi.get(`${ACCOUNT_TYPE}`);
    dispatch(actions.setAccountType(res.data));
    return "success";
  } catch (error) {
    return error.response.data;
  }
};
