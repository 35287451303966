import React, { useState } from 'react';
import MediaForm from './MediaForm';
import MediaList from './MediaList';

function MediaSection({ selectedMedia }) {
  const [media, setMedia] = React.useState({ data: {}, open: false });
  const [currentmediatype, setCurrentMediatype] = useState('');
  const openForm = (data) => {
    setMedia({ data: data, open: true });
  };

  return media.open ? (
    <MediaForm openForm={(p) => setMedia(p)} data={media.data} />
  ) : (
    <MediaList openForm={(p) => openForm(p)} selectedMedia={(p) => selectedMedia(p)} />
  );
}

export default MediaSection;
