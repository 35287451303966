import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { sentenceCase } from 'change-case';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { eduleyApi } from 'src/api/apiConfig';
import Iconify from 'src/components/Iconify';
import { calculateDuration, formatTime } from 'src/utils/utils';
import * as yup from 'yup';
import SpecialEventConfirmation from './SpecialEventConfirmation';

const isValidTimeForToday = (startTime) => {
  const now = dayjs();
  const selectedTime = dayjs(startTime);

  // If the start time is before the current time + 30 minutes, it's invalid
  return selectedTime.isAfter(now.add(30, 'minute'));
};

// Updated schema
const specialEventSchema = yup.object().shape({
  eventType: yup.string().required('Event type is required'),
  date: yup.string().required('Date is required'),
  startTime: yup
    .string()
    .nullable()
    .when('eventType', {
      is: (value) => value !== 'holiday' && value !== 'canceled',
      then: yup
        .string()
        .required('Start time is required')
        .nullable()
        .test('isValidTime', 'Start time must be a valid time in HH:mm format', (value) => (value ? dayjs(value, true).isValid() : false))
        .test('isAfterEndTime', 'Start time must be before end time', function (value) {
          const { endTime } = this.parent;

          if (!endTime || !value) return true;
          const start = dayjs(value);
          const end = dayjs(endTime);
          return start.isBefore(end);
        })
        .test('isNotPastOrNearTime', 'Start time must be at least 30 minutes from now if the event is scheduled for today.', function (value) {
          const { date } = this.parent;
          const today = dayjs().startOf('day');

          if (dayjs(date).isSame(today, 'day')) {
            return isValidTimeForToday(value);
          }
          return true;
        }),
      otherwise: yup.string().nullable()
    }),
  endTime: yup
    .string()
    .nullable()
    .when('eventType', {
      is: (value) => value !== 'holiday' && value !== 'canceled',
      then: yup
        .string()
        .required('End time is required')
        .nullable()
        .test('isValidTime', 'End time must be a valid time in HH:mm format', (value) => (value ? dayjs(value, true).isValid() : false))
        .test('isAfterStartTime', 'End time must be after start time', function (value) {
          const { startTime } = this.parent;
          if (!startTime || !value) return true;
          const start = dayjs(startTime);
          const end = dayjs(value);
          return end.isAfter(start);
        }),
      otherwise: yup.string().nullable()
    }),
  remarks: yup.string().nullable(),
  attendanceNeeded: yup.boolean().nullable()
});

const getSuccessMessage = (eventType, date) => {
  const formattedDate = dayjs(date).format('ddd, MMM DD');

  switch (eventType) {
    case 'canceled':
      return {
        add: `The Class on ${formattedDate} has been canceled.`,
        update: `The Class on ${formattedDate} has been updated.`,
        delete: `The Class on ${formattedDate} has been deleted.`
      };
    case 'holiday':
      return {
        add: `Holiday marked successfully on ${formattedDate}.`,
        update: `The Holiday on ${formattedDate} has been updated.`,
        delete: `The Holiday on ${formattedDate} has been deleted.`
      };
    case 'orientation':
      return {
        add: `Orientation marked successfully on ${formattedDate}.`,
        update: `The Orientation on ${formattedDate} has been updated.`,
        delete: `The Orientation on ${formattedDate} has been deleted.`
      };
    case 'graduation':
      return {
        add: `Graduation Day marked successfully on ${formattedDate}.`,
        update: `The Graduation on ${formattedDate} has been updated.`,
        delete: `The Graduation on ${formattedDate} has been deleted.`
      };
    case 'special_class':
      return {
        add: `Special Class marked successfully on ${formattedDate}.`,
        update: `The Special Class on ${formattedDate} has been updated.`,
        delete: `The Special Class on ${formattedDate} has been deleted.`
      };
    default:
      return {
        add: `Special Event added on ${formattedDate}.`,
        update: `The Special Event on ${formattedDate} has been updated.`,
        delete: `The Special Event on ${formattedDate} has been deleted.`
      };
  }
};

export const getClass = (eventType) => {
  switch (eventType) {
    case 'canceled':
      return 'Class Cancellation';
    case 'holiday':
      return 'Holiday';
    case 'orientation':
      return 'Orientation Day';
    case 'graduation':
      return 'Graduation Day';
    case 'special_class':
      return 'Special Class';
    default:
      return null;
  }
};

const SpecialEvents = ({ courseInfo }) => {
  const [specialEvents, setSpecialEvents] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [addEventOpen, setAddEventOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    trigger,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(specialEventSchema)
  });

  // for date calculations
  const today = dayjs();
  const minTime = today.add(30, 'minute');

  const selectedDate = watch('date');
  const isToday = selectedDate && dayjs(selectedDate).isSame(dayjs(), 'day');

  const eventCount = specialEvents.length;

  const getSpecialEvents = async () => {
    try {
      const response = await eduleyApi.get(`/course/admin/course/cohort/cohort-event?cohort=${courseInfo.id}`);
      setSpecialEvents(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSpecialEvents();
  }, [courseInfo]);

  //   handle edit
  const handleEdit = (event) => {
    setValue('eventType', event.event);
    setValue('date', event.date);
    setValue('startTime', event.start_time ? dayjs(event.start_time, 'HH:mm:ss') : null);
    setValue('endTime', event.end_time ? dayjs(event.end_time, 'HH:mm:ss') : null);
    setValue('remarks', event.remarks);
    setValue('attendanceNeeded', event.attendance_needed);
    setSelectedEvent(event);
    setAddEventOpen(true);
  };

  const onClose = () => {
    setAddEventOpen(false);
    reset();
  };

  const isHoliday = watch('eventType') === 'holiday' || watch('eventType') === 'canceled';

  // handle special event creation
  const handleSpecialEvent = async (data) => {
    setLoading(true);

    const payload = {
      event: data.eventType,
      date: data.date ? dayjs(data.date).format('YYYY-MM-DD') : null,
      remarks: data.remarks,
      cohort: courseInfo.id,
      attendance_needed: data.attendanceNeeded || false
    };
    if (data.eventType !== 'canceled' && data.eventType !== 'holiday') {
      payload.start_time = data.startTime ? dayjs(data.startTime).format('HH:mm:ss') : null;
      payload.end_time = data.endTime ? dayjs(data.endTime).format('HH:mm:ss') : null;
    }

    try {
      if (selectedEvent) {
        await eduleyApi.put(`/course/admin/course/cohort/cohort-event/${selectedEvent.id}/`, payload);
        const message = getSuccessMessage(data.eventType, data.date).update;
        enqueueSnackbar(message ?? 'Special event updated successfully', { variant: 'success' });
      } else {
        await eduleyApi.post(`/course/admin/course/cohort/cohort-event/`, {
          ...payload,
          cohort: courseInfo.id
        });
        const message = getSuccessMessage(data.eventType, data.date).add;
        enqueueSnackbar(message ?? 'Special event added successfully', { variant: 'success' });
      }
      await getSpecialEvents();
      setAddEventOpen(false);
      reset();
      setSelectedEvent(null);
      setIsDialogOpen(true);
      setIsConfirmationOpen(false);
    } catch (error) {
      const message = error?.data?.data?.non_field_errors?.[0] || 'An error occurred';
      enqueueSnackbar(message, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  // handle delete event
  const handleDelete = async () => {
    setLoading(true);
    try {
      await eduleyApi.delete(`course/admin/course/cohort/cohort-event/${selectedEvent.id}`);
      const message = getSuccessMessage(selectedEvent.event, selectedEvent.date).delete;
      enqueueSnackbar(message ?? 'Special event deleted successfully', { variant: 'success' });
    } catch (error) {
      const message = error?.data?.data?.non_field_errors[0] || 'An error occurred';
      enqueueSnackbar(message, { variant: 'error' });
    } finally {
      setLoading(false);
      setDeleteDialogOpen(false);
      setSelectedEvent(null);
      await getSpecialEvents();
    }
  };

  return (
    <>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          mt: 2
        }}
      >
        {eventCount > 0 && (
          <Button
            variant="outlined"
            disableRipple
            sx={{
              width: 'fit-content'
            }}
            onClick={() => {
              if (eventCount > 0) {
                setIsDialogOpen(true);
              } else {
                setAddEventOpen(true);
              }
            }}
          >
            {eventCount > 0 && (
              <p
                style={{
                  position: 'absolute',
                  top: '-8px',
                  right: '-13px',
                  backgroundColor: '#90ac9c',
                  color: 'white',
                  borderRadius: '50%',
                  padding: '5px',
                  fontSize: '12px',
                  fontWeight: 'bold',
                  width: '20px',
                  height: '20px',
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                {eventCount}
              </p>
            )}
            {'Special Events'}
          </Button>
        )}
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            setSelectedEvent(null);
            setAddEventOpen(true);
          }}
          sx={{
            mt: 2
          }}
        >
          Add Special Event
        </Button>
      </Stack>
      <Dialog
        fullWidth
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '800px'
            }
          }
        }}
        onClose={() => setIsDialogOpen(false)}
        open={isDialogOpen}
      >
        <DialogTitle>Schedule Special Events</DialogTitle>
        <DialogContent>
          {specialEvents.length > 0 ? (
            <Stack direction="column" spacing={1} sx={{ mt: 2, mb: 3 }}>
              {specialEvents.map((event) => (
                <Stack key={event.id} direction="row" justifyContent="space-between" spacing={4}>
                  <Stack key={event.id} direction="row" alignItems="center" spacing={1}>
                    <Typography
                      sx={{
                        width: '160px'
                      }}
                      variant="subtitle1"
                    >
                      {getClass(event?.event)}
                    </Typography>
                    <Typography variant="body">
                      {event.event !== 'holiday' && event.event !== 'canceled'
                        ? `${dayjs(event?.date).format('ddd, MMM DD')} - ${formatTime(event?.start_time)} to ${formatTime(
                            event?.end_time
                          )} - ${calculateDuration(event?.start_time, event?.end_time)}`
                        : `${dayjs(event?.date).format('ddd, MMM DD')}`}
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Button
                      variant="text"
                      color="primary"
                      sx={{
                        minWidth: '32px'
                      }}
                      onClick={() => {
                        handleEdit(event);
                      }}
                    >
                      <Iconify icon={'material-symbols:ink-pen'} width={20} height={20} />
                    </Button>
                    <Button
                      sx={{
                        minWidth: '32px'
                      }}
                      variant="text"
                      color="primary"
                      onClick={() => {
                        setSelectedEvent(event);
                        setDeleteDialogOpen(true);
                      }}
                    >
                      <Iconify icon={'material-symbols:delete-outline-rounded'} width={20} height={20} />
                    </Button>
                  </Stack>
                </Stack>
              ))}
            </Stack>
          ) : (
            <Typography variant="body1" color="textSecondary" sx={{ mt: 4 }}>
              No special events scheduled
            </Typography>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setSelectedEvent(null);
              setAddEventOpen(true);
            }}
            sx={{
              mt: 2
            }}
          >
            Add Special Event
          </Button>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setIsDialogOpen(false);
            }}
          >
            Close
          </Button>
          {/* <Button variant="contained" color="primary" type="submit">
            Save
          </Button> */}
        </DialogActions>
      </Dialog>
      <Dialog maxWidth="lg" onClose={onClose} open={addEventOpen}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DialogTitle>{selectedEvent ? 'Edit Special Event' : 'Add Special Event'}</DialogTitle>
          <DialogContent
            sx={{
              width: 560
            }}
          >
            <form>
              <Stack direction="column" spacing={3} sx={{ mt: 4, mb: 3 }}>
                <Stack direction="row" spacing={2}>
                  <FormControl fullWidth>
                    <InputLabel id="event-type">Event Type*</InputLabel>

                    <Select
                      label="Event Type*"
                      placeholder="Event Type*"
                      variant="outlined"
                      onChange={(e) => {
                        setValue('eventType', e.target.value, {
                          shouldValidate: true
                        });
                        if (e.target.value === 'holiday' || e.target.value === 'canceled') {
                          setValue('startTime', null);
                          setValue('endTime', null);
                        }
                      }}
                      disabled={selectedEvent}
                      value={watch('eventType')}
                      error={Boolean(errors.eventType)}
                      helperText={errors.eventType?.message}
                    >
                      <MenuItem value="graduation">Graduation</MenuItem>
                      <MenuItem value="orientation">Orientation</MenuItem>
                      <MenuItem value="special_class">Special Class</MenuItem>
                      <MenuItem value="canceled">Cancellation</MenuItem>
                      <MenuItem value="holiday">Holiday</MenuItem>
                    </Select>
                    <FormHelperText>{errors.eventType?.message} </FormHelperText>
                  </FormControl>
                  <FormControl fullWidth error={Boolean(errors.date)}>
                    <DatePicker
                      id="date-picker"
                      fullWidth
                      label="Date*"
                      type="date"
                      onChange={(date) =>
                        setValue('date', date, {
                          shouldValidate: true
                        })
                      }
                      value={watch('date') ? dayjs(watch('date')) : null}
                      helperText={errors.date?.message}
                      minDate={dayjs(courseInfo.start_date).isBefore(dayjs(), 'day') ? dayjs() : dayjs(courseInfo.start_date)}
                      maxDate={dayjs(courseInfo.end_date)}
                    />
                    <FormHelperText
                      FormHelperTextProps={{
                        sx: { color: 'red', fontSize: '14px' }
                      }}
                    >
                      {errors.date?.message}
                    </FormHelperText>
                  </FormControl>
                </Stack>
                {!isHoliday && (
                  <Stack>
                    <Stack direction="row" spacing={2}>
                      <FormControl fullWidth error={Boolean(errors.startTime)}>
                        <TimePicker
                          fullWidth
                          label="Start Time"
                          type="time"
                          onChange={(time) => {
                            setValue('startTime', time, {
                              shouldValidate: true
                            });
                            trigger('endTime');
                          }}
                          value={watch('startTime') ? watch('startTime') : null}
                          maxTime={watch('endTime') ? watch('endTime') : null}
                          minTime={isToday ? minTime : dayjs('00:00', 'HH:mm')}
                        />
                        <FormHelperText>{errors.startTime?.message} </FormHelperText>
                      </FormControl>

                      <FormControl fullWidth error={true}>
                        <TimePicker
                          fullWidth
                          label="End Time"
                          type="time"
                          onChange={(time) => {
                            setValue('endTime', time, {
                              shouldValidate: true
                            });
                            trigger('startTime');
                          }}
                          value={watch('endTime') ? watch('endTime') : null}
                          minTime={watch('startTime') ? watch('startTime') : null}
                        />
                        <FormHelperText>{errors.endTime?.message}</FormHelperText>
                      </FormControl>
                    </Stack>
                    {calculateDuration(watch('startTime'), watch('endTime')) && (
                      <Typography variant="caption" color="textSecondary">
                        Duration: {calculateDuration(watch('startTime'), watch('endTime'))}
                      </Typography>
                    )}
                  </Stack>
                )}
                <TextField fullWidth label="Remarks" {...register('remarks')} error={Boolean(errors.remarks)} helperText={errors.remarks?.message} />
                {!isHoliday && (
                  <FormControlLabel
                    control={<Checkbox checked={watch('attendanceNeeded')} onChange={(e) => setValue('attendanceNeeded', e.target.checked)} />}
                    label="Attendance Needed"
                    sx={{ mt: 1 }}
                  />
                )}
              </Stack>
            </form>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" color="primary" onClick={onClose} disabled={loading}>
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              type="submit"
              onClick={async () => {
                const isValid = await trigger();
                if (isValid) {
                  setIsConfirmationOpen(true);
                }
              }}
            >
              Save
            </LoadingButton>
          </DialogActions>
        </LocalizationProvider>
      </Dialog>
      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Delete Special Event</DialogTitle>
        <DialogContent
          sx={{
            pt: '10px !important',
            pb: '10px !important'
          }}
        >
          <Typography variant="body1">{`Are you sure you want to delete ${getClass(selectedEvent?.event)}`}</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={() => setDeleteDialogOpen(false)}>
            Cancel
          </Button>
          <LoadingButton variant="contained" color="primary" onClick={handleDelete} loading={loading}>
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <SpecialEventConfirmation
        confirmation={handleSubmit(handleSpecialEvent)}
        courseName={courseInfo.name}
        eventDate={watch('date') ? dayjs(watch('date')).format('ddd, MMM DD') : ''}
        eventName={sentenceCase(watch('eventType') ?? '')}
        eventType={watch('eventType') ?? ''}
        open={isConfirmationOpen}
        loading={loading}
        setOpen={setIsConfirmationOpen}
        isAttendanceNeeded={watch('attendanceNeeded')}
      />
    </>
  );
};

export default SpecialEvents;
