import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
// import { useNavigate } from "react-router-dom";
import { useEffect, useMemo, useState } from 'react';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import {
  Card,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
// components
import _ from 'lodash';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { FormProvider, RHFEditor, RHFSelect, RHFTextField } from 'src/components/hook-form';
import Iconify from 'src/components/Iconify';
import Page from 'src/components/Page';
import RoleBasedGuard from 'src/guards/RoleBasedGuard';
import useAuth from 'src/hooks/useAuth';
import { createcourse, editcourse, getcategory, getlanguagelist } from 'src/redux/slices/courseupdate';
import { useDispatch, useSelector } from 'src/redux/store';
import AiImplemention from 'src/sections/@dashboard/course/addcourse/component/AiImplemention';
import { InstructorModal } from '../../instructor';
import BasicView from '../courseView/BasicView';
import CohortModal from './component/CohortModal';
import ConfirmationPopup from './component/ConfirmationPopup';
import ReviewerlistModal from './component/RevierlistModal';
// import {InstructorModal} from "src/sections/@dashboard//InstructorModal";
// ----------------------------------------------------------------------

const COURSE_LEVEL = [
  { name: 'Beginner', id: 'beginner' },
  { name: 'Intermediate', id: 'intermediate' },
  { name: 'Expert', id: 'expert' }
];

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1)
}));

// ----------------------------------------------------------------------

export default function BasicForm({ isEdit = false, accessibleRoles = [] }) {
  const dispatch = useDispatch();
  const { addcourse, categorylist = [], languagelist = [], loadingtoform } = useSelector((state) => state.courseupdate);
  const [currencylists, setcurrencyLists] = useState([]);
  const [showConformation, setShowConformation] = useState({
    show: false,
    confirm: false
  });
  const { firstform } = addcourse;

  console.log();
  // console.log(category);
  const { enqueueSnackbar } = useSnackbar();
  const [instructor, setInstructor] = useState('');
  const [categorys, setcategorys] = useState([]);
  const [reviewers, setReviewers] = useState([]);
  const [cohortAlert, setCohortAlert] = useState(false);
  const [data, setData] = useState({
    offer_first_section_free: true,
    is_cohort: false
  });

  useEffect(() => {
    if (addcourse?.firstform?.course_type) {
      setCourseType(addcourse?.firstform?.course_type);
    }
  }, [addcourse?.firstform?.course_type]);

  const [languages, setlanguagess] = useState([]);
  const [currency, setCurrency] = useState('');
  const { instituteinfo } = useAuth();
  const [courseType, setCourseType] = useState('fulltime');
  useEffect(() => {
    if (instituteinfo) {
      setcurrencyLists([instituteinfo?.default_currency]);
      // setCurrency(instituteinfo?.default_currency);
    }
  }, [instituteinfo]);
  useEffect(() => {
    if (firstform.instructor) {
      setInstructor(firstform.instructor);
    }
    if (firstform.reviewers) {
      setReviewers(firstform.reviewers);
    }
    if (firstform) {
      setData({ ...data, ...firstform });
    }
    setCurrency(firstform.price_currency);
  }, [firstform.instructor, firstform.reviewers, firstform]);
  useEffect(() => {
    if (categorylist.length > 0) {
      let categorys = [{ name: 'Select Category', id: '' }, ...categorylist];
      setcategorys(categorys);
    }
    if (languagelist.length > 0) {
      let languagess = [{ display_name: 'Select Language', id: '' }, ...languagelist];
      setlanguagess(languagess);
    }
  }, [categorylist, languagelist]);

  const NewCourseSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    long_description: Yup.string().required('Description is required'),
    short_description: Yup.string().max(200, 'Short description must be at most 200 characters').required('Short description is required'),
    what_will_student_learn: Yup.string().required('this is a required field'),
    prerequisite_for_course: Yup.string().required('this is a required field'),
    course_level: Yup.string().required('Program level is required'),
    course_language: Yup.string().required('Audio language is required'),
    // category: Yup.string().required("Category is required"),
    duration_in_weeks: Yup.number().required('Program duration is required')

    // instructor: Yup.string().required("Instructor is required"),
  });

  const defaultValues = useMemo(
    () => ({
      name: firstform?.name || '',
      long_description: firstform?.long_description || '',
      short_description: firstform?.short_description || '',
      what_will_student_learn: firstform?.what_will_student_learn || '',
      prerequisite_for_course: firstform?.prerequisite_for_course || '',
      course_level: firstform?.course_level || COURSE_LEVEL[0].id,
      course_language: firstform?.course_language || languages[0],
      category: firstform?.category || categorys[0]?.id,
      duration_in_weeks: firstform?.duration_in_weeks || '',
      cohort_attendance: firstform?.enable_cohort_attendance || false,
      enable_student_document: false
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [firstform]
  );

  const methods = useForm({
    resolver: yupResolver(NewCourseSchema),
    defaultValues
  });

  const {
    reset,
    watch,
    handleSubmit,
    setValue,
    formState: { isSubmitting }
  } = methods;

  const values = watch();

  useEffect(() => {
    if (isEdit && firstform) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }

    dispatch(getcategory());

    dispatch(getlanguagelist());
  }, [isEdit, firstform]);

  const onSubmit = async () => {
    // console.log(firstform);
    setShowConformation({
      show: false
    });
    if (instructor === '') {
      enqueueSnackbar('Instructor is required', {
        variant: 'error'
      });
      return;
    }
    let commonValues = {
      instructor: instructor,
      is_cohort: data.is_cohort,
      reviewers: reviewers,
      cohort_attendance: data?.cohort_attendance,
      enable_certificate: data.enable_certificate,
      access_post_graduation_in_weeks: data.access_post_graduation_in_weeks,
      price_currency: instituteinfo?.default_currency?.id,
      is_course_free: data.is_course_free,
      offer_first_section_free: data.offer_first_section_free,
      price: data.price
    };
    if (firstform.id === undefined || firstform.id === null) {
      console.log('create');

      dispatch(
        createcourse({
          ...values,
          instructor: instructor,
          is_cohort: false,
          reviewers: reviewers,
          enable_certificate: data.enable_certificate,
          access_post_graduation_in_weeks: data.access_post_graduation_in_weeks,
          price_currency: instituteinfo?.default_currency?.id,
          is_course_free: data.is_course_free,
          offer_first_section_free: data.offer_first_section_free,
          cohort_attendance: data?.cohort_attendance,
          price: data.price,
          payment_plans: ['full_payment'],
          is_cohort: data?.is_cohort,
          course_type: courseType
        })
      );
    } else {
      console.log('edit');
      if (firstform.status == 'live') {
        if (data.offer_first_section_free) {
          if (data.section.length <= 1) {
            enqueueSnackbar(
              "Free trial program should have minimum 2 sections in the curriculum to offer the first section as free. Please un-check 'Offer the first section of the course as free' field to move to next step.",
              { variant: 'error' }
            );
            return;
          }
        }
      }
      let f = {
        ...firstform,
        ...values,
        instructor: instructor,
        reviewers: reviewers,
        enable_certificate: data.enable_certificate,
        access_post_graduation_in_weeks: data.access_post_graduation_in_weeks,
        price_currency: instituteinfo?.default_currency?.id,
        is_course_free: data.is_course_free,
        offer_first_section_free: data.offer_first_section_free,
        cohort_attendance: data?.cohort_attendance,
        price: data.price,
        is_cohort: data?.is_cohort,
        course_type: courseType
      };
      dispatch(editcourse(f));
    }
  };
  const checkConfirmation = () => {
    let info = [];
    // console.log(
    //   "first",
    //   +data.price,
    //   +firstform.price,
    //   +data?.duration_in_weeks,
    //   +firstform?.duration_in_weeks,
    //   firstform.payment_plans?.includes("payment_plan"),
    // );
    if (!_.isEmpty(firstform.id)) {
      if (
        (+data.price != +firstform.price || +values?.duration_in_weeks != +firstform?.duration_in_weeks) &&
        firstform.payment_plans?.includes('payment_plan') &&
        !addcourse?.firstform?.is_cohort
      ) {
        // console.log(
        //   "first",
        //   +data.price != +firstform.price,
        //   +data.price,
        //   +firstform.price
        // );
        if (+data.price != +firstform.price) {
          info.push({
            label: 'Program price',
            icon: 'mdi:currency-usd'
          });
        }
        if (+values?.duration_in_weeks != +firstform?.duration_in_weeks) {
          info.push({
            label: 'Program duration',
            icon: 'mdi:calendar-month'
          });
        }
        setShowConformation({
          show: true,
          info: info
        });
      } else {
        onSubmit();
      }
    } else {
      onSubmit();
    }
  };
  const cancelConformation = () => {
    setShowConformation({
      show: false
    });
  };
  const handleOpenCohortAlert = () => {
    setCohortAlert(true);
  };
  const handleCloseCohortAlert = () => {
    setCohortAlert(false);
  };
  const handleDoneByCohortAlert = () => {
    setCohortAlert(false);
    setData({ ...data, is_cohort: false });
  };
  const handleChangeCohortToggle = (e, v) => {
    if (!data.is_cohort) {
      setData({ ...data, is_cohort: !data.is_cohort });
    } else {
      setCohortAlert(true);
    }
  };
  const handleAiDescription = (data) => {
    setValue('long_description', data.replace(/\n/g, '<br>'));
  };
  const courseViewInformation = useMemo(
    () => ({
      ...data,
      category: categorylist?.find((c) => c.id == data.category)?.name
    }),
    [data, categorylist]
  );

  return (
    <RoleBasedGuard accessibleRoles={accessibleRoles} noPermissionChildren={<BasicView data={courseViewInformation} loading={loadingtoform} />}>
      <Page title="Basic Information" sx={{ height: 1 }}>
        <Container maxWidth={false} sx={{ height: 1 }}>
          <HeaderBreadcrumbs
            heading="Basic Information"
            links={[
              {
                name: 'Basic Information'
                //   href: PATH_DASHBOARD.root,
              }
              // { name: 'Kanban' },
            ]}
          />
          <FormProvider methods={methods} onSubmit={handleSubmit(checkConfirmation)}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Card sx={{ p: 3 }}>
                  <Stack spacing={3}>
                    <div>
                      <LabelStyle>Program Name *</LabelStyle>
                      <RHFTextField name="name" required />
                    </div>

                    <div>
                      <LabelStyle>Short Description *</LabelStyle>
                      <RHFTextField simple name="short_description" />
                    </div>

                    <div>
                      <Stack direction="row" spacing={2} justifyContent={'space-between'}>
                        <LabelStyle>Program Description</LabelStyle>
                        {values?.name?.length >= 2 && <AiImplemention data={{ ...data, ...values }} handleResponse={handleAiDescription} />}
                      </Stack>
                      <RHFEditor simple name="long_description" />
                    </div>
                  </Stack>
                  <Grid container spacing={3} sx={{ mb: 1, mt: 1 }}>
                    <Grid item xs={12} md={6}>
                      <LabelStyle>What will students learn in your program ?</LabelStyle>
                      <RHFTextField simple name="what_will_student_learn" multiline rows={3} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <LabelStyle>
                        Pre-requisites
                        {/* Requirements for students to complete your course ? * */}
                      </LabelStyle>
                      <RHFTextField simple name="prerequisite_for_course" multiline rows={3} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} sx={{ mb: 1, mt: 1 }}>
                    <Grid item xs={12} md={6}>
                      <RHFSelect name="course_level" label="Program Level" required>
                        {COURSE_LEVEL.map((category, i) => (
                          <option key={'cokk' + i} value={category.id}>
                            {category.name}
                          </option>
                        ))}
                      </RHFSelect>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <RHFSelect
                        name="course_language"
                        required
                        label="Program Language"
                        InputLabelProps={{
                          shrink: true
                        }}
                      >
                        {languages.map((classify, I) => (
                          <option key={'LA' + I} value={classify.id}>
                            {classify.display_name}
                          </option>
                        ))}
                      </RHFSelect>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} sx={{ mb: 1, mt: 1 }}>
                    <Grid item xs={12} md={6}>
                      <Stack direction={'row'} spacing={2}>
                        <LabelStyle>Program Duration in weeks *</LabelStyle>
                        <TooltipMessage
                          sx={{
                            mt: -2
                          }}
                          message={
                            'After the first time setup of program, reducing the duration of the program will disable the payment plan (if enabled) and the payment plan will need to be reconfigured.'
                          }
                        />
                      </Stack>
                      <RHFTextField
                        name="duration_in_weeks"
                        // label="Course Duration in weeks"
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <LabelStyle>Instructor *</LabelStyle>
                      <InstructorModal
                        id={instructor}
                        changeInstructor={(p) => {
                          setInstructor(p.id);
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} sx={{ mb: 1, mt: 1 }}>
                    <Grid item xs={12} md={6}>
                      <Stack direction={'row'}>
                        <LabelStyle>Add Co-Instructor</LabelStyle>
                        <Tooltip
                          describeChild
                          arrow
                          title={
                            'The users selected will be able to review the program’s assignments and quiz with subjective questions. Atleast 1 reviewer should be selected for the program.'
                          }
                        >
                          <IconButton sx={{ mt: -1 }}>
                            <Iconify icon="ant-design:exclamation-circle-twotone" />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                      <ReviewerlistModal allreviewer={reviewers} instructor={instructor} handlereviewer={(p) => setReviewers(p)} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <LabelStyle>Program Category </LabelStyle>
                      <RHFSelect
                        name="category"
                        // required
                        // label="Course Category"
                        InputLabelProps={{
                          shrink: true
                        }}
                      >
                        {categorys.map((classify, i) => (
                          <>
                            <option key={'ca' + i} value={classify.id}>
                              {classify.name}
                            </option>
                          </>
                        ))}
                      </RHFSelect>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} sx={{ mb: 1, mt: 1 }}>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      // sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Stack direction={'row'}>
                        <LabelStyle>Access post graduation in weeks *</LabelStyle>

                        <Tooltip
                          describeChild
                          arrow
                          title="View only access of the course content to be allowed to student after graduation. If it’s 0 then student will not have any view access."
                        >
                          <IconButton sx={{ mt: -1 }}>
                            <Iconify icon="ant-design:exclamation-circle-twotone" />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                      <TextField
                        required
                        fullWidth
                        value={data?.access_post_graduation_in_weeks}
                        onChange={(e) =>
                          setData({
                            ...data,
                            access_post_graduation_in_weeks: e.target.value
                          })
                        }
                        type="number"
                        InputProps={{
                          inputProps: {
                            min: 0
                          }
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mt: 2
                      }}
                    >
                      <Stack direction={'row'} spacing={2}>
                        <LabelStyle sx={{ mt: 2 }}>Award Certificate on Program Completion *</LabelStyle>
                        <TooltipMessage
                          sx={{ mt: 2 }}
                          message="When the student completes program successfully, the certificate will be shared. You can upload Signature for the certificate from Configuration > Theme > Institute Configuration Section"
                        />
                      </Stack>
                      <Stack>
                        <ToggleButtonGroup
                          color="primary"
                          value={data.enable_certificate ? 'yes' : 'no'}
                          exclusive
                          onChange={(e, v) => {
                            if (v) {
                              setData({
                                ...data,
                                enable_certificate: v == 'yes' ? true : false
                              });
                            }
                          }}
                        >
                          <ToggleButton value="yes">Yes</ToggleButton>
                          <ToggleButton value="no">No</ToggleButton>
                        </ToggleButtonGroup>
                      </Stack>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} sx={{ mb: 1, mt: 1 }}>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      // sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <div>
                        {/* <Stack direction={'row'} spacing={2}>
                          <Typography sx={{ mb: 1 }} variant="body2">
                            Price Of Program
                          </Typography>
                          <TooltipMessage
                            sx={{ mt: -2 }}
                            message="After the first time setup of program, any changes in the program fees will disable the payment plan (if enabled) and the payment plan will need to be reconfigured."
                          />
                        </Stack>

                        <TextField
                          fullWidth
                          InputProps={{
                            startAdornment: <InputAdornment position="start">{instituteinfo?.default_currency?.prefix}</InputAdornment>,
                            endAdornment: <InputAdornment position="end"> {instituteinfo?.default_currency?.suffix}</InputAdornment>
                          }}
                          name="price"
                          size="meddium"
                          disabled={data.is_course_free}
                          placeholder="Enter Price"
                          value={data.price}
                          onChange={(e) => {
                            setData({ ...data, price: e.target.value });
                          }}
                        /> */}
                        <Stack sx={{ mt: 2 }} direction="row">
                          <Checkbox
                            disabled={data.is_course_free}
                            checked={!!data.offer_first_section_free}
                            onChange={(e) => {
                              setData({
                                ...data,
                                offer_first_section_free: !data.offer_first_section_free
                              });
                              // setTrailSection(!trailsection);
                            }}
                            inputProps={{ 'aria-label': 'controlled' }}
                            sx={{ mt: -1 }}
                          />
                          <Typography
                            variant="body2"
                            sx={{
                              mt: 0
                            }}
                            color={data.is_course_free ? 'text.secondary' : ''}
                          >
                            Offer the first section of the program as free
                          </Typography>
                        </Stack>
                      </div>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={6}
                      // sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Stack sx={{ height: 1 }} direction={'row'} alignItems="center">
                        <Checkbox
                          // defaultChecked
                          checked={!!data?.is_course_free}
                          // disabled={value.can_skip}
                          onChange={(e) => {
                            setData({
                              ...data,
                              is_course_free: e.target.checked ? true : false,
                              price: e.target.checked ? 0 : firstform?.price || 0,
                              offer_first_section_free: e.target.checked ? false : data.offer_first_section_free
                            });
                          }}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                        <Typography
                          variant="body2"

                          // color="text.secondary"
                        >
                          Free Program
                        </Typography>
                        {data?.is_course_free && (
                          <>
                            <Typography variant="subtitle1" color="warning.main" sx={{ ml: 3 }}>
                              ( This program is free.)
                            </Typography>
                          </>
                        )}
                      </Stack>
                    </Grid>
                    {/* <Grid xs={12} md={6} item>
                      <Stack direction="row" justifyContent={'space-between'} alignItems={'center'}>
                        <Typography variant="body2" sx={{ ml: 1.6, mt: 2 }}>
                          Program runs in Cohorts
                          <TooltipMessage
                            sx={{ mt: 1 }}
                            message={`Cohorted Programs are programs where students can join
                        only in batches which can be managed in the cohorts
                        menu. Evergreen programs can be joined by students at
                        any point of time.`}
                          />
                        </Typography>

                        <ToggleButtonGroup color="primary" value={data.is_cohort ? 'true' : 'false'} exclusive onChange={handleChangeCohortToggle}>
                          <ToggleButton value="true">Yes</ToggleButton>
                          <ToggleButton value="false">No</ToggleButton>
                        </ToggleButtonGroup>
                      </Stack>
                    </Grid> */}
                    {/* <Grid xs={12} md={6} item sx={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <FormLabel id="demo-radio-buttons-group-label" style={{ marginRight: '16px', color: 'black' }}>
                          Course Type <span style={{ color: 'red' }}>*</span>:
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="Course Type"
                          name="CourseType"
                          value={courseType}
                          onChange={(e) => setCourseType(e.target.value)}
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <FormControlLabel value="parttime" control={<Radio />} label="Part-Time" />
                          <FormControlLabel value="fulltime" control={<Radio />} label="Full-Time" />
                        </RadioGroup>
                      </div>
                    </Grid> */}
                    <Grid xs={12} md={6} item>
                      {data.is_cohort && instituteinfo?.enable_cohort_attendance && (
                        <Stack direction="row" alignItems="center" sx={{ pl: 1 }}>
                          {/* <RHFCheckbox label='Enable Cohort Attendance' name='cohort_attendance' /> */}
                          <FormControlLabel
                            label="Enable Attendance"
                            control={
                              <Checkbox
                                checked={data?.cohort_attendance}
                                onChange={(e) => {
                                  setData({
                                    ...data,
                                    cohort_attendance: e.target.checked
                                  });
                                }}
                              />
                            }
                          />
                        </Stack>
                      )}
                    </Grid>
                  </Grid>
                </Card>

                <Stack
                  sx={{ mt: 3 }}
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                  // justifyContent="flex-end"
                >
                  <Stack item xs={12} md={3}>
                    <RoleBasedGuard accessibleRoles={[1, 2]} noPermissionChildren={<></>}>
                      <LoadingButton type="submit" variant="contained" size="large" loading={loadingtoform}>
                        Next
                        {/* {!isEdit ? "C" : "Save Changes"} */}
                      </LoadingButton>
                    </RoleBasedGuard>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </FormProvider>
        </Container>
        {showConformation?.show && (
          <ConfirmationPopup type="program" handleCancel={cancelConformation} handleOk={onSubmit} info={showConformation?.info} />
        )}
        {cohortAlert && <CohortModal handleClose={handleCloseCohortAlert} handleDone={handleDoneByCohortAlert} />}
      </Page>
    </RoleBasedGuard>
  );
}
const TooltipMessage = ({ message, sx = {} }) => {
  return (
    <Tooltip describeChild arrow title={message}>
      <IconButton
        style={{
          marginTop: '-0.5rem'
        }}
      >
        <Iconify icon="ant-design:exclamation-circle-twotone" />
      </IconButton>
    </Tooltip>
  );
};
