import PropTypes from "prop-types";
// form
import { useFormContext, Controller } from "react-hook-form";
// @mui
import { TextField } from "@mui/material";
import { DatePicker } from "@mui/lab";

// ----------------------------------------------------------------------

RHFDatePicker.propTypes = {
  name: PropTypes.string,
};

export default function RHFDatePicker({ name, onTrigger, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <DatePicker
          renderInput={(params) => <TextField {...params} fullWidth error={!!error} helperText={error?.message} {...other} />}
          {...field}
          onChange={(value) => {
            if (!onTrigger) {
              field.onChange(value);
            } else {
              onTrigger({ field, value });
            }
          }}
        />
      )}
    />
  );
}
