import { Box, Button, InputAdornment, TextField, Toolbar } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Iconify from 'src/components/Iconify';
import RoleBasedGuard from 'src/guards/RoleBasedGuard';
import MediaListSort from './MediaListSort';
import MediaSortbyPrivate from './MediaSortbyPrivate';
import MediatypeSort from './MediatypeSort';

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
  [theme.breakpoints.down('sm')]: {
    // Apply styles for mobile
    width: '100%',
    flexDirection: 'column-reverse',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10
  }
}));

ProductListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  addNewMedia: PropTypes.func,
  sortMedia: PropTypes.func,
  sortMediatype: PropTypes.func,
  sortMediabyPrivate: PropTypes.func,
  public_private: PropTypes.string
};

export default function ProductListToolbar({
  numSelected,
  filterName,
  onFilterName,
  addNewMedia,
  sortMedia,
  sortMediatype,
  sortMediabyPrivate,
  public_private = ''
}) {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: isLight ? 'primary.main' : 'text.primary',
          bgcolor: isLight ? 'primary.lighter' : 'primary.dark'
        })
      }}
    >
      <TextField
        sx={{
          width: {
            xs: '100%', // 100% width on mobile
            md: '40%' // 40% width on desktop
          }
        }}
        variant="outlined"
        size="small"
        value={filterName}
        onChange={(event) => onFilterName(event.target.value)}
        placeholder="Search Media..."
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
            </InputAdornment>
          )
        }}
      />

      <Box
        sx={{
          width: {
            xs: '100%', // 100% width on mobile
            md: '60%' // Full width on mobile
          },
          overflowX: {
            xs: 'auto' // Enable horizontal scrolling on mobile
          },
          overflowY: {
            xs: 'hidden' // Prevent vertical scrolling on mobile
          },
          display: 'flex',
          JustifyContent: 'space-between',
          alignItems: 'center',
          padding: '25px', // Ensure it’s a flex container on mobile
          flexWrap: {
            xs: 'nowrap' // Prevent wrapping of items on mobile
          }
        }}
      >
        {!public_private && <MediaSortbyPrivate sortMedia={(p) => sortMediabyPrivate(p)} />}
        <MediaListSort sortMedia={(p) => sortMedia(p)} />
        <MediatypeSort sortMedia={(p) => sortMediatype(p)} />

        <RoleBasedGuard accessibleRoles={[1, 2, 3]} noPermissionChildren={<></>}>
          <Box>
            <Button size="small" variant="outlined" color="primary" onClick={() => addNewMedia()}>
              Add Media
            </Button>
          </Box>
        </RoleBasedGuard>
      </Box>
    </RootStyle>
  );
}
