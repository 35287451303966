import { useEffect, useState } from 'react';
// @mui
import { Box, Card, CardHeader, Container, Stack, Tab, Table, TableBody, TableContainer, TablePagination, Tabs } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
// routes
// hooks
import useSettings from 'src/hooks/useSettings';
// import useTable, { getComparator, emptyRows } from "../../hooks/useTable";
// _mock_
// components
import Page from 'src/components/Page';

import { TableHeadCustom } from 'src/components/table';
// sections

import { capitalCase } from 'change-case';
import { useSelector } from 'react-redux';
import { eduleyApi } from 'src/api/apiConfig';
import { PLATFORM_SETTINGS, PLATFORM_SETTINGS_ACCESS_REQUEST } from 'src/apiUrl';
import Iconify from 'src/components/Iconify';
import { setAccessRequestList } from 'src/redux/slices/configuration';
import { useDispatch } from 'src/redux/store';
import { PATH_DASHBOARD } from 'src/routes/paths';
import PlatformManagementRow from 'src/sections/@dashboard/fund_management/payout/PlatformManagementRow';
import { getPageLimit, setPageLimit } from 'src/utils/pageLimitControl';
import AccessPermission from './AccessPermission';

// ----------------------------------------------------------------------

const PLATFORM_SETTINGS_TABLE_HEAD = [
  { id: 'client_name', label: 'Client Name', align: 'left' },
  { id: 'price', label: 'Whitelisted Domain', align: 'left' },
  { id: '', label: 'Whitelisted Email', align: 'left' },
  { id: '', label: 'Blacklisted Email', align: 'left' },
  { id: '', label: 'Pending Access', align: 'left' },
  { id: '', label: 'Actions', align: 'left' }
];

// ----------------------------------------------------------------------

export default function PlatformSettings() {
  const { themeStretch } = useSettings();
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const [transactions, setTransaction] = useState({});
  const [pagedata, setPageData] = useState({
    limit: getPageLimit('recentpayout'),
    offset: 0,
    orderBy: 'create_date',
    order: 'desc'
  });
  const [currentTab, setCurrentTab] = useState('pending');
  const { accessRequestList } = useSelector((state) => state.configuration);

  const ACCESS_PERMISSION_TABS = [
    {
      value: 'pending',
      icon: <Iconify icon={'mdi:heart'} width={20} height={20} />,
      type: 'pending',
      component: (
        <AccessPermission
          type="pending"
          updateAccessRequestCount={() => {
            getInstitutePlatformSettingslist();
            getPermissionsList('granted');
            getPermissionsList('revoked');
          }}
        />
      )
    },

    {
      value: 'completed',
      type: 'granted',
      icon: <Iconify icon={'bi:person-circle'} width={20} height={20} />,
      component: <AccessPermission type="granted" />
    },
    {
      value: 'rejected',
      type: 'revoked',
      icon: <Iconify icon={'material-symbols:cancel-outline'} width={20} height={20} />,
      component: <AccessPermission type="revoked" />
    }
  ];

  const query = `?limit=${pagedata.limit}&offset=${pagedata.offset}&ordering=${pagedata.order === 'desc' ? '-' : ''}${pagedata.orderBy}`;

  // useEffect(() => {
  //   getPermissionsList('granted');
  //   getPermissionsList('revoked');
  // }, []);

  useEffect(() => {
    getInstitutePlatformSettingslist(query);
  }, [pagedata]);

  const getPermissionsList = async (status) => {
    const pendingList = `?limit=5&offset=0&status=${status}`;
    try {
      let res = await eduleyApi.get(`${PLATFORM_SETTINGS_ACCESS_REQUEST}${pendingList}`);
      const accessRequestListTemp = {
        ...accessRequestList
      };
      accessRequestListTemp[status] = res.data;
      dispatch(setAccessRequestList(accessRequestListTemp));
    } catch (error) {
      console.log(error);
    }
  };

  const getInstitutePlatformSettingslist = async () => {
    try {
      let res = await eduleyApi.get(`${PLATFORM_SETTINGS}${query}`);
      setTransaction(res.data);
      setTableData(res.data.results);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOrderBy = (event) => {
    setPageData({
      ...pagedata,
      orderBy: event,
      offset: 0,
      order: pagedata.orderBy === event && pagedata.order === 'desc' ? 'asc' : 'desc'
    });
  };

  function onEdit(row) {
    navigate(PATH_DASHBOARD.fund.platform_settings.edit(row.id), { state: row });
  }

  return (
    <>
      <Page title="Platform Settings">
        <Container maxWidth={themeStretch ? false : 'lg'}>
          <Stack>
            <Card>
              <CardHeader title="Platform Settings" sx={{ p: 2 }} />
              <TableContainer
                sx={{
                  position: 'relative',
                  minHeight: 260
                }}
              >
                <Table>
                  <TableHeadCustom
                    order={pagedata.order}
                    orderBy={pagedata.orderBy}
                    headLabel={PLATFORM_SETTINGS_TABLE_HEAD}
                    rowCount={tableData?.length}
                    onSort={handleOrderBy}
                  />

                  <TableBody>
                    {tableData?.map((row, ii) => (
                      <PlatformManagementRow key={ii + 'hjj'} row={row} onEdit={() => onEdit(row)} />
                    ))}
                  </TableBody>
                  {/* {count == 0 && (
                    <TableRow style={{ height: 53 * 5 }}>
                      <TableCell align="center" colSpan={9} sx={{ py: 3, color: 'text.main' }}>
                        No Data Found
                      </TableCell>
                    </TableRow>
                  )} */}
                </Table>
              </TableContainer>
              {/* </Scrollbar> */}

              <Box sx={{ position: 'relative' }}>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={transactions?.count || 0}
                  rowsPerPage={pagedata.limit}
                  page={pagedata.offset / pagedata.limit + 0}
                  onPageChange={(event, newPage) => {
                    // console.log("newPage====", newPage);
                    setPageData({
                      ...pagedata,
                      offset: newPage * pagedata.limit
                    });
                  }}
                  onRowsPerPageChange={(event) => {
                    // console.log("event====", event.target.value);
                    setPageLimit('recentpayout', event.target.value);
                    setPageData({ ...pagedata, limit: event.target.value });
                  }}
                />
              </Box>
            </Card>
          </Stack>

          <Stack sx={{ mt: 2 }}>
            <Card>
              <CardHeader title="Access Permission" sx={{ p: 2 }} />
              <Tabs
                value={currentTab}
                scrollButtons="auto"
                variant="scrollable"
                allowScrollButtonsMobile
                onChange={(e, value) => setCurrentTab(value)}
                sx={{ px: 2 }}
              >
                {ACCESS_PERMISSION_TABS.map((tab) => (
                  <Tab
                    disableRipple
                    key={tab.value}
                    label={`${capitalCase(tab.value)} (${accessRequestList[tab.type]?.count || 0})`}
                    icon={tab.icon}
                    value={tab.value}
                  />
                ))}
              </Tabs>
              {ACCESS_PERMISSION_TABS.map((tab) => {
                const isMatched = tab.value === currentTab;
                return isMatched && <Box key={tab.value}>{tab.component}</Box>;
              })}
            </Card>
          </Stack>
        </Container>
      </Page>
    </>
  );
}

// ----------------------------------------------------------------------
