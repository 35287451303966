import {
  Box,
  CardContent,
  CardHeader,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  useTheme
} from '@mui/material';
import _ from 'lodash';
import Iconify from 'src/components/Iconify';
import { TableLoader } from 'src/components/table';
import useAuth from 'src/hooks/useAuth';
import ChoosePaymentType from '../ChoosePaymentType';
const PaymentPlanView = ({ data, loading, paymentInfo }) => {
  const theme = useTheme();
  const { instituteinfo } = useAuth();
  return (
    <>
      <Box
        sx={{
          bgcolor: theme.palette.grey[200]
        }}
      >
        <TableLoader loading={loading} />
        <CardHeader title="Payment Options for User" />
        <CardContent>
          <ChoosePaymentType handleChange={() => {}} value={data} disabled={true} />
        </CardContent>
      </Box>
      {data.installment && !_.isEmpty(paymentInfo) && (
        <>
          <Box
            sx={{
              width: '100%'
            }}
          >
            <TableLoader loading={loading} />
            <CardHeader
              title="Configure Payment Plan"
              sx={{
                fontWeight: 'bold',
                fontSize: 16
              }}
            />
            <CardContent>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableCell>Installment#</TableCell>
                    <TableCell>Installment Amount ({instituteinfo?.default_currency.prefix || instituteinfo?.default_currency.suffix})</TableCell>
                    <TableCell>
                      Due Date (in days)
                      <Tooltip
                        describeChild
                        arrow
                        title="Due in days from the date of registration fee payment made by the student. Should be less than program duration days."
                      >
                        <IconButton sx={{ mt: -1 }}>
                          <Iconify
                            icon="ant-design:exclamation-circle-twotone"
                            width={20}
                            height={20}
                            sx={{
                              mb: -0.5,
                              ml: 1
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>Registration Fee</TableCell>
                      <TableCell>{paymentInfo[0]?.registration_fee}</TableCell>
                      <TableCell>1st day</TableCell>
                    </TableRow>
                    {paymentInfo[0]?.installments?.map((item, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>Installment {index + 1}</TableCell>
                          <TableCell>{item?.amount}</TableCell>
                          <TableCell>After {item?.due_in_days} day</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Box>
        </>
      )}
    </>
  );
};

export default PaymentPlanView;
