import { createSlice } from "@reduxjs/toolkit";
// import axios from "axios";
import axios from "../../utils/axios";

import sum from "lodash/sum";
import uniqBy from "lodash/uniqBy";
import { REVENUE_HIGHLIGHT, REVENUE_LIST } from "src/apiUrl";
// utils
//
import { dispatch } from "../store";
import { eduleyApi } from "src/api/apiConfig";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,

  error: null,
  revenue: {
    data: {},
    isLoading: false,
    error: null,
  },
  hightlight: {
    data: {},
    isLoading: false,
    error: null,
  },
};

const slice = createSlice({
  name: "revenue",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setRevenue(state, action) {
      state.revenue.data = action.payload;
      state.revenue.isLoading = false;
      state.revenue.error = null;
    },
    fetchRevenue(state) {
      state.revenue.isLoading = true;
    },
    hasErrorRevenue(state, action) {
      state.revenue.isLoading = false;
      state.revenue.error = action.payload;
    },

    // HIGHTLIGHT
    setsubscriptionhightlight(state, action) {
      state.hightlight.data = action.payload;
      state.hightlight.isLoading = false;
      state.hightlight.error = null;
    },
    fetchSubscriptionHightlight(state) {
      state.hightlight.isLoading = true;
    },
    hasErrorSubscriptionHightlight(state, action) {
      state.hightlight.isLoading = false;
      state.hightlight.error = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {} = slice.actions;

// ----------------------------------------------------------------------

const accessToken = localStorage.getItem("accessToken");

const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${accessToken}`,
  },
};

export const getRevenue = (data) => {
  return async () => {
    dispatch(slice.actions.fetchRevenue());
    try {
      const response = await eduleyApi.get(`${REVENUE_LIST}${data}`);
      dispatch(slice.actions.setRevenue(response.data));
    } catch (error) {
      dispatch(slice.actions.hasErrorRevenue(error));
    }
  };
};

export const getRevenuehightlight = (q) => {
  return async () => {
    dispatch(slice.actions.fetchSubscriptionHightlight());
    try {
      const response = await eduleyApi.get(`${REVENUE_HIGHLIGHT}${q}`);
      dispatch(slice.actions.setsubscriptionhightlight(response.data));
    } catch (error) {
      dispatch(slice.actions.hasErrorSubscriptionhightlight(error));
    }
  };
};
