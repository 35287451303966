import { useState, useCallback, useEffect } from "react";
// @mui
import { alpha, styled } from "@mui/material/styles";
import logo from 'src/assets/images/transitexp.jpeg'
import Iconify from "src/components/Iconify";
import {
    Card,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    DialogContentText,
    TextField,
    Button,
    Stack,
    Checkbox,
    Typography,
    List,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
} from "@mui/material";
import { addPayoutAccount, editPayoutAccount } from "src/redux/slices/fund_management";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "src/redux/store";
import { useSnackbar } from "notistack";

const BankCardfill = ({ cancelling, data1 = {}, id }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [data, setData] = useState({
        beneficiary_name: "",
        beneficiary_address: "",
        beneficiary_bank: "",
        beneficiary_account_number: "",
        account_type: id,
        ...data1,
    });
    const [errors, setErrors] = useState({});
    const requestingpayout = async () => {

        setLoading(true);
        let res = "";
        if (data1.id) {
            res = await dispatch(editPayoutAccount(data));
        } else {
            res = await dispatch(addPayoutAccount(data));
        }
        if (res == "success") {
            setLoading(false);
            enqueueSnackbar("Payout account added successfully", {
                variant: "success",
            });
            cancelling();
        } else {
            setLoading(false);
            console.log(res);
            setErrors(res);
        }
        console.log(res);
        setLoading(false);
    };
    return (
        <>
            <Dialog
                fullWidth
                maxWidth="sm"
                open={true}
                onClose={() => cancelling()}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle id="max-width-dialog-title">
                    {"Add New Account"}
                </DialogTitle>
                <DialogContent>
                    {/* <DialogContentText>{"Add Request"}</DialogContentText> */}
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Beneficiary Name"
                        value={data.beneficiary_name}
                        onChange={(e) =>
                            setData({ ...data, beneficiary_name: e.target.value })
                        }
                        type="text"
                        helperText={errors.beneficiary_name}
                        error={errors.beneficiary_name ? true : false}
                        fullWidth
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        multiline
                        default rows={2}
                        error={errors.beneficiary_address ? true : false}
                        helperText={errors.beneficiary_address}
                        value={data.beneficiary_address}
                        onChange={(e) =>
                            setData({ ...data, beneficiary_address: e.target.value })
                        }
                        label="Beneficiary Address"
                        type="text"
                        fullWidth
                    />
                    <TextField
                        value={data.transit_number}
                        margin="dense"
                        error={errors.transit_number ? true : false}
                        helperText={errors.transit_number}
                        onChange={(e) =>
                            setData({ ...data, transit_number: e.target.value })
                        }
                        label="Transit Number"

                        type="text"
                        fullWidth
                    />
                    <TextField
                        value={data.institution_number}
                        margin="dense"
                        error={errors.institution_number ? true : false}
                        helperText={errors.institution_number}
                        onChange={(e) =>
                            setData({ ...data, institution_number: e.target.value })
                        }
                        label="Institution Number"

                        type="text"
                        fullWidth
                    />
                    <TextField
                        value={data.beneficiary_account_number}
                        margin="dense"
                        onChange={(e) =>
                            setData({ ...data, beneficiary_account_number: e.target.value })
                        }
                        error={errors.beneficiary_account_number ? true : false}
                        helperText={errors.beneficiary_account_number}
                        id="name"
                        label="Account Number"
                        type="text"
                        fullWidth
                    />

                    <TextField
                        value={data.beneficiary_bank}
                        margin="dense"
                        error={errors.beneficiary_bank ? true : false}
                        helperText={errors.beneficiary_bank}
                        onChange={(e) =>
                            setData({ ...data, beneficiary_bank: e.target.value })
                        }
                        label="Bank Name"
                        type="text"
                        fullWidth
                    />


                    <Avatar variant="square" sx={{ bgcolor: 'warning.main', width: '80%', height: 85, borderRadius: '10%', mt: 3, }} alt="Interac" src={logo} />
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' onClick={() => cancelling()}>Cancel</Button>
                    <LoadingButton
                        onClick={() => requestingpayout()}
                        loading={loading}
                        disabled={loading}
                        variant='contained'
                    >
                        Submit
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

const InteracCard = ({ cancelling, data1 = {}, id }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [data, setData] = useState({
        account_type: id,
        ...data1,
    });
    const [errors, setErrors] = useState({});
    const requestingpayout = async () => {
        if (data.interac !== data.interac2) {
            setErrors({
                interac2: "email is not matching",
            });
            return;
        }
        setLoading(true);
        let res = "";
        if (data1.id) {
            res = await dispatch(editPayoutAccount(data));
        } else {
            res = await dispatch(addPayoutAccount(data));
        }

        if (res == "success") {
            setLoading(false);
            enqueueSnackbar("Payout account added successfully", {
                variant: "success",
            });
            cancelling();
        } else {
            setLoading(false);
            console.log(res);
            setErrors(res);
        }
        console.log(res);
        setLoading(false);
    };

    return (
        <>
            <Dialog
                fullWidth
                maxWidth="sm"
                open={true}
                onClose={() => cancelling()}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle id="max-width-dialog-title">
                    {data1.id ? "Edit Interac Account" : "Add New Interac Account"}
                </DialogTitle>
                <DialogContent>
                    {/* <DialogContentText>{"Add Request"}</DialogContentText> */}
                    <TextField
                        sx={{ mt: 2 }}

                        margin="dense"
                        label="Interac email"
                        value={data.interac}
                        onChange={(e) =>
                            setData({ ...data, interac: e.target.value })
                        }
                        type="text"
                        helperText={errors.interac}
                        error={errors.interac ? true : false}
                        fullWidth
                    />
                    <TextField
                        margin="dense"
                        sx={{ mt: 2 }}

                        label="Re-enter Interac email"
                        value={data.interac2}
                        onChange={(e) =>
                            setData({ ...data, interac2: e.target.value })
                        }
                        type='password'
                        helperText={errors.interac2}
                        error={errors.interac ? true : false}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' onClick={() => cancelling()}>Cancel</Button>
                    <LoadingButton
                        onClick={() => requestingpayout()}
                        loading={loading}
                        disabled={loading}
                        variant='contained'
                    >
                        Submit
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

const PaypalCard = ({ cancelling, data1 = {}, id }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [data, setData] = useState({
        account_type: id,
        ...data1,
    });
    const [errors, setErrors] = useState({});
    const requestingpayout = async () => {
        if (data.paypal !== data.paypal2) {
            setErrors({
                paypal2: "email is not matching",
            });
            return;
        }
        setLoading(true);
        let res = "";
        if (data1.id) {
            res = await dispatch(editPayoutAccount(data));
        } else {
            res = await dispatch(addPayoutAccount(data));
        }
        if (res == "success") {
            setLoading(false);
            enqueueSnackbar("Payout account added successfully", {
                variant: "success",
            });
            cancelling();
        } else {
            setLoading(false);
            console.log(res);
            setErrors(res);
        }
        console.log(res);
        setLoading(false);
    };
    return (
        <>
            <Dialog
                fullWidth
                maxWidth="sm"
                open={true}
                onClose={() => cancelling()}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle id="max-width-dialog-title">
                    {data1.id ? "Edit Paypal Account" : "Add New Paypal Account"}
                </DialogTitle>
                <DialogContent>
                    {/* <DialogContentText>{"Add Request"}</DialogContentText> */}
                    <TextField
                        margin="dense"
                        label="email"
                        sx={{ mt: 2 }}

                        value={data.paypal}
                        onChange={(e) =>
                            setData({ ...data, paypal: e.target.value })
                        }
                        // type="text"
                        helperText={errors.paypal}
                        error={errors.paypal ? true : false}
                        fullWidth
                    />
                    <TextField
                        sx={{ mt: 2 }}
                        margin="dense"
                        label="Re-enter email"
                        value={data.paypal2}
                        onChange={(e) =>
                            setData({ ...data, paypal2: e.target.value })
                        }
                        type='password'
                        helperText={errors.paypal2}
                        error={errors.paypal2 ? true : false}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' onClick={() => cancelling()}>Cancel</Button>
                    <LoadingButton
                        onClick={() => requestingpayout()}
                        loading={loading}
                        disabled={loading}
                        variant='contained'
                    >
                        Submit
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

export { BankCardfill, PaypalCard, InteracCard };
