import { createSlice } from "@reduxjs/toolkit";
import { reject } from "lodash";
import { eduleyApi } from "src/api/apiConfig";
import {
  MEDIA_DOCUMENT,
  MEDIA_IMAGE,
  MEDIA_LIST,
  MEDIA_VIDEO,
} from "src/apiUrl";
import { fDateDash } from "src/utils/formatTime";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  media: {},
  image: {},
  video: {},
  document: {},
};

const slice = createSlice({
  name: "media",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MAILS
    getMediaSuccess(state, action) {
      const media = action.payload;
      state.error = null;
      state.isLoading = false;
      state.media = media;
      //   state.media=
    },
    getMediaImageSuccess(state, action) {
      const image = action.payload;
      state.isLoading = false;
      state.error = null;
      state.image = image;
    },
    getMediaVideoSuccess(state, action) {
      const video = action.payload;
      state.isLoading = false;

      state.video = video;
    },

    getMediaDocumentSuccess(state, action) {
      const document = action.payload;
      state.error = null;
      state.isLoading = false;
      state.document = document;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
const accessToken = localStorage.getItem("accessToken");

const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${accessToken}`,
    date: fDateDash(new Date()),
  },
};
const config2 = {
  headers: {
    "Content-Type":
      "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW",
    Authorization: `Bearer ${accessToken}`,
    date: fDateDash(new Date()),
  },
};
export const getMedialist = (paginate) => async (dispatch) => {
  console.log("getMedia", paginate);
  dispatch(slice.actions.startLoading());
  try {
    const response = await eduleyApi.get(`${MEDIA_LIST}${paginate}`);
    dispatch(slice.actions.getMediaSuccess(response.data));
    return "success";
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

// ----------------------------------------------------------------------

export const uploadImage = (file) => async (dispatch) => {
  console.log("uploadImage", file);
  dispatch(slice.actions.startLoading());
  const config3 = {
    onUploadProgress: (progressEvent) => console.log(progressEvent.loaded),
  };
  try {
    const response = await axios.post(
      `${MEDIA_IMAGE}`,

      file,
      config2,
      config3
    );
    console.log("headers---", response.headers);
    dispatch(slice.actions.getMediaImageSuccess(response.data));
    return "success";
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return "error";
  }
};

export const UpdateImage = (file, id) => async (dispatch) => {
  console.log("uploadImage", file);
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.put(`${MEDIA_IMAGE}${id}/`, file, config2);
    dispatch(slice.actions.getMediaImageSuccess(response.data));
    return "success";
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return "error";
  }
};
export const deleteImage = (id) => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.delete(`${MEDIA_IMAGE}${id}/`, config);
    return "success";
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    // reject('reject')

    return error;
  }
};

export const uploadVideo = (file) => async (dispatch) => {
  console.log("uploadVideo", file);
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(`${MEDIA_VIDEO}`, file, config2);
    dispatch(slice.actions.getMediaVideoSuccess(response.data));
    return "success";
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return "error";
  }
};
export const deleteVideo = (id) => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.delete(`${MEDIA_VIDEO}${id}/`, config);
    return "success";
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return error;
  }
};
export const getVideo = (id) => async (dispatch) => {
  // console.log("uploadVideo", file);
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.get(`${MEDIA_VIDEO}${id}/`, config);
    dispatch(slice.actions.getMediaVideoSuccess(response.data));
    return "success";
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return "error";
  }
};
export const updateVideo = (file, id) => async (dispatch) => {
  console.log("updateVideo", file);
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.put(`${MEDIA_VIDEO}${id}/`, file, config2);
    dispatch(slice.actions.getMediaVideoSuccess(response.data));
    return "success";
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return "error";
  }
};

export const uploadDocument = (file) => async (dispatch) => {
  // console.log("uploadDocument", file);
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(`${MEDIA_DOCUMENT}`, file, config2);
    dispatch(slice.actions.getMediaDocumentSuccess(response.data));
    return "success";
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return "error";
  }
};
export const deleteDocument = (id) => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.delete(`${MEDIA_DOCUMENT}${id}/`, config);
    return "success";
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    // reject(error)
    return error;
  }
};
export const updateDocument = (file, id) => async (dispatch) => {
  // console.log("uploadDocument", file);
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.put(`${MEDIA_DOCUMENT}${id}/`, file, config2);
    dispatch(slice.actions.getMediaDocumentSuccess(response.data));
    return "success";
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return "error";
  }
};

export const uploadImagelink = (file) => async (dispatch) => {
  console.log("uploadImage", file);
  dispatch(slice.actions.startLoading());
  try {
    let response = await axios.post(`${MEDIA_IMAGE}`, file, config);
    console.log("dddddddd", response);
    return "success";
    // dispatch(slice.actions.getMediaImageSuccess(response.data));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return "error";
  }
};

export const UpdateImagelink = (file, id) => async (dispatch) => {
  console.log("uploadImage", file);
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.patch(`${MEDIA_IMAGE}${id}/`, file, config);
    // dispatch(slice.actions.getMediaImageSuccess(response.data));
    return "success";
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return "error";
  }
};

export const uploadVideolink = (file) => async (dispatch) => {
  console.log("uploadVideo", file);
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(`${MEDIA_VIDEO}`, file, config);
    // dispatch(slice.actions.getMediaVideoSuccess(response.data));
    return "success";
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return "error";
  }
};
export const updateVideolink = (file, id) => async (dispatch) => {
  console.log("uploadVideo", file);
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.patch(`${MEDIA_VIDEO}${id}/`, file, config);
    // dispatch(slice.actions.getMediaVideoSuccess(response.data));
    return "success";
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return "error";
  }
};

export const uploadDocumentlink = (file) => async (dispatch) => {
  console.log("uploadDocument", file);
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(`${MEDIA_DOCUMENT}`, file, config);
    // dispatch(slice.actions.getMediaDocumentSuccess(response.data));
    return "success";
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return "error";
  }
};
export const updateDocumentlink = (file, id) => async (dispatch) => {
  console.log("uploadDocument", file);
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.put(`${MEDIA_DOCUMENT}${id}/`, file, config);
    // dispatch(slice.actions.getMediaDocumentSuccess(response.data));
    return "success";
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return "error";
  }
};
