// import PropTypes from "prop-types";
// import Slider from "react-slick";
import { useEffect, useState } from 'react';
// @mui
import {
  Button,
  Card,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

// utils
import { fDateTime } from '../../../../utils/formatTime';
// _mock_
// import { _bookingReview } from "../../../../_mock";

// components
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { eduleyApi } from 'src/api/apiConfig';
import { BUDGETNISTA_PAYOUT } from 'src/apiUrl';
import Iconify from 'src/components/Iconify';
import useAuth from 'src/hooks/useAuth';
import { useDispatch, useSelector } from 'src/redux/store';
import { fAmount } from 'src/utils/formatNumber';
// import { CarouselArrows } from "../../../../components/carousel";

// ----------------------------------------------------------------------

export default function BudgetnistaPayoutRequest({ donepayment, amount = {} }) {
  const [addrequest, setAddrequest] = useState(false);
  console.log(amount);
  return (
    <>
      <Card>
        {amount?.data?.data <= 0 ? (
          <>
            <RootStylee>
              <Typography flexWrap={'inherit'} variant="subtitle2" sx={{ opacity: 0.72 }}>
                Budgetnista Payouts is available only for institute who have opted for payment via Self
              </Typography>
            </RootStylee>
          </>
        ) : (
          <>
            <CardHeader
              title={'Payable Balance'}
              subheader={`${fDateTime(new Date())}`}
              sx={{
                '& .MuiCardHeader-action': {
                  alignSelf: 'center'
                }
              }}
            />
            <ReviewItem requesting={() => setAddrequest(true)} data={amount} />
          </>
        )}{' '}
      </Card>
      {addrequest && (
        <AddrequestModal
          cancelling={() => setAddrequest(false)}
          donepayment={() => {
            setAddrequest(false);
            donepayment();
          }}
        />
      )}
    </>
  );
}
//

const AddrequestModal = ({ cancelling, donepayment }) => {
  const {
    payout: { transactions, accountreq: account }
  } = useSelector((state) => state.fund_management);

  const { enqueueSnackbar } = useSnackbar();

  // console.log("transactions", account);
  const dispatch = useDispatch();
  const { instituteinfo } = useAuth();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    amount: '',
    remarks: '',
    status: 'in process'
  });
  const [error, setError] = useState({ amount: '', account: '' });

  const [currency, setCurrency] = useState([]);

  useEffect(() => {
    getCurrencylist();
  }, []);
  //   console.log("curreny---", instituteinfo.default_currency, data.currency);

  const getCurrencylist = async () => {
    setCurrency([instituteinfo.default_currency]);
    setData({ ...data, currency: instituteinfo.default_currency.id });
    // try {
    //   let res = await eduleyApi.get(CURRENCY_LIST);
    //   //   console.log(res);

    // } catch (error) {
    //   console.log(error.response);
    // }
  };

  const requestingpayout = async () => {
    setLoading(true);
    try {
      let res = await eduleyApi.post(BUDGETNISTA_PAYOUT, data);
      enqueueSnackbar('In process', {
        variant: 'success'
      });
      console.log(res);
      donepayment();
    } catch (error) {
      console.log(error.data);
      setError(error.data.data);
    }

    setLoading(false);
  };
  console.log(currency);
  return (
    <>
      <Dialog fullWidth maxWidth="xs" open={true} onClose={() => cancelling()} aria-labelledby="max-width-dialog-title">
        <DialogTitle id="max-width-dialog-title">{'Make Payment'}</DialogTitle>
        <DialogContent>
          <Typography variant="caption">Enter Amount</Typography>
          <TextField
            autoFocus
            margin="dense"
            type="number"
            value={data.amount}
            onChange={(e) => setData({ ...data, amount: e.target.value })}
            fullWidth
            error={error.amount}
            helperText={error.amount}
          />
          <Typography variant="caption">Select Currency</Typography>

          <Select error={error.currency} fullWidth value={data.currency} onChange={(e) => setData({ ...data, currency: e.target.value })}>
            <MenuItem value="" disabled>
              Select Currency
            </MenuItem>
            {currency?.map((item, ifg) => (
              <MenuItem value={item.id} key={ifg + 'jk'}>
                {item.currency_name}
              </MenuItem>
            ))}
          </Select>
          {error?.currency && <FormHelperText>{error.currency}</FormHelperText>}
          <Typography variant="caption">Enter Remarks</Typography>

          <TextField
            margin="dense"
            value={data.remarks}
            onChange={(e) => setData({ ...data, remarks: e.target.value })}
            fullWidth
            error={error.remarks}
            helperText={error.remarks}
          />
        </DialogContent>
        <DialogActions>
          <LoadingButton onClick={() => requestingpayout()} loading={loading} disabled={loading} variant="contained">
            Submit
          </LoadingButton>
          <Button variant="outlined" onClick={() => cancelling()}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

// ----------------------------------------------------------------------

function ReviewItem({ requesting, data = {} }) {
  return (
    <>
      <Stack spacing={2} sx={{ minHeight: 202, position: 'relative', p: 3 }}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <AnalyticsCurrentVisits data2={data} />
        </Stack>
        <Stack direction="row" spacing={2} alignItems="flex-end" sx={{ flexGrow: 1 }}>
          <Button
            fullWidth
            onClick={() => requesting()}
            variant="contained"
            // disabled={ }
            //   endIcon={<Iconify icon={"eva:checkmark-circle-2-fill"} />}
          >
            Make Payment
          </Button>
        </Stack>
      </Stack>
    </>
  );
}
const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(4.5, 5)
}));
const RootStylee = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  padding: 25,
  width: 250,
  height: 480,
  padding: theme.spacing(0, 0)
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(16),
  height: theme.spacing(16),
  justifyContent: 'center',
  marginBottom: theme.spacing(3)
}));

// ----------------------------------------------------------------------

function AnalyticsCurrentVisits({ icon = 'emojione-monotone:money-bag', color = 'primary', data2 = {} }) {
  const { type = '', data = {} } = data2;
  const { data: as = '0', heading = '' } = data;
  let amount = parseFloat(as)?.toFixed(2);
  console.log('data', data);
  return (
    <RootStyle
      sx={{
        color: (theme) => theme.palette[color].darker,
        bgcolor: (theme) => theme.palette[color].lighter
      }}
    >
      <IconWrapperStyle
        sx={{
          color: (theme) => theme.palette[color].dark,
          backgroundImage: (theme) =>
            `linear-gradient(135deg, ${alpha(theme.palette[color].dark, 0)} 0%, ${alpha(theme.palette[color].dark, 0.24)} 100%)`
        }}
      >
        <Iconify icon={icon} width={54} height={54} />
      </IconWrapperStyle>
      <Typography variant="h3">{fAmount(amount)}</Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        {heading}
      </Typography>
    </RootStyle>
  );
}
